import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModuleService } from '../../module.service';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy} from '@angular/core';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from './../../../global';
declare var $;
const router = Router;
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-publisher-settings',
  templateUrl: './publisher-settings.component.html',
  styleUrls: ['./publisher-settings.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublisherSettingsComponent implements OnInit {
  private ServerUrl = GlobalVariable.BASE_API_URL;
  private BaseUrl = GlobalVariable.BASE_URL;
  error: {};
  showLoadingIndicator: any;
  numbers: any;
  private http: HttpClient;
  public settings: any;
  addForm: FormGroup;
  currentCompany: any;
  Companyuuid: any;
  publicregistration:boolean;
  constructor(
    private moduleservice: ModuleService,
    private toastrService:ToastrService,
    handler: HttpBackend,
    private fb: FormBuilder,
    private titleService: Title
  ) {
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.Companyuuid = this.currentCompany.uuid;
    this.http = new HttpClient(handler);
    this.publicregistration =true;
   }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit(): void {
    this.setTitle('Publisher Registration')
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000);
    this.getAllpublisherssetting();
    this.addForm = this.fb.group({
      // , Validators.required
      user_uuid : [''],
      public_registration : [''],
      intro : [''],
      email: [''],      
      terms_cond: [''],      
      tc_url: [''],      
     });
  }
terms_condstatus:any;
terms_condurl:any;
getAllpublisherssetting(){
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  this.moduleservice.getAllpublisherssetting(this.currentCompany.uuid).subscribe(
    res => {
      //  console.log(res); return false;  
      if(res['public_registration']==1){
        this.publicregistration = true;
        this.ispubreg = true;
      }else{
        this.publicregistration = false;
        this.ispubreg = false;
      }
      
      if(res['terms_cond']==1){
        this.terms_condstatus = true;
      }else{
        this.terms_condstatus = false;
      }
      
      if(res['tc_url']!=''){
        this.terms_condurl = res['tc_url'];
      }else{
        this.terms_condurl = 'https://ringlabmedia.com/publisher-terms';
      }

      this.addForm.patchValue({
        intro: res['intro'],               
        email: res['email'],               
        tc_url: this.terms_condurl,               
      });             
    },
    error => this.error = error,
  );  
}  


ispubreg:any=true;
pubreg_enable(event:any){
  this.ispubreg = event.target.checked; 
}  

tcenable:any=true;
tc_enable(event:any){
  this.tcenable = event.target.checked; 
}  

Submitted = false;
//Add Publisher
get addformcontrol() { return this.addForm.controls; }  
onSubmit () {
    const formData = new FormData();      
    this.Submitted = true;
    if (this.addForm.invalid) {
        return;
    }
    
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    formData.append('user_uuid', currentUser.uuid);
    formData.append('uuid', this.currentCompany.uuid);
    formData.append('public_registration',this.ispubreg);
    formData.append('intro', this.addForm.get('intro').value);
    formData.append('email', this.addForm.get('email').value);    
    formData.append('terms_cond', this.tcenable);
    formData.append('tc_url', this.addForm.get('tc_url').value);
    
    // console.log(formData); return false;

    this.moduleservice.addPublisherSettingdata(formData).subscribe(
      res => {
        // console.log(res.status); return false;
        if (res.status !== 'error') {
          this.toastrService.success('Publisher Setting Saved Successfully');  
          this.getAllpublisherssetting();
          this.Submitted = false;
        }else{
          this.toastrService.error('Failed To Add Label');
        }
      },
      error => this.error = error,
    );     
   }

}
