import { GlobalVariable } from './../../global';
import { AfterViewInit, ChangeDetectionStrategy,ViewChild, ElementRef, ChangeDetectorRef,Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup,FormArray,Validators, FormBuilder } from '@angular/forms';
import { ModuleService } from './../module.service';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router, ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import Swal from 'sweetalert2';
import { ngxCsv } from 'ngx-csv';
import { Subject } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Title } from '@angular/platform-browser';
declare var $;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  pendingTotal:number;
  completedTotal:number;
  pendingdata:any[];
  completed:any[];
}

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {
  @ViewChild('dataTable', {static: true}) table: ElementRef;

  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};
  dtOptionsPending: DataTables.Settings = {   dom: 'rt'};
  private http: HttpClient;
  private ServerUrl = GlobalVariable.BASE_API_URL;
  error: {};
  loader: any;
  
  constructor(
    private renderer: Renderer2,
       private moduleservice: ModuleService,
       private toastrService:ToastrService,
       handler: HttpBackend,
       private fb: FormBuilder,
       private router: Router,       
       private route: ActivatedRoute,
       private cdRef: ChangeDetectorRef,
       private ngxLoader: NgxUiLoaderService,
       private titleService: Title,      
  ) {
    this.http = new HttpClient(handler);    
    window.addEventListener("click", (event) => {
        const el = document.getElementById('adjust_status');
        if (el) {
            let btn = document.getElementById("adjust_status");
            btn.addEventListener("click", (e:Event) => this.adjust_status(0));        
        }
        
        const el1 = document.getElementById('adjust_status_rej');
        if (el1) {
            let btn = document.getElementById("adjust_status_rej");
            btn.addEventListener("click", (e:Event) => this.adjust_status(1));        
        }
    });
    this.loader = {
      loaderId: 'customLoaderId',      
      loaderId1: 'customLoaderId1',      
    };
   }
  ngOnInit(): void {
    this.loadtasks()
  }

pendingdata:any = [];
completed:any = [];
opendata:any = [];
loadtasks(){
      // if ($.fn.DataTable.isDataTable(this.table) ) {
      //   $($('.dt').dataTable().fnDestroy());
      // }    
      this.ngxLoader.startLoader('customLoaderId');
      this.ngxLoader.startLoader('customLoaderId1');
      let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.http.post<any>(
              this.ServerUrl+'loadtasks/'+currentCompany.uuid,
              {}
            ).subscribe(resp => {
              const result = resp.data.filter(s => s.status==0);
              const result1 = resp.data.filter(s => s.status==1 || s.status==2);
              const result2 = resp.data.filter(s => s.status==3);
              
              // this.pendingdata = result;             
              this.completed = result1;             
              this.opendata = result;             
              
              this.dtTrigger.next();
              this.ngxLoader.stopLoader('customLoaderId');
              this.ngxLoader.stopLoader('customLoaderId1');
            },
            error => this.error = error
        );        
}

pfiltertype:any=0;
getParticipationType(type){
  $('.appendedtable').remove();
  if(type==0){
    $("#tabs2").removeClass("btn btn-primary btn-sm camactivetab");
    $("#tabs3").removeClass("btn btn-primary btn-sm camactivetab");
     $("#tabs1").addClass("camactivetab");this.pfiltertype=0;
     this.loadtasks();
  }
  if(type==1){
    $("#tabs1").removeClass("btn btn-primary btn-sm camactivetab");
    $("#tabs3").removeClass("btn btn-primary btn-sm camactivetab");
     $("#tabs2").addClass("camactivetab");this.pfiltertype=1;
     this.loadtasks();
  }
  if(type==2){
    $("#tabs1").removeClass("btn btn-primary btn-sm camactivetab");
    $("#tabs2").removeClass("btn btn-primary btn-sm camactivetab");
     $("#tabs3").addClass("camactivetab");this.pfiltertype=2;
     this.loadtasks();
  }
  
}

parreqdata:any;
reqdata(details:any) {    
  this.parreqdata = details;    
}

adjust_status(i:any){
  // console.log(this.selecteddata);
  let currentuser = JSON.parse(localStorage.getItem('currentUser'));  
  let status;
  if(i==0){
    status = "Approve";
  }
  if(i==1){
    status = "Reject";
  }
  Swal.fire({
    title: 'Are you sure ?',
    text: 'You want to '+status+' request to adjust conversion',
    
    showCancelButton: true,
    confirmButtonText: 'Yes, '+status+' it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      this.moduleservice.approvepayoutstatus(this.selecteddata,currentuser.uuid,i).subscribe(
        res => {
            Swal.fire(
              'Approved!',
              'Payout request '+status+' successfully.',
            )
            this.loadtasks();
            setTimeout(() => {
              Swal.close()
            }, 2000);           
          },
          error => this.error = error
      );      
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your Record is safe :)',
      )
      setTimeout(() => {
        Swal.close()
      }, 2000);
    }
  });
}
selecteddata:any = []
details(i,data){
  this.selecteddata = data;
  let calldata = JSON.parse(data.calldata);
  var date = new Date(0);
  date.setSeconds(calldata.duration); 
  var timeString = date.toISOString().substring(11, 19);
  // console.log(timeString)

  var tr = $('details-control').closest('tr').eq(i);
  // var divClass = $(".detailrow_"+i).eq(0).attr('class'); 
  var divClass1 = $('#home_'+i).attr('class');
  $('.appendedtable').remove();
  // console.log(divClass1);
  // if (divClass.indexOf('shown') > -1) {
  if (divClass1!=undefined) {
      $(".appendedtbl_"+i).remove();
        $(".detailrow_"+i).removeClass('shown');
    }else {
          var actions ='';
          if(data.status==0){
            actions += '<div class="">'+
            '<button id="adjust_status" class="btn btn-sm btn-primary">Approve</button>'+
            '<button id="adjust_status_rej" class="ml-2 btn btn-sm btn-danger">Reject</button>'+
            '</div>';
          }
          var payout="-";
          if(data.payout!=""){
            payout = '$'+data.payout;
          }
          var revenue="-";
          if(data.revenue!=""){
            revenue = '$'+data.revenue;
          }
          
          let recording = "-";
          if(calldata.recording!=null){
            recording="";
            recording += '<audio style="width: 130px;height: 30px;" controls muted loop><source src="https://d2actkasrcrrs8.cloudfront.net/'+calldata.recording+'" type="audio/mp3"></audio>';                              
          }

               

          var campaignsdata ='<div class="">'+
          '<div class="mt-2 mb-2">'+
          '<div class="row" style="border-bottom: 1px solid #403f3f;">'+
          '<div class="col-md-2">'+
          '<h6 style="font-size:13px;padding: 2px;">Task Details</h6>'+
          '</div>'+
          '<div class="col-md-9"></div>'+
          '<div class="col-md-1 text-right">'+
          // '<button class="btn btn-sm btn-danger" onClick="closeref('+i+')">Close</button>'+
          '</div>'+
          '</div>'+
          '</div>'+
          '<table class="table  table-striped targetreferencetable" style="width: 50%;">'+
          '<tr style="background: #24293a!important;">'+
          '<th>Requested Payout</th>'+
          '<th>Requested Revenue</th>'+      
          '<th>Reason For Request</th>'+      
          '</tr>'+
          '<tr style="background: #252d48!important;">'+
          '<td>'+payout+'</td>'+
          '<td>'+revenue+'</td>'+
          '<td>'+data.reason+'</td>'+
          '</tr>'+
          '</table>'+
          '<div class="mt-2 mb-2" style="border-bottom: 1px solid #403f3f;">'+
          '<h6 style="font-size:13px;padding: 2px;">Call Details</h6>'+
          '</div>'+
          '<table class="table table-striped targetreferencetable">'+
          '<tr style="background: #24293a!important;">'+
          '<th>Call Date</th>'+
          '<th>Campaign</th>'+      
          '<th>Caller ID</th>'+      
          '<th>Buyer</th>'+      
          '<th>Target</th>'+      
          '<th>Call Revenue</th>'+      
          '<th>Publisher</th>'+      
          '<th>Call Payout</th>'+      
          '<th>Call Duration</th>'+      
          '<th>Recording</th>'+      
          '</tr>'+
          '<tr style="background: #252d48!important;">'+
          '<td>'+calldata.start_stamp+'</td>'+
          '<td>'+calldata.campaign_name+'</td>'+
          '<td>'+calldata.caller_id_number+'</td>'+
          '<td>'+calldata.buyer_name+'</td>'+
          '<td>'+calldata.target_name+'</td>'+
          '<td>'+calldata.revenue+'</td>'+
          '<td>'+calldata.publisher_name+'</td>'+
          '<td>'+calldata.payout+'</td>'+
          '<td>'+timeString+'</td>'+
          '<td>'+recording+'</td>'+
          '</tr>'+
          '</table>'+
            actions+
          '</div>';

          var taskhistory = "";
          if(data.status==1 || data.status==2){
          taskhistory+='<section class="section" style="width:0"><div class="section-body"><div class="mt-4" style="border-bottom:1px solid #403f3f"><h6 style="font-size:13px;padding:2px">Task History</h6></div><div class="row"><div class="col-12"><div class="activities"><div class="activity mt-2"><div class="activity-icon bg-blue text-white"><i class="fas fa-info"></i></div><div class="activity-detail" style="width:35%!important"><span class="">'+data.created_at+'</span><br><span class="text-primary">Request started by '+data.from+'</span><div class="row" style="background:#192034!important"><div class="col-md-6">Conversion Payout Adjustment Requested</div><div class="col-md-6">'+data.payout+'</div></div><div class="row"><div class="col-md-6">Conversion Revenue Adjustment Requested</div><div class="col-md-6">'+data.revenue+'</div></div><div class="row" style="background:#192034!important"><div class="col-md-6" >Reason</div><div class="col-md-6">'+data.reason+'</div></div><div class="row"><div class="col-md-6">Call Conversion Payout Amount</div><div class="col-md-6">'+Number(calldata.payout)+'</div></div><div class="row" style="background:#192034!important"><div class="col-md-6">Call Conversion Revenue Amount</div><div class="col-md-6">'+Number(calldata.revenue)+'</div></div></div></div><div class="activity"><div class="activity-icon bg-success text-white"><i class="fas fa-check"></i></div><div class="activity-detail" style="width:35%!important"><div class="mb-2"><span class="">'+data.approved_at+'</span><br><span class="text-primary">Request to adjust revenue is approved by '+data.approver+'</span></div><div class="row" style="background:#192034!important"><div class="col-md-6">Revenue Adjusted</div><div class="col-md-6">'+Number(calldata.revenue)+'</div></div><div class="row"><div class="col-md-6">Payout Adjusted</div><div class="col-md-6">'+Number(calldata.payout)+'</div></div></div></div></div></div></div></div></section>';
          }
      $(".detailrow_"+i).after(
        '<div class="appendedtable appendedtbl_'+i+'">'+
        '<div class="tab-content">'+
          '<div id="home_'+i+'" class="tab-pane fade in active show">'+
            '<section class="section" style="width: 0px;">'+
            '<div class="section-body">'+              
                  '<div class="activities1" style="margin-left: 11px;display: block;width: 1553px!important;">'+
                    campaignsdata+
                    taskhistory+
                  '</span>'+            
            '</div>'+
          '</section> '+
          '</div>'+

      '</div>'+               
      '</div>'               
        );
        tr.addClass('shown');       
    }
    
}

}
