import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, NgForm } from '@angular/forms'
import { ToastrService } from 'ngx-toastr';
import { ModuleService } from '../module.service';
import { Router, ActivatedRoute } from '@angular/router';
declare var $;
@Component({
  selector: 'app-verification-management',
  templateUrl: './verification-management.component.html',
  styleUrls: ['./verification-management.component.scss']
})
export class VerificationManagementComponent implements OnInit {

  addForm: FormGroup;
  addEmailForm: FormGroup;
  editEmailForm: FormGroup;
  addWildcardForm: FormGroup;
  editWildcardForm: FormGroup;
  error: string;
  controlEnabled:boolean = true;
  rulesdata:any;
  ivrnamedata:any;  
  emaildata:any;  
  wildcarddata:any;  
  public show:boolean = false;
  result: any;
  showLoadingIndicator: any;
  data:any = {};

  constructor(
    private fb: FormBuilder,
    private toastrService:ToastrService, 
    private moduleService: ModuleService,
    private router: Router,

  ) {     
  }

  ngOnInit() { 
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000); 
        // console.log(this.initItemRow());
    this.addForm = this.fb.group({ 
      name: ['',Validators.required],
      itemRows: this.fb.array([]),
    }); 

    this.addEmailForm = this.fb.group({ 
      email: ['', [Validators.required, Validators.email]], 
    }); 
    
    this.editEmailForm = this.fb.group({ 
      uuid: ['', ''], 
      email: ['', [Validators.required, Validators.email]], 
    }); 

    this.addWildcardForm = this.fb.group({ 
      name: ['', Validators.required], 
      notes: ['', Validators.required], 
    }); 

    this.editWildcardForm = this.fb.group({ 
      uuid: ['', ''], 
      name: ['', Validators.required], 
      notes: ['', Validators.required], 
    }); 
    

    // $('#thenselect_0').hide();
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleService.getRules(this.currentCompany.uuid).subscribe(
      res => {
        this.rulesdata = res.rule_data;                
      },
      error => this.error = error,
    );  

    this.moduleService.GetIVRnamedata(this.currentCompany.uuid).subscribe(
      res => {
        this.ivrnamedata = res.ivr_data;       
      },
      error => this.error = error,
    );  
    
    if (this.currentCompany.uuid) {
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.moduleService.getverificationruledata(this.currentCompany.uuid).subscribe(
        res => {
          const control = <FormArray>this.addForm.controls['itemRows'];
          for (let index = 0; index < res.ruleset_data.length; index++) {

            control.push(this.initItemRow()); 
control.controls[index].get('ifval').setValue(res.ruleset_data[index].rule_condition_type);    
control.controls[index].get('isval').setValue(res.ruleset_data[index].rule_condition_is_value);  
if(res.ruleset_data[index].then_condition_action == 'FORWARD_TO_IVR_MENU'){
control.controls[index].get('thenselect').setValue(res.ruleset_data[index].then_condition_value); 
this.data[index]='FORWARD_TO_IVR_MENU'; 
}else{
control.controls[index].get('thentxt').setValue(res.ruleset_data[index].then_condition_value);    
this.data[index]='FORWARD_TO'; 
}  
// control.controls[index].get('thenval').setValue(res.ruleset_data[index].then_condition_action);    
}
        console.log(res);
        this.result = res.ruleset_data;
        }
    );
    
  } 
    
    this.getEmail(); 
    this.getWildcard(); 
  }

  editWildcard(uuid:string,name:string,notes:any){
    this.editWildcardForm.patchValue({
      uuid: uuid,
      name: name,
      notes: notes,            
    });    
  }

  editEmail (uuid:string,email:string){
    this.editEmailForm.patchValue({
      uuid: uuid,
      email: email,      
    });    
  }

  getEmail(){
    this.moduleService.GetEmails(this.currentCompany.uuid).subscribe(
      res => {
        this.emaildata = res.email_data;
      //  console.log(res); return false;
      },
      error => this.error = error,
    );
  }

  getWildcard(){
    this.moduleService.getWildcard(this.currentCompany.uuid).subscribe(
      res => {
        this.wildcarddata = res.data;
      //  console.log(res); return false;
      },
      error => this.error = error,
    );
  }

  thenvalue:string = 'FORWARD_TO';
  rowcount:number = 0;
  thenval(event:any,count:number){
    if(event.target.value == 'FORWARD_TO_IVR_MENU')
    {
      $('#thentxt_'+count).hide();
      $('#thenselect_'+count).show();
      this.thenvalue = event.target.value;              
    }else{
      $('#thentxt_'+count).show();
      $('#thenselect_'+count).hide();          
    }
  }

  submitted = false;
  currentCompany:any;
  get f() { return this.addForm.controls; }  
  onSubmit () {
    this.submitted = true;
    // if (this.addForm.invalid) {
    //     return;
    // }
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    // console.log(this.addForm.value);return false;
    this.moduleService.addVerificationRuleSet(this.addForm.value,this.currentCompany.uuid).subscribe(
      res => {
        // console.log(res); return false;
        if (res.status !== 'error') {
          this.toastrService.success('Verification Rules Saved Successfully');          
        }else{
          this.toastrService.error('Failed To Add');
        }
      },
      error => this.error = error,
    );     
  }

   initItemRow(){
    return this.fb.group({
      ifval: ['NUMBER',''],
      isval: ['',''],
      thenval: ['FORWARD_TO',''],
      thentxt: ['',''],
      thenselect: ['',''],
      andval: ['STOP',''],
    });
   
  }
  
  addRow(length:number){
    // alert(length);
    // length = length-1;
    const control = <FormArray>this.addForm.controls['itemRows'];
    control.push(this.initItemRow());
    this.data[length]='FORWARD_TO'; 
    
    // this.data[length]='FORWARD_TO'; 
    // $('#thenselect_'+length).hide();
    // alert($('#thenselect_0').val());    
  }

  totalrow:number;
  deleteRow(index:number){
    const control = <FormArray>this.addForm.controls['itemRows'];
    if(control!=null){
      this.totalrow = control.value.length;      
    }
    if(this.totalrow){
      control.removeAt(index);  
    }    
}

EmailSubmitted = false;
get Emailcontrol() { return this.addEmailForm.controls; }  
onEmailSubmit () {
  // const formData = new FormData();      
  this.EmailSubmitted = true;
  if (this.addEmailForm.invalid) {
      return;
  }
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  this.moduleService.addEmail(this.addEmailForm.value,this.currentCompany.uuid).subscribe(
    res => {
      // console.log(res); return false;
      if (res.status !== 'error') {
        this.toastrService.success('Email Added Successfully');
        this.getEmail();  
      }else{
        this.toastrService.error('Failed To Add');
      }
    },
    error => this.error = error,
  );     
}

editEmailSubmitted = false;
get editEmailcontrol() { return this.editEmailForm.controls; }  
oneditEmailSubmit () {
  this.editEmailSubmitted = true;
  if (this.editEmailForm.invalid) {
      return;
  }
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  this.moduleService.editEmail(this.editEmailForm.value,this.currentCompany.uuid).subscribe(
    res => {
      // console.log(res); return false;
      if (res.status !== 'error') {
        this.toastrService.success('Email Updated Successfully');
        this.getEmail();  
      }else{
        this.toastrService.error('Failed To Add');
      }
    },
    error => this.error = error,
  );     
}

closeModal() { 
  
  if(this.addWildcardForm.get('name').value && this.addWildcardForm.get('notes').value)
    {
      $("#addWildcard").modal("hide");    
      setTimeout(() => {
        this.WildcardSubmitted= false;
        this.addWildcardForm.reset();
      }, 2000);    
    }

    if(this.editWildcardForm.get('name').value && this.editWildcardForm.get('notes').value)
    {
      $("#editwildcard").modal("hide");    
      setTimeout(() => {
        this.editWildcardSubmitted= false;
        this.editWildcardForm.reset();
      }, 1000);       
    }

  if(this.addEmailForm.get('email').value)
  {
    $("#addLabels").modal("hide");    
    setTimeout(() => {
      this.EmailSubmitted = false;
      this.addEmailForm.reset();
    }, 2000);    
  }

  if(this.editEmailForm.get('email').value)
  {
    $("#editmail").modal("hide");    
    setTimeout(() => {      
      this.editEmailSubmitted=false;
      this.editEmailForm.reset();
    }, 2000);    
  }
}


// WildCard Add Model

WildcardSubmitted = false;
get wildcardcontrol() { return this.addWildcardForm.controls; }  
onwildcardSubmit () {
  // const formData = new FormData();      
  this.WildcardSubmitted = true;
  if (this.addWildcardForm.invalid) {
      return;
  }
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  this.moduleService.addWildcard(this.addWildcardForm.value,this.currentCompany.uuid).subscribe(
    res => {
      // console.log(res); return false;
      if (res.status !== 'error') {
        this.toastrService.success('New Verification Wildcard Added Successfully');
        this.getWildcard();  
      }else{
        this.toastrService.error('Failed To Add');
      }
    },
    error => this.error = error,
  );     
}

editWildcardSubmitted = false;
get editwildcardcontrol() { return this.editWildcardForm.controls; }  
onupdatewildcardSubmit() {
  // const formData = new FormData();      
  this.editWildcardSubmitted = true;
  if (this.editWildcardForm.invalid) {
      return;
  }
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  this.moduleService.editWildcard(this.editWildcardForm.value,this.currentCompany.uuid).subscribe(
    res => {
      // console.log(res); return false;
      if (res.status !== 'error') {
        this.toastrService.success('Verification Wildcard Updated Successfully');
        this.getWildcard();  
      }else{
        this.toastrService.error('Failed To Add');
      }
    },
    error => this.error = error,
  );     
}


_keyPress(event: any) {
  const isNumericInput = (event) => {
    const key = event.keyCode;
    return ((key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
    );
  };
  
  const isModifierKey = (event) => {
    const key = event.keyCode;
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
      (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      (
        // Allow Ctrl/Command + A,C,V,X,Z
        (event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
      )
  };
  
  const enforceFormat = (event) => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if(!isNumericInput(event) && !isModifierKey(event)){
      event.preventDefault();
    }
  };
  
  const formatToPhone = (event) => {
    if(isModifierKey(event)) {return;}
  
    const target = event.target;
    const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
    const zip = input.substring(0,3);
    const middle = input.substring(3,6);
    const last = input.substring(6,10);
  
    if(input.length > 6){target.value = `(${zip}) ${middle} - ${last}`;}
    else if(input.length > 3){target.value = `(${zip}) ${middle}`;}
    else if(input.length > 0){target.value = `(${zip}`;}
  };
  // const inputElement = document.getElementById('number');
  const inputElement =document.getElementsByClassName('number');
  if(inputElement){
    for (let i = 0; i < inputElement.length; i++) {
      inputElement[i].addEventListener('keydown',enforceFormat);
      inputElement[i].addEventListener('keyup',formatToPhone);    
  }
     }  
    
}




}
