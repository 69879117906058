import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModuleService } from './../module.service';
import { Chart } from 'angular-highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import * as Highcharts from 'highcharts';
import "datatables.net";
import { GlobalVariable } from '../../global';
import { HttpBackend, HttpClient } from '@angular/common/http';
HC_exporting(Highcharts);
import { Subject } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
declare var $;
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  private ServerUrl = GlobalVariable.BASE_API_URL;
  showLoadingIndicator: any;
  currentUser: any;
  currentCompany: any;
  error: any;
  NumberOfCalls: any;
  NumberOfIVR: any;
  NumberOfNumbers: any=0;
  NumberOfSMS: any;
  event:any="";
  dtTrigger: Subject<any> = new Subject<any>();
  dtTrigger_target: Subject<any> = new Subject<any>();

  @ViewChild('dataTable', {static: true}) table: ElementRef;
  @ViewChild('dataTable_top', {static: true}) table_top: ElementRef;
  @ViewChild('datatable_target', {static: true}) table_target: ElementRef;

  dtOptionsTop: DataTables.Settings = {   dom: 'rt'};

  dataTable: any;
  datatable_target: any;
  dtOptions: DataTables.Settings = {};
  dtOptions_target: DataTables.Settings = {};
  private http: HttpClient;
  labels: any[];
  loader:any;
  constructor(  private moduleservice: ModuleService,
    handler: HttpBackend,
    private titleService: Title,
    private ngxLoader: NgxUiLoaderService,
    ) {
      this.http = new HttpClient(handler);
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.loader = {
        loaderId: 'customLoaderId',
        loaderId1: 'customLoaderId1',
        loaderId2: 'customLoaderId2',
      }
     }

  currentDate = new Date();

public setTitle(newTitle: string) {
  this.titleService.setTitle(newTitle);
}

  ngOnInit() {
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000);
    this.setTitle('Dashboard')
    // console.log(this.currentCompany);

    // this.getNumberOfCounts();
    // this.getNumberOfIVR();
    // this.getNumberOfNumbers();
    // this.getNumberOfSMS();
    this.getChartNumberOfCalls(this.event);
    this.loadcalls();
    this.loadtoppreformenr();
    this.loadTargets();
  }

  numbers:any = [];
  loadTargets(){
      if ($.fn.DataTable.isDataTable(this.table_target.nativeElement) ) {
        $(this.table_target.nativeElement).dataTable().fnDestroy();
      }
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
     this.dtOptions_target = {
        "pagingType": 'full_numbers',
        // "lengthChange": false,
        "processing": true,
        "searching": false,
        "serverSide": true,
        "pageLength": 20,
        "lengthMenu": [[-1, 20, 50, 100], ["All", 20, 50, 100]],
        "order": [[ 1, "desc" ]],
        ajax: (dataTablesParameters: any, callback) => {
          this.http.post<DataTablesResponse>(
            this.ServerUrl+'gettargets/'+this.currentCompany.uuid,
            {'dataTablesParameters':dataTablesParameters, 'user_uuid':currentUser.uuid, 'role':currentUser.role,'type':1}, {}
          ).subscribe(resp => {
                    // let data = resp.data.filter((item,i)=>{
                    //   return item.month>0;
                    // })

                    // let finaldata = data.sort((a,b)=>{
                    //     return b.month - a.month;
                    // })
                    this.dtTrigger_target.next();
                    this.numbers = resp.data;
                    callback({
                      recordsTotal: resp.recordsTotal,
                      recordsFiltered: resp.recordsFiltered,
                        data: [],
                    });
          });
        },
        columns: [
          {data:"name"},{data:"hour"},{data:"active_status"},{data:"action"},
        ],
    };

  this.datatable_target = $(this.table_target.nativeElement);
  this.datatable_target.dataTable(this.dtOptions_target);
}



  chart:any;
  date1:string;
  bgcolorcode:any;
  getChartNumberOfCalls(event:any){
    // console.log(event);return false;
    if(event!=''){
      this.date1 = event.endDate._d;
    }else{
      this.date1 = JSON.stringify({startDate: moment().format("MM/DD/YYYY 00:00"), endDate: moment().format("MM/DD/YYYY 23:59")});
    }
    let params = {
      user_uuid : this.currentUser.uuid,
      role : "",
      date : this.date1,
    }
    // console.log(this.date1);
    this.moduleservice.getChartbyDate(this.currentCompany.uuid,params,this.currentUser.timezone).subscribe(
      res => {
        //  console.log(res);
        if ($("html").hasClass("dark")) {
          this.bgcolorcode='rgb(17,19,25)';
        }
        this.chart = new Chart({
          chart: {
            // polar: true,
            type: 'line',

            backgroundColor:this.bgcolorcode
          },
          exporting: {
            enabled: true
          },
          title: {
            text: ''
          },
          // subtitle: {
          //   text: document.ontouchstart === undefined ?
          //     'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
          // },
          credits: {
            enabled: false
          },
          xAxis: {
              minRange: 0,
              gridLineWidth: 0,
              title: {
                text: 'Hours'
              }
          },
          yAxis: {
            min:0,
            gridLineWidth: 1,
            title: {
              text: 'Number Of Calls'
            },
            gridLineColor: '#2f3031'
          },
          legend: {
            enabled: false
          },
          // plotOptions: {
          //   area: {
          //     fillColor: {
          //       linearGradient: {
          //         x1: 0,
          //         y1: 0,
          //         x2: 0,
          //         y2: 1
          //       },
          //       stops: [
          //         [0, '#7cb5ec'],
          //         [1, 'rgba(124,181,236,0)']
          //       ]
          //     },
          //     marker: {
          //       radius: 2
          //     },
          //     lineWidth: 1,
          //     states: {
          //       hover: {
          //         lineWidth: 1
          //       }
          //     },
          //     threshold: null
          //   }

          // },
          plotOptions: {
            area: {
                  fillColor: {
                    linearGradient: {
                      x1: 0,
                      y1: 0,
                      x2: 0,
                      y2: 1
                    },
                    stops: [
                      [0, '#7cb5ec'],
                      [1, 'rgba(124,181,236,0)']
                    ]
                  },
                  marker: {
                    radius: 2
                  },
                  lineWidth: 1,
                  states: {
                      hover: {
                          lineWidth: 1
                      }
                  },
                  threshold: null
              },

            line: {
                dataLabels: {
                    enabled: true
                },
                enableMouseTracking: false
            }
          },
          series: [{
            data: res,
            type: 'area',
            name:"Call Count"
          }]
        });
      },
      error => this.error = error
    );
  }

  // getchart(event:any){
  //   const date = event.startDate._d;
  //   // console.log();
  //   this.moduleservice.getChartbyDate(this.currentUser.uuid,date).subscribe(
  //     res => {
  //       this.NumberOfCalls = res;
  //     },
  //     error => this.error = error
  //   );
  // }


  loadcalls(){
    if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
      $(this.table.nativeElement).dataTable().fnDestroy();
    }

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    var that = this;
    this.dtOptions = {
      "pagingType": 'full_numbers',
      "processing": true,
      "serverSide": true,
      "paging":   false,
      "info":     false,
      // "lengthMenu": [[-1, 50, 100, 500, 2000, 5000], ["All", 50, 100, 500, 2000, 5000 ]],
      "pageLength": 20,
      // "ordering": false,
      "searching":false,
      ajax: (dataTablesParameters: any, callback) => {
        that.http.post<DataTablesResponse>(
          this.ServerUrl+'getlastweekcalls/'+this.currentCompany.uuid+'/'+this.currentUser.timezone+'/', {'dataTablesParameters':dataTablesParameters, 'user_uuid':this.currentUser.uuid, 'role':""}
         , {}
        ).subscribe(resp => {
                  // console.log(resp); return false;
                  that.labels = resp.data;
                  callback({
                      recordsTotal: resp.recordsTotal,
                      recordsFiltered: resp.recordsFiltered,
                      data: [],
                  });
              });
      },
      columns: [
        {data:"did"},{data:"incoming_number"},{data:"publisher"},{data:"target"},{data:"campaign"},{data:"routed_to"},{data:"routed_to"},{data:"routed_to"},
      ],
  };

  this.dataTable = $(this.table.nativeElement);
  this.dataTable.dataTable(this.dtOptions);
  }

  getNumberOfCounts(){
    // console.log(this.currentUser.timezone);
    this.moduleservice.getNumberOfCount(this.currentCompany.uuid,this.currentUser.timezone).subscribe(
      res => {
        this.NumberOfCalls = res.NumberOfcalls;
        this.NumberOfIVR = res.NumberOfivr;
        this.NumberOfNumbers = res.NumberOfnos;
        this.NumberOfSMS = res.NumberOfsms;
        // console.log(this.NumberOfCounts);
      },
      error => this.error = error
    );
  }

  pfiltertype:any=0;
  getParticipationType(type){
    if(type==0){
      $("#tabs2").removeClass("btn btn-primary btn-sm camactivetab");
      $("#tabs3").removeClass("btn btn-primary btn-sm camactivetab");
      $("#tabs1").addClass("camactivetab");this.pfiltertype=0;
      this.loadtoppreformenr();
    }
    if(type==1){
      $("#tabs1").removeClass("btn btn-primary btn-sm camactivetab");
      $("#tabs3").removeClass("btn btn-primary btn-sm camactivetab");
      $("#tabs2").addClass("camactivetab");this.pfiltertype=1;
      this.loadtoptarget();
    }
    if(type==2){
      $("#tabs1").removeClass("btn btn-primary btn-sm camactivetab");
      $("#tabs2").removeClass("btn btn-primary btn-sm camactivetab");
      $("#tabs3").addClass("camactivetab");this.pfiltertype=2;
      this.loadtopcampaign();
    }

  }


  top_performer:any = [];
  loadtoppreformenr(){
    if ($.fn.DataTable.isDataTable(this.table_top) ) {
      $($('#dt').dataTable().fnDestroy());
    }
    this.ngxLoader.startLoader('customLoaderId');
    let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let date1 = {startDate: moment().format("MM/DD/YYYY 00:00"), endDate: moment().format("MM/DD/YYYY 23:59")};
    this.http.post<any>(
            this.ServerUrl+'getcallsdata/'+currentCompany.uuid,
            {'role':currentUser.role,'user_uuid':currentUser.uuid,'timezone':this.currentUser.timezone,'groupby':4,date:date1}
          ).subscribe(resp => {
            let finaldata = resp.data.sort((a,b)=>{
              return b.revenue - a.revenue;
            })
            this.top_performer = finaldata;
            this.ngxLoader.stopLoader('customLoaderId');
            this.dtTrigger.next();
          },
          error => this.error = error
      );
  }

  top_target:any = [];
  loadtoptarget(){
    if ($.fn.DataTable.isDataTable(this.table_top) ) {
      $($('#dt').dataTable().fnDestroy());
    }
    this.ngxLoader.startLoader('customLoaderId1');
    let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    let date1 = {startDate: moment().format("MM/DD/YYYY 00:00"), endDate: moment().format("MM/DD/YYYY 23:59")};
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.http.post<any>(
            this.ServerUrl+'getcallsdata/'+currentCompany.uuid,
            {'role':currentUser.role,'user_uuid':currentUser.uuid,'timezone':this.currentUser.timezone,'groupby':2,date:date1}
          ).subscribe(resp => {
            let finaldata = resp.data.sort((a,b)=>{
              return b.revenue - a.revenue;
            })
            this.top_target = finaldata;
            this.ngxLoader.stopLoader('customLoaderId1');
            this.dtTrigger.next();
          },
          error => this.error = error
      );
  }

  top_campaign:any = [];
  loadtopcampaign(){
    if ($.fn.DataTable.isDataTable(this.table_top) ) {
      $($('#dt').dataTable().fnDestroy());
    }
    this.ngxLoader.startLoader('customLoaderId2');
    let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let date1 = {startDate: moment().format("MM/DD/YYYY 00:00"), endDate: moment().format("MM/DD/YYYY 23:59")};
    this.http.post<any>(
            this.ServerUrl+'getcallsdata/'+currentCompany.uuid,
            {'role':currentUser.role,'user_uuid':currentUser.uuid,'timezone':this.currentUser.timezone,'groupby':1,date:date1}
          ).subscribe(resp => {
            let finaldata = resp.data.sort((a,b)=>{
              return b.revenue - a.revenue;
            })
            this.top_campaign = finaldata;
            this.ngxLoader.stopLoader('customLoaderId2');
            this.dtTrigger.next();
          },
          error => this.error = error
      );
  }


  // getNumberOfIVR(){
  //   this.moduleservice.getNumberOfCount(this.currentUser.uuid,2).subscribe(
  //     res => {
  //       this.NumberOfIVR = res;
  //     },
  //     error => this.error = error
  //   );
  // }
  // getNumberOfNumbers(){
  //   this.moduleservice.getNumberOfCount(this.currentUser.uuid,3).subscribe(
  //     res => {
  //       this.NumberOfNumbers = res;
  //     },
  //     error => this.error = error
  //   );
  // }
  // getNumberOfSMS(){
  //   this.moduleservice.getNumberOfCount(this.currentUser.uuid,4).subscribe(
  //     res => {
  //       this.NumberOfSMS = res;
  //     },
  //     error => this.error = error
  //   );
  // }

}
