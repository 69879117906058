import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModuleService } from './../module.service';
import { HttpClient, HttpBackend} from '@angular/common/http';
import { Router} from '@angular/router';
import { GlobalVariable } from '../../global';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import * as moment from 'moment';
import "datatables.net";

declare var $;
const router = Router;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-shortlist',
  templateUrl: './shortlist.component.html',
  styleUrls: ['./shortlist.component.scss']
})
export class ShortlistComponent implements OnInit {
    private ServerUrl = GlobalVariable.BASE_API_URL;   
    selected: any;
    numbers: any = '';
    alwaysShowCalendars: boolean;
    showRangeLabelOnInput: boolean;
    keepCalendarOpeningWithRange: boolean;    
    ranges: any = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [
        moment()
          .subtract(1, 'month')
          .startOf('month'),
        moment()
          .subtract(1, 'month')
          .endOf('month')
      ],
     
    };
  
    @ViewChild('dataTable', {static: true}) table: ElementRef;
    dataTable: any;
    currentCompany: any;    
    error: {};
    showLoadingIndicator: any;
    dtOptions: DataTables.Settings = {};
    alldata: any;
    private http: HttpClient;    
    searchcallsform: FormGroup;
    submitted = false;
    constructor(
      private moduleservice: ModuleService,
      handler: HttpBackend,
      private fb: FormBuilder,
    ){ 
      this.http = new HttpClient(handler);
      this.alwaysShowCalendars = true;
      this.keepCalendarOpeningWithRange = true;
      this.showRangeLabelOnInput = true;
      this.selected = {startDate: moment().startOf('day'), endDate: moment().endOf('day')};    
    }
  
    ngOnInit() {
      this.showLoadingIndicator= true;
      setTimeout(()=>{this.showLoadingIndicator= false; },1000);
      this.loadcalls();   
    }
    get f() { return this.searchcallsform.controls; }  
  
    loadcalls(){
      if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
        $(this.table.nativeElement).dataTable().fnDestroy();
      } 
      
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));      
      this.dtOptions = {
        "pagingType": 'full_numbers',
        "processing": true,
        "serverSide": true,          
        "pageLength": 20,
        "order": [[ 1, "desc" ]],
        ajax: (dataTablesParameters: any, callback) => {
          this.http.post<DataTablesResponse>(
            this.ServerUrl+'getshortlonglistnumbers/'+this.currentCompany.uuid+'/0', {'dataTablesParameters':dataTablesParameters,'number':this.numbers,'date':this.selected}
           , {}
          ).subscribe(resp => {
                    // console.log(resp); return false;
                    this.alldata = resp.data;   
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: [],
                    });
                });
        },
        columns: [
          {data:"number"},{data:"forward_to"},{data:"created_at"}
        ],
    };
  
    this.dataTable = $(this.table.nativeElement);
    this.dataTable.dataTable(this.dtOptions);
    }
}
