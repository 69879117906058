import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-page-not-found-component',
  templateUrl: './page-not-found-component.component.html',
  styleUrls: ['./page-not-found-component.component.scss']
})
export class PageNotFoundComponentComponent implements OnInit {
  showLoadingIndicator: boolean;

  constructor(private router: Router,) { }
  
  ngOnInit() {
    this.router.navigate(['/404']);
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000);
  }
}
