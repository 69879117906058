import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModuleService } from '../../module.service';
import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from './../../../global';
declare var $;
const router = Router;
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-basic-setting',
  templateUrl: './basic-setting.component.html',
  styleUrls: ['./basic-setting.component.scss']
})
export class BasicSettingComponent implements OnInit {
    private ServerUrl = GlobalVariable.BASE_API_URL;
    error: {};
    showLoadingIndicator: any;
    private http: HttpClient;
    public settings: any;
    addForm: FormGroup;
    currentCompany: any;
    Companyuuid: any;
    constructor(
      private moduleservice: ModuleService,
      private toastrService:ToastrService,
      handler: HttpBackend,
      private fb: FormBuilder,
      private titleService: Title
    ) {
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.Companyuuid = this.currentCompany.uuid;
      this.http = new HttpClient(handler);
     }

     public setTitle(newTitle: string) {
      this.titleService.setTitle(newTitle);
    }
  
    ngOnInit(): void {
      this.setTitle('Forwarding Number Directory')
      this.showLoadingIndicator= true;
      setTimeout(()=>{this.showLoadingIndicator= false; },1000);
      this.getAllbasicsetting();
      this.addForm = this.fb.group({
        // , Validators.required
        googlesheetlink : [''],
        xyzrules : [''],   
        email : [''],   
       });
    }

  allBuyers: any=[];
  selectedItems: any=[];
  getAllbasicsetting(){
    this.currentCompany = JSON.parse(localStorage.getItem('currentUser'));
    const p1 = new Promise((result,rej)=>{
      this.moduleservice.getAllbasicsetting(this.currentCompany.uuid).subscribe(
        res => {
          result(res)
          //  console.log(res); return false;           
        },
        error => this.error = error,
      ); 
    })
      const prm = [p1];
  
      Promise.all(prm).
          then((results) =>{
            // console.log(results);
            
            this.allBuyers = results[0]['emails'];   
            if(results[0]['xyz_rules']==1){
              this.xyzrules = true;
            }else{
              this.xyzrules = false;
            }
            // // this.allBuyers = res['emails'];
    
            if(results[0]['emails'].length>0){        
              let text =  results[0]['emails'].filter(x => x.uuid == results[0]['email'])[0];
              if(text!=undefined){
                this.selectedItems = [{"id":text.uuid,"itemName":text.itemName}];
              }
            }           
           
            this.addForm.patchValue({
              googlesheetlink: results[0]['google_sheet_link'],               
              xyzrules: this.xyzrules,               
            });                      
          });
   

     
  }  
 
  xyzrules:any=true;
  xyzrules_enable(event:any){
    this.xyzrules = event.target.checked; 
  }  
 
  isaddemail:any=0;
  isaddemailfun(type:any){
    this.isaddemail = type; 
  }  
  
  Submitted = false;
  //Add Publisher
  get addformcontrol() { return this.addForm.controls; }  
  onSubmit () {
    // console.log(this.addemailval);retu
    
      const formData = new FormData();      
      this.Submitted = true;
      if (this.addForm.invalid) {
          return;
      }
      
      this.currentCompany = JSON.parse(localStorage.getItem('currentUser'));
      formData.append('user_uuid', this.currentCompany.uuid);
      formData.append('google_sheet_link', this.addForm.get('googlesheetlink').value);
      formData.append('email', JSON.stringify(this.addForm.get('email').value));
      formData.append('xyz_rules', this.xyzrules);
      
      // console.log(formData); return false;
  
      this.moduleservice.addBasicSettingdata(formData).subscribe(
        res => {
          // console.log(res.status); return false;
          if (res.status !== 'error') {
            this.toastrService.success('Basic Setting Saved Successfully');  
            this.getAllbasicsetting();
            this.Submitted = false;
          }else{
            this.toastrService.error('Failed To Save Settings');
          }
        },
        error => this.error = error,
      );     
     }

    addemailval:any="";
    addnewemail() {
      if(this.addemailval==""){
        Swal.fire(
          'Error!',
          'Please add email value',    
        );
        return
      }
     
      this.moduleservice.addemail(this.addemailval,this.currentCompany.uuid).subscribe(
        res => {
          // console.log(res.status); return false;
          if (res.status !== 'error') {
            this.toastrService.success('Email added successfully');  
            this.getAllbasicsetting();
            this.isaddemail = 0;
            this.addemailval = "";
          }else{
            this.toastrService.error('Failed To add email.');
          }
        },
        error => this.error = error,
      );
      
    }
  
  }
  