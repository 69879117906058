import { FormBuilder, Validators, FormGroup} from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModuleService } from './../../module.service';
import { HttpClient, HttpBackend} from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
//import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from '../../../global';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Title } from '@angular/platform-browser';
declare var $;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-blocked-numbers',
  templateUrl: './blocked-numbers.component.html',
  styleUrls: ['./blocked-numbers.component.scss']
})
export class BlockedNumbersComponent implements OnInit {
    private ServerUrl = GlobalVariable.BASE_API_URL;
  
    @ViewChild('dataTable', {static: true}) table: ElementRef;
    dataTable: any;
    currentCompany: any;
    label: Number;  
    error: {};
    showLoadingIndicator: any;
    dtOptions: DataTables.Settings = {};
    blocknumbers: any;  
    private http: HttpClient;
    Submitted = false;
    addForm: FormGroup;
    editForm: FormGroup;
    checkForm: FormGroup;
    ischecked: string = '1';
    data:any = {};
    constructor(
      private moduleservice: ModuleService,
      private toastrService:ToastrService,
      handler: HttpBackend,
      private fb: FormBuilder,
      private titleService: Title
    ){ 
      this.http = new HttpClient(handler);
    }
  
    selscopetype:number=0;
    public selscopetypeval(e: any) {
      this.selscopetype = e.target.value
      this.addForm.get('scopetype').setValue(e.target.value);    
    }
  
    public selscopetypevaledit(e: any) {
      this.selscopetypeedit = e.target.value
      this.editForm.get('scopetype').setValue(e.target.value);
    }
    public setTitle(newTitle: string) {
      this.titleService.setTitle(newTitle);
    }
  

    allcampaign:any;
    alltarget:any;
    allbuyer:any;
    ngOnInit() {
      this.setTitle('Block Number')
      this.showLoadingIndicator= true;
      setTimeout(()=>{this.showLoadingIndicator= false; },1000);
      this.loadblocknumbers();
      
      this.addForm = this.fb.group({
        block_type: [''],
        blocked_number: ['',Validators.required],      
        blocked_prefix: [''],      
        scopetype: [''],      
        scope: [''],      
        campaign_uuid: [''],      
        target_uuid: [''],      
        buyer_uuid: [''],      
        duration: [''],              
      });

      this.addForm.get('block_type').setValue(0);
      // this.editForm.get('editblock_type').setValue(0);

  
      this.editForm = this.fb.group({
        uuid: [''],
        editblock_type: [''],
        blocked_number: ['',Validators.required],      
        blocked_prefix: [''], 
        scopetype: [''],      
        scope: [''],      
        campaign_uuid: [''], 
        target_uuid: [''],      
        buyer_uuid: [''],           
        duration: [''],              
      });
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.moduleservice.getallcampain(this.currentCompany.uuid).subscribe(
          res => {
            // console.log(res);return false;    
            this.allcampaign= res.active     
          },
          error => this.error = error
      ); 
      this.moduleservice.getAlltarget(this.currentCompany.uuid).subscribe(
          res => {
            // console.log(res);return false;    
            this.alltarget= res
          },
          error => this.error = error
      ); 
      this.moduleservice.getallbuyers(this.currentCompany.uuid).subscribe(
          res => {
            // console.log(res);return false;    
            this.allbuyer= res     
          },
          error => this.error = error
      ); 
    }
  
    block:any = 0;    
    block_numbertype(val:number) {
      this.block = val;
      this.addForm.get('block_type').setValue(this.block);
    }
   
    iscampaign:any = 0;
    scopetype(val:number) {
      this.iscampaign = val;
      this.editiscampaign = val;
      this.addForm.get('scope').setValue(val);
      this.editForm.get('scope').setValue(val);
    }
    
    istarget:any = 0;
    editistarget:any = 0;
    scopetype_target(val:number) {
      this.istarget = val;
      this.editistarget = val;
      this.addForm.get('scope').setValue(val);
      this.editForm.get('scope').setValue(val);
    }
 
    isbuyer:any = 0;
    eidtisbuyer:any = 0;
    scopetype_buyer(val:number) {
      this.isbuyer = val;
      this.eidtisbuyer = val;
      this.addForm.get('scope').setValue(val);
      this.editForm.get('scope').setValue(val);
    }
    
    isspecifyduration:any = 0;
    isdurationadd(event:any) {
      // console.log(event.target.checked);
      // if(event.target.checked==true){
        this.isspecifyduration = event;
      // }
      // if(event.target.checked==false){
      //   this.editisspecifyduration = 0;
      //   this.isspecifyduration = 0;
      // }
    }
    
    isduration(event:any) {
      // console.log(event.target.checked);
      // if(event.target.checked==true){
        this.editisspecifyduration = event;
     
      // }
      // if(event.target.checked==false){
      //   this.editisspecifyduration = 0;
      //   this.isspecifyduration = 0;
      // }
    }
  
    editblock:any = 0;    
    editblock_numbertype(val:number) {
      // alert(val);
      this.editblock = val;
      this.editForm.get('editblock_type').setValue(val);
    }
   
    editiscampaign:any = 0;
    editscopetype(val:number) {
      this.editiscampaign = val;
      this.editForm.get('scope').setValue(val);
    }
    
    editisspecifyduration:any = 0;
    editisduration(event:any) {
      if(event.target.checked==true){
        this.editisspecifyduration = 1;
      }
      if(event.target.checked==false){
        this.editisspecifyduration = 0;
      }
    }
  
   
  
    loadblocknumbers(){
        if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
          $(this.table.nativeElement).dataTable().fnDestroy();
        } 
        this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
        this.dtOptions = {
          "pagingType": 'full_numbers',
          "processing": true,
          "serverSide": true,          
          "pageLength": 20,
          "lengthMenu": [[-1, 20, 100, 500, 2000], ["All", 20, 100, 500, 2000]],   
          "order": [[ 4, "desc" ]],
          ajax: (dataTablesParameters: any, callback) => {
            this.http.post<DataTablesResponse>(
              this.ServerUrl+'getblocked_numbers/'+this.currentCompany.uuid,            
              dataTablesParameters, {}
            ).subscribe(resp => {
                      // console.log(resp.data);
                      this.blocknumbers = resp.data;   
                      callback({
                          recordsTotal: resp.recordsTotal,
                          recordsFiltered: resp.recordsFiltered,
                          data: [],
                      });
                  });
          },
          columns: [
            {data:"blocked_number"},{data:"scopetype"},{data:"scope"},{data:"blocked_until"},{data:"created_at"},{data:"action"},
          ],
          columnDefs: [
            { 
                "targets": [0], 
                "orderable": false,
            },
            ],
      };
  
      this.dataTable = $(this.table.nativeElement);
      this.dataTable.dataTable(this.dtOptions);
      }
  
      Delete(uuid: any) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You want to Delete Record!',
          
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
            this.moduleservice.deleteblckedno(uuid).subscribe(
                res => {
                  // console.log(res);return false;
                  Swal.fire(
                    'Deleted!',
                    'Record Deleted Successfully.',
                  )
                  setTimeout(() => {
                    Swal.close()
                  }, 2000);
  
                  this.loadblocknumbers();
                },
                error => this.error = error
            );       
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Your Record is safe :)',
              'error'
            )
            setTimeout(() => {
              Swal.close()
            }, 2000);
          }
        });
      }

  resetForm(){
    this.selscopetype = 0; 
    this.addForm.get('scopetype').setValue(0);    
    this.isspecifyduration = 0;      
  }
  
  //Add Labels
  get labelcontrol() { return this.addForm.controls; }  
  onSubmit () {
      const formData = new FormData(); 
      this.Submitted = true;
      if (this.addForm.invalid) {
          return;
      }
      
      // alert(this.isspecifyduration);return false;

      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      formData.append('company_uuid', this.currentCompany.uuid);
      formData.append('timezone', currentUser.timezone);
      formData.append('block_type', this.addForm.get('block_type').value);
      formData.append('blocked_number', this.addForm.get('blocked_number').value);
      
      formData.append('blocked_prefix', this.addForm.get('blocked_prefix').value);
      formData.append('scopetype', this.addForm.get('scopetype').value);
      formData.append('scope', this.addForm.get('scope').value);
      formData.append('campaign_uuid', this.addForm.get('campaign_uuid').value);
      formData.append('target_uuid', this.addForm.get('target_uuid').value);
      formData.append('buyer_uuid', this.addForm.get('buyer_uuid').value);
    
      formData.append('is_duration', this.isspecifyduration);
      formData.append('duration', this.addForm.get('duration').value);
      
       this.moduleservice.addblockedcalls(formData,this.currentCompany.uuid).subscribe(
        res => {
          // console.log(res); return false;
          if (res.status !== 'error') {
            this.toastrService.success('Number Blocked Successfully'); 
            this.loadblocknumbers();        
            this.Submitted = false;
       
          }else{
            this.toastrService.error('Failed To Add');
          }
        },
        error => this.error = error,
      );     
     }
  
     closeModal() {    
      if(this.addForm.get('blocked_number').value!=""){
        $("#addLabels").modal("hide");   
      }
        if(this.addForm.get('block_type').value==0 || this.addForm.get('block_type').value==1 || this.addForm.get('block_type').value==2)
        {
       
                
        $(".custom-switch-input").prop('checked',false);
        this.Submitted = false;
        setTimeout(() => {
          this.addForm.reset(); 
           this.block = 0;
          this.isspecifyduration = 0;
          this.iscampaign = 0;
          this.block_numbertype(0);
          this.scopetype(0);                
        }, 2000);    
       }
     }

    editcloseModal() {
    $("#editLabels").modal("hide");         
      $(".custom-switch-input").prop('checked',false);
      this.Submitted = false;      
    }

    selscopetypeedit:number = 0

    // Edit data
    editdata(uuid:string,block_type:number,blocked_number:string,blocked_prefix:string,scope:any,campaign_uuid:any,is_duration,blocked_until:any,scopetype:string,target_uuid:string,buyer_uuid:string){
      this.editblock = block_type;
      this.editiscampaign = scope;    
      this.eidtisbuyer = scope;    
      this.editistarget = scope;    
      this.editForm.get('scopetype').setValue(scopetype);
      this.editForm.get('scope').setValue(scope);
      this.selscopetypeedit = +scopetype;    
      console.log(is_duration);
      // if(blocked_until!='' && blocked_until!='∞'){
        this.editisspecifyduration=is_duration;
      // }else{
      //   this.editisspecifyduration=0;
      // }
      this.editForm.patchValue({
        uuid: uuid,
        blocked_number: blocked_number,
        blocked_prefix: blocked_number,       
        campaign_uuid: campaign_uuid,      
        target_uuid: target_uuid,      
        buyer_uuid: buyer_uuid,      
        duration: blocked_until!="∞"?blocked_until:"",                
      });  
      $("#editLabels").modal("show");     
    }
    
    get labeleditcontrol() { return this.editForm.controls; }  
    onEditSubmit () {
        const formData = new FormData();      
        this.Submitted = true;
        if (this.editForm.invalid) {
            return;
        }
        
        // alert(this.editForm.get('editblock_type').value);
        // alert(this.editblock)
        // alert(this.editisspecifyduration);
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      formData.append('company_uuid', this.currentCompany.uuid);
      formData.append('block_type', this.editForm.get('editblock_type').value);
      formData.append('company_uuid', this.currentCompany.uuid);
      formData.append('timezone', currentUser.timezone);
      formData.append('blocked_number', this.editForm.get('blocked_number').value);
        
        formData.append('blocked_prefix', this.editForm.get('blocked_prefix').value);
        formData.append('scope', this.editForm.get('scope').value);
        formData.append('campaign_uuid', this.editForm.get('campaign_uuid').value);

        formData.append('scopetype', this.editForm.get('scopetype').value);
        formData.append('target_uuid', this.editForm.get('target_uuid').value);
        formData.append('buyer_uuid', this.editForm.get('buyer_uuid').value);

        formData.append('duration', this.editForm.get('duration').value);
        formData.append('is_duration', this.editisspecifyduration);

        this.moduleservice.editblockednumbers(formData,this.editForm.get('uuid').value).subscribe(
          res => {
            // console.log(res); return false;
            if (res.status !== 'error') {
              this.loadblocknumbers();
              this.Submitted = false;
              this.toastrService.success('Blocked Number data Updated Successfully');         
            }else{
              this.toastrService.error('Failed To Update Data');
            }
          },
          error => this.error = error,
        );     
       }

    
       exportdata(){
        var options = { 
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true, 
          // showTitle: true,
          // title: 'Your title',
          useBom: true,
          // noDownload: true,
          headers: ["Label Name", "Notes","Created At"]
        };  
       
        new ngxCsv(this.blocknumbers, 'All Blocked Numbers', options);
       
      }   
  
    checkalldelete(event) {
      const checked = event.target.checked;
      this.blocknumbers.forEach(chkbox => chkbox.selected = checked);    
    }
  
    checkboxes:any = [];  
    deleteselected(event:any){ 
      // $("#customCheck1").prop("checked", false);    
      if(event.target.checked==true)
      {
        if(event.target.value=='on'){
          this.checkboxes = [];
          this.blocknumbers.forEach(lbl => {
            this.checkboxes.push(lbl.id);
          });
        }else{ 
          if(this.checkboxes.length+1==this.blocknumbers.length){
            $( "#customCheck1" ).prop( "checked", true );
          }
          this.checkboxes.push(event.target.value);            }            
          this.ischecked = '0';  
      }else{ 
        // console.log(this.checkboxes);      
        if(this.checkboxes.length!=0){
          $( "#customCheck1" ).prop( "checked", false );        
        }
        if(event.target.value!='on'){
          const index = this.checkboxes.indexOf(event.target.value);
          this.checkboxes.splice(index, 1);
        }else{
          this.checkboxes = [];
        }
        this.ischecked = '1';
      }    
      // console.log(this.checkboxes); 
    }
  
    onDeleteSubmit(){    
      if(this.checkboxes.length==0){
        this.toastrService.error('Please Select Row To Delete');
      }else{
        // console.log(this.checkboxes);  
        Swal.fire({
          title: 'Are you sure?',
          text: 'You want to Delete Selected Record!',
          
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
  
            this.moduleservice.deleteSelectedLabels(this.checkboxes).subscribe(
              res => {
                  Swal.fire(
                    'Deleted!',
                    'Records Deleted Successfully.',
                   )
                  setTimeout(() => {
                    Swal.close()
                  }, 2000);
  
                this.loadblocknumbers();
              },
              error => this.error = error
            );      
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Your Record is safe :)',
              'error'
            )
            setTimeout(() => {
              Swal.close()
            }, 2000);
          }
        });
      }
      
    }


  }
