import { FormBuilder,FormGroup} from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModuleService } from './../module.service';
import { HttpClient, HttpBackend} from '@angular/common/http';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import 'sweetalert2/src/sweetalert2.scss'
import { GlobalVariable } from '../../global';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import * as moment from 'moment';
import "datatables.net";

declare var $;
const router = Router;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.scss']
})
export class SmsComponent implements OnInit {

    private ServerUrl = GlobalVariable.BASE_API_URL;
  
    selected: any;
    sms: any = '';
    alwaysShowCalendars: boolean;
    showRangeLabelOnInput: boolean;
    keepCalendarOpeningWithRange: boolean;
    maxDate: moment.Moment;
    minDate: moment.Moment;
    ranges: any = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [
        moment()
          .subtract(1, 'month')
          .startOf('month'),
        moment()
          .subtract(1, 'month')
          .endOf('month')
      ],   
    };
  
    @ViewChild('dataTable', {static: true}) table: ElementRef;
    dataTable: any;
    currentCompany: any;
    label: Number;
    error: {};
    showLoadingIndicator: any;
    dtOptions: DataTables.Settings = {};
    smses: any;
    private http: HttpClient;
    searchcallsform: FormGroup;
    submitted = false;
  
  
    constructor(
      private moduleservice: ModuleService,
      private toastrService:ToastrService,
      handler: HttpBackend,
      private fb: FormBuilder,
   
  
    ){ 
      this.http = new HttpClient(handler);
      this.alwaysShowCalendars = true;
      this.keepCalendarOpeningWithRange = true;
      this.showRangeLabelOnInput = true;
      this.selected = {startDate: moment().startOf('day'), endDate: moment().endOf('day')};      
    }
  
    ngOnInit() {
      this.showLoadingIndicator= true;
      setTimeout(()=>{this.showLoadingIndicator= false; },1000);
      this.loadcalls();   
    }
    get f() { return this.searchcallsform.controls; }  
  
    loadcalls(){
      if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
        $(this.table.nativeElement).dataTable().fnDestroy();
      } 
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      var that = this;
      this.dtOptions = {
        "pagingType": 'full_numbers',
        "processing": true,
        "serverSide": true,          
        "pageLength": 20,
        "order": [[ 2, "desc" ]],
        ajax: (dataTablesParameters: any, callback) => {
          that.http.post<DataTablesResponse>(
            this.ServerUrl+'getsms/'+this.currentCompany.uuid, {'dataTablesParameters':dataTablesParameters,'sms':this.sms,'date':this.selected}
           , {}
          ).subscribe(resp => {
                    that.smses = resp.data;   
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: [],
                    });
                });
        },
        columns: [
          {data:"sms_from"},{data:"sms_to"},{data:"time"},{data:"message"},{data:"source_network"},{data:"date_received"}
        ],
    };
  
    this.dataTable = $(this.table.nativeElement);
    this.dataTable.dataTable(this.dtOptions);
    }
  
    exportdata(){
      var options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true, 
        useBom: true,
        headers: ["From", "To","Time","Message","Source Network","Date Received"]
      };
  
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.moduleservice.getsmsForExport(this.currentCompany.uuid).subscribe(
        res => {
          new ngxCsv(res.data,'SMS Logs', options);
        },
        error => this.error = error
    );      
    }
  
}
