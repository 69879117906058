import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModuleService } from './../../module.service';
import { Chart } from 'angular-highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import * as Highcharts from 'highcharts';
HC_exporting(Highcharts);
import { FormBuilder, Validators, FormGroup} from '@angular/forms';
import * as moment from 'moment';
import "datatables.net";
import { GlobalVariable } from '../../../global';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
declare var $;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-report-labelsummary',
  templateUrl: './report-labelsummary.component.html',
  styleUrls: ['./report-labelsummary.component.scss']
})
export class ReportLabelsummaryComponent implements OnInit {
    private ServerUrl = GlobalVariable.BASE_API_URL;
   
    showLoadingIndicator: any;
    currentUser: any;
    error: any;
    event:any="";
    CallReportForm: FormGroup;
  
    selected: any;
    numbers: any = '';
    alwaysShowCalendars: boolean;
    showRangeLabelOnInput: boolean;
    keepCalendarOpeningWithRange: boolean;
    maxDate: moment.Moment;
    minDate: moment.Moment;
    
    @ViewChild('dataTable', {static: true}) table: ElementRef;
    dataTable: any;
    dtOptions: DataTables.Settings = {};
    private http: HttpClient;
    // LabelSubmitted = false;
    searchcallsform: FormGroup;
    submitted = false;
  
  
    ranges: any = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [
        moment()
          .subtract(1, 'month')
          .startOf('month'),
        moment()
          .subtract(1, 'month')
          .endOf('month')
      ],
      // 'Last 3 Month': [
      //   moment()
      //     .subtract(3, 'month')
      //     .startOf('month'),
      //   moment()
      //     .subtract(1, 'month')
      //     .endOf('month')
      // ]
    };
  
  
    constructor(  private moduleservice: ModuleService,private fb: FormBuilder,
      private toastrService:ToastrService,
      handler: HttpBackend,
      ) {
        this.http = new HttpClient(handler);
  
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.keepCalendarOpeningWithRange = true;
        this.showRangeLabelOnInput = true;
        this.selected = {startDate: moment().startOf('day'), endDate: moment().endOf('day')};      
       }
    
  
    ngOnInit() {
      this.showLoadingIndicator= true;
      setTimeout(()=>{this.showLoadingIndicator= false; },1000);
  
      this.CallReportForm = this.fb.group({
        date: ['',''],      
        label: ['',''],      
        duration_type: ['',''],      
        duration: ['',''],      
      });
      // this.getChartNumberOfCalls(this.event);
      this.getAllLabels(this.currentUser.uuid);
    }
  
    get f() { return this.searchcallsform.controls; }  
  
  
  
    alllabel:any;
    getAllLabels(company_uuid:any){
      this.moduleservice.getAllLabels(this.currentUser.uuid).subscribe(
        res => {
          this.alllabel = res;
          console.log(res);
        },
        error => this.error = error
      );  
    }
  
    chart:any;
    onCallReportSubmit () {
      // console.log(this.CallReportForm.value); return false;
      this.moduleservice.getLabelReports(this.CallReportForm.value,this.currentUser.uuid).subscribe(
        res => {
          // console.log(res.date); return false;
  
          this.chart = new Chart({
            chart: {
              type: 'column',
          },
          colors: [
            '#4572A7', 
            '#AA4643', 
            '#89A54E', 
            '#80699B', 
            '#3D96AE', 
            '#DB843D', 
            '#92A8CD', 
            '#A47D7C', 
            '#B5CA92'
            ],
          title: {
            text: 'Calls By Labels'
          },
          subtitle: {
            text: document.ontouchstart === undefined ?
            'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
          },
          accessibility: {
              announceNewData: {
                  enabled: true
              }
          },
          xAxis:{
            title: {
              text: 'Labels'
            },
            categories:res.labels
         },
          yAxis: {
              title: {
                text: 'Number Of Calls'
              }
      
          },
          legend: {
              enabled: false
          },
          plotOptions: {
              series: {
                  borderWidth: 0,
                  dataLabels: {
                      enabled: true,
                      format: '{point.y} Call'
                  },
              },
              column: {
                colorByPoint: true
            }
              
          },
      
          tooltip: {
              headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
              pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
          },
      
          series: [{
            data: res.count,
            type: 'column',      
            name:"Call Count"
          }],    
          
        }); 
        },
        error => this.error = error
      );  
   
        if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
          $(this.table.nativeElement).dataTable().fnDestroy();
        } 
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
          var that = this;
          this.dtOptions = {
            "pagingType": 'full_numbers',
            "processing": true,
            "searching":false,
            "serverSide": true,          
            "lengthMenu": [[-1, 50, 100, 500, 2000, 5000], ["All", 50, 100, 500, 2000, 5000 ]],
            "pageLength": 500,
            "order": [[ 2, "desc" ]],
            ajax: (dataTablesParameters: any, callback) => {
              that.http.post<DataTablesResponse>(
                this.ServerUrl+'getdaywiseLabelSummaryReport/'+this.currentUser.uuid, {'dataTablesParameters':dataTablesParameters,'formvalue':this.CallReportForm.value}
              , {}
              ).subscribe(resp => {
                        // console.log(resp.data); return false;
                        that.numbers = resp.data;   
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: [],
                        });
                    });
            },
            columns: [
              {data:"created_at"},{data:"label"},{data:"noofcalls"},{data:"avg"},{data:"call_duration"},{data:"ivr_inputs"},{data:"revenue"},
            ],
        };
  
        this.dataTable = $(this.table.nativeElement);
        this.dataTable.dataTable(this.dtOptions);
  }
  }