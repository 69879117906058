import { SidebarService } from './sidebar.service';
import { Component, OnInit, OnDestroy  } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, ParamMap,Router } from '@angular/router';
import { Menu } from './menu';
import { ModuleService } from '../modules/module.service';
declare var $;
import { GlobalVariable } from './../global';
import {} from 'bootstrap';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  private subscriptionName: Subscription; //important to create a subscription
  private ServerUrl = GlobalVariable.BASE_URL;
  menu: Menu;
  error: {};
  routes: string;
  routerurl:any;
  constructor(
    private route: ActivatedRoute,
    private sidebarservice: SidebarService,
    private moduleservice: ModuleService,
    public router: Router,   
  ){ 
    // subscribe to sender component messages
    this.subscriptionName= this.moduleservice.getUpdate().subscribe
    (message => { 
      if(message.text==1){
        this.ngOnInit();
      }
    });
    this.routerurl = window.location.href.replace(this.ServerUrl, "");
  }

  public userName:string;
  public firstName: string;
  public lastName: string;
  public companyName: string;

  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.subscriptionName.unsubscribe();
  }

  role:any="";
  ngOnInit() {
    this.currentCompany = JSON.parse(localStorage.getItem('currentUser'));
    // console.log(this.currentCompany); return
    if(this.currentCompany == null){
      return
    }
    
    var loggedinuser = JSON.parse(localStorage.getItem('currentUser'));
    var loggedincompany = JSON.parse(localStorage.getItem('currentCompany'));
   
    // console.log(loggedinuser.role)
    if(loggedinuser!=null){
    this.role = loggedinuser.role;
    if(loggedinuser){
    this.firstName = loggedinuser.first_name;
    this.lastName = loggedinuser.last_name;
    this.companyName = loggedincompany.name;
    }

    const allParams = this.route.snapshot.params // allParams is an object
    const param1 = allParams.param1 // retrieve the parameter "param1"

    // this.sidebarservice.getMenu().subscribe((data)=>{
      // console.log(loggedinuser);      
    // },    
    //   error => this.error = error
    // );
   
  
    //   (Menu: any) => {
    //     // console.log(Menu)
    //     // location.reload()
    // }, 
    // (error: any) => {
    //     // console.log(error)
    // } 
    }   

    this.getAllbasicsetting();
    this.getAllsavedreport();
    this.getmenu(loggedinuser.role);

   
    // console.log(this.routerurl)



    
    
  }

  getmenu(role:any){
    this.sidebarservice.getMenu(role).subscribe(
      (data: Menu) => this.menu = data,
        error => this.error = error
      );
  }

  currentCompany:any;
  xyzrules:any;
  google_sheet_link:any;
  getAllbasicsetting(){
    this.currentCompany = JSON.parse(localStorage.getItem('currentUser'));
    
    this.moduleservice.getAllbasicsetting(this.currentCompany.uuid).subscribe(
      res => {
        //  console.log(res); return false;  
        this.google_sheet_link = res['google_sheet_link'];
               
      },
      error => this.error = error,
    );  
  }  
  
  allsavedreports:any;
  getAllsavedreport(){
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    
    
    this.moduleservice.getAllsavedreport(this.currentCompany.uuid).subscribe(
      res => {
        this.allsavedreports = res;
        //  console.log(res); return false;  
        // this.google_sheet_link = res['google_sheet_link'];
               
      },
      error => this.error = error,
    );  
  }  
  
  
  hideothertoggle(){ 
    $('.target-item').removeClass("show"); 
    $('.report-item').removeClass("show");    
    $('.blocklist-item').removeClass("show");
    $('.numbers-item').removeClass("show"); 
    $('.settings-item').removeClass("show");
    $('.ivr-item').removeClass("show");                    
  }
  showSubmenu(id:number,e:any=""){ 
    let tag = e.target.parentElement.tagName
    // console.log(tag);
    if(tag=="LI"){
      return
    }
    
    if(id==0){      
      // if($(".target-item").hasClass("show")){
      //   $('.target-item').removeClass("show"); 
      // }      
      // console.log(this.router.url);
      
      // $('.submenu0').addClass("show");      
      $('.target-item').toggleClass("show");    
      $('.report-item').removeClass("show");    
      $('.blocklist-item').removeClass("show");
      $('.numbers-item').removeClass("show"); 
      $('.settings-item').removeClass("show");
      $('.ivr-item').removeClass("show");      
    }    
    if(id==1){      
      // $('.report-item').removeClass("show");      
      $('.report-item').toggleClass("show");    
      $('.target-item').removeClass("show"); 
      $('.blocklist-item').removeClass("show");
      $('.numbers-item').removeClass("show"); 
      $('.settings-item').removeClass("show");
      $('.ivr-item').removeClass("show");      
    }    
    if(id==2){      
      // $('.report-item').removeClass("show");      
      $('.blocklist-item').toggleClass("show");  
      $('.target-item').removeClass("show"); 
      $('.report-item').removeClass("show");    
      $('.numbers-item').removeClass("show"); 
      $('.settings-item').removeClass("show");
      $('.ivr-item').removeClass("show");        
    }    
   
    if(id==3){      
      // $('.report-item').removeClass("show");      
      $('.numbers-item').toggleClass("show");
      $('.target-item').removeClass("show"); 
      $('.report-item').removeClass("show");    
      $('.blocklist-item').removeClass("show");
      $('.settings-item').removeClass("show");
      $('.ivr-item').removeClass("show");          
    }    
    
    if(id==4){      
      // $('.report-item').removeClass("show");      
      $('.settings-item').toggleClass("show");  
      $('.target-item').removeClass("show"); 
      $('.report-item').removeClass("show");    
      $('.blocklist-item').removeClass("show");
      $('.numbers-item').removeClass("show"); 
      $('.ivr-item').removeClass("show");        
    }    
    if(id==5){      
      // $('.report-item').removeClass("show");      
      $('.ivr-item').toggleClass("show");     
      $('.target-item').removeClass("show"); 
      $('.report-item').removeClass("show");    
      $('.blocklist-item').removeClass("show");
      $('.numbers-item').removeClass("show"); 
      $('.settings-item').removeClass("show");      
    }    
  }
}
