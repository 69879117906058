import { FormBuilder, Validators, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModuleService } from './../module.service';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {RequestOptions } from '@angular/http';
//import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from '../../global';
import { Title } from '@angular/platform-browser';

let requestOptions = new RequestOptions({ headers:null, withCredentials: 
  true });

declare var $;
const router = Router;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

const ParseHeaders = {
  headers: new HttpHeaders({
   'Content-Type'  : 'application/x-www-form-urlencoded'
  })
 };

@Component({
  selector: 'app-audible-ring',
  templateUrl: './audible-ring.component.html',
  styleUrls: ['./audible-ring.component.scss']
})
export class AudibleRingComponent implements OnInit {
 private ServerUrl = GlobalVariable.BASE_API_URL;

 @ViewChild('dataTable', {static: true}) table: ElementRef;
  dataTable: any;
  currentCompany: any;
  label: Number;
  allnumbers: any;
  audiblering: any;
  

  // action: any;
  error: {};
  showLoadingIndicator: any;
  dtOptions: DataTables.Settings = {};
  labels: any;
  // that: Number;
  private http: HttpClient;
  LabelSubmitted = false;
  addLabelForm: FormGroup;
  editLabelForm: FormGroup;
  currentFileUpload: any;
  constructor(
    private moduleservice: ModuleService,
    private toastrService:ToastrService,
    handler: HttpBackend,
    private fb: FormBuilder,
    private titleService: Title
  ){ 
    this.http = new HttpClient(handler);
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit() {
    this.setTitle('Audible Ring')
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000);
    this.loadaudiblerings();

    this.addLabelForm = this.fb.group({
      number: ['', Validators.required],
      notes: ['',Validators.required],      
    });
    
    // this.addLabelForm.patchValue({
    //   number: "",
    // });

    this.getallnumbers();
    this.getaudiblering();
  }

  getaudiblering(){
    this.moduleservice.getIVRAudio(this.currentCompany.uuid,1).subscribe(
      res => {
        this.audiblering = res;
      },
      error => this.error = error
    );     
  }

  getallnumbers(){    
    this.moduleservice.getallnumbers(this.currentCompany.uuid).subscribe(
      res => {
        this.allnumbers = res;
      },
      error => this.error = error
    );       
  }

  loadaudiblerings(){
    if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
      $(this.table.nativeElement).dataTable().fnDestroy();
    } 
   
    var that = this;
    this.dtOptions = {
      "pagingType": 'full_numbers',
      "processing": true,
      "serverSide": true,          
      "pageLength": 20,
      "lengthMenu": [[-1, 20, 100, 500, 2000], ["All", 20, 100, 500, 2000]],   
      "order": [[ 2, "desc" ]],
      ajax: (dataTablesParameters: any, callback) => {
        that.http.post<DataTablesResponse>(          
          this.ServerUrl+'/getaudiblerinfnumbers/'+this.currentCompany.uuid,          
          dataTablesParameters, {}
        ).subscribe(resp => {
                  // console.log(resp);
                  that.labels = resp.data;   
                  callback({
                      recordsTotal: resp.recordsTotal,
                      recordsFiltered: resp.recordsFiltered,
                      data: [],
                  });
              });
      },
      columns: [
        {data:"name"},{data:"notes"},{data:"created_at"},{data:"action"},
      ],
  };

  this.dataTable = $(this.table.nativeElement);
  this.dataTable.dataTable(this.dtOptions);
  }

  Delete(uuid: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Delete Record!',
      
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.moduleservice.deleteAudibleRingNumber(uuid).subscribe(
            res => {
              // console.log(res);return false;
              Swal.fire(
                'Deleted!',
                'Record Deleted Successfully.',              
              )
              setTimeout(() => {
                Swal.close()
              }, 2000);

              this.loadaudiblerings();
            },
            error => this.error = error
        );       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your Record is safe :)',
          'error'
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
      }
    });
  }

get labelcontrol() { return this.addLabelForm.controls; }  
onLabelSubmit () {
    const formData = new FormData();      
    this.LabelSubmitted = true;
    if (this.addLabelForm.invalid) {
        return;
    }
    formData.append('company_uuid', this.currentCompany.uuid);
    formData.append('number', this.addLabelForm.get('number').value);
    formData.append('notes', this.addLabelForm.get('notes').value);
    this.moduleservice.addExceptionNumber(formData).subscribe(
      res => {
        // console.log(res); return false;
        if (res.status !== 'error') {
          this.toastrService.success('New Exception Number Added Successfully');         
        }else{
          this.toastrService.error('Failed To Add New Exception Number');
        }
      },
      error => this.error = error,
    );     
   }

   closeModal() {
    if(this.addLabelForm.get('number').value && this.addLabelForm.get('notes').value)
    {
      $("#addLabels").modal("hide");    
      this.loadaudiblerings();          
      setTimeout(() => {
        this.LabelSubmitted = false;
        this.addLabelForm.reset();     
      }, 2000);    
    }

  }


  count:number = 0;
  ext:string;
  selectFile(event) {
    this.currentFileUpload = event.target.files.item(0); 
    this.ext = this.currentFileUpload.name.split('.').pop(); 
   
    // console.log(event.target.files); return false;
    if(this.ext == 'mp3' || this.ext == 'wav'){
        this.moduleservice.uploadfile(event.target.files.item(0),this.currentCompany.uuid,1).subscribe(
          res => {
            // console.log(res); return false;
            if(res.body){
            if (res.body.status == 'success') {
              this.toastrService.success('Audio Uploaded Succssfully');
              this.getaudiblering();                         
            }else if(this.count==0 && res.status=='error'){
              this.toastrService.error('Failed To Add');
            }
            this.count=1;
            }
            },
          error => this.error = error,
        );
    }else{
      this.toastrService.error('Please Upload only .mp3 or .wav file');
    }
  }

}
