import { ModulesModule } from './modules/modules.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageNotFoundComponentComponent } from './page-not-found-component/page-not-found-component.component';
import { AuthModule } from './auth/auth.module';
import { AuthRoutingModule } from './auth/auth-routing.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';
import { BrowserModule } from '@angular/platform-browser';
import { AuthGuard } from './auth/auth.guard';
import { DataTablesModule } from 'angular-datatables';



const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full' },  
  {path:'404',component:PageNotFoundComponentComponent},
  {path:'**',component:PageNotFoundComponentComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: true}),
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AuthModule,
    AuthRoutingModule,
    ModulesModule, 
    DataTablesModule   
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
