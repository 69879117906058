import { FormGroup,Validators, FormBuilder } from '@angular/forms';
import { ModuleService } from './../../module.service';
import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from '../../../global';
// import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Title } from '@angular/platform-browser';

declare var $;
const router = Router;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-manage-company',
  templateUrl: './manage-company.component.html',
  styleUrls: ['./manage-company.component.scss']
})
export class ManageCompanyComponent implements OnInit {
    private ServerUrl = GlobalVariable.BASE_API_URL;
  
    @ViewChild('dataTable', {static: true}) table: ElementRef;
    dataTable: any;
    number: Number;
    action: any;
    error: {};
    showLoadingIndicator: any;
    dtOptions: DataTables.Settings = {};
    numbers: any;
    that: Number;
    private http: HttpClient;
    currentUser: any;
    ischecked: string = '1';
    public role: number;
    addForm: FormGroup;
    editForm: FormGroup;
    public companies: any;
  
    constructor(
      private moduleservice: ModuleService,
      private toastrService:ToastrService,
      handler: HttpBackend,
      private fb: FormBuilder,
      private titleService: Title
    ){ 
      this.http = new HttpClient(handler);
    }

    public setTitle(newTitle: string) {
      this.titleService.setTitle(newTitle);
    }
  
    ngOnInit() {
      this.setTitle('Manage Companies')
      this.showLoadingIndicator= true;
      setTimeout(()=>{this.showLoadingIndicator= false; },1000);
      this.loadcompanies();
      if(this.role==0 || this.role==2 || this.role==4){
        this.companies = loggedinuser.companydetails;
        // console.log(this.companies);
      }

      var loggedinuser = JSON.parse(localStorage.getItem('currentUser'));
      if(loggedinuser){
        this.role = loggedinuser.role;
      }
      this.addForm = this.fb.group({
        company_name : ['', Validators.required],
        phone: ['', Validators.required],      
        city: ['', Validators.required],      
        state: ['', Validators.required],              
        country: ['', Validators.required],              
        postal_code: ['', Validators.required],              
      });
     
      this.editForm = this.fb.group({
        uuid: [''],      
        company_name : ['', Validators.required],
        phone: ['', Validators.required],      
        city: ['', Validators.required],      
        state: ['', Validators.required],              
        country: ['', Validators.required],              
        postal_code: ['', Validators.required],     
      });
    }
    loadcompanies(){
            if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
              $(this.table.nativeElement).dataTable().fnDestroy();
            }    
            this.currentUser = JSON.parse(localStorage.getItem('currentCompany'));
           this.dtOptions = {
              "pagingType": 'full_numbers',
              "processing": true,
              "serverSide": true,          
              "pageLength": 25,            
              "order": [[ 5, "desc" ]],
              ajax: (dataTablesParameters: any, callback) => {
                this.http.post<DataTablesResponse>(
                  this.ServerUrl+'getcompanies/'+this.currentUser.uuid,
                  dataTablesParameters, {}
                ).subscribe(resp => {
                          this.numbers = resp.data;   
                          callback({
                              recordsTotal: resp.recordsTotal,
                              recordsFiltered: resp.recordsFiltered,
                              data: [],
                          });
                      });
              },
              columns: [
                {data:"name"},{data:"phone"},{data:"city"},{data:"state"},{data:"country"},{data:"postal_code"},{data:"created_at"},{data:"action"},
              ],
              columnDefs: [
                { 
                    "targets": [0], 
                    "orderable": false,
                },
                ],
          };
      
        this.dataTable = $(this.table.nativeElement);
        this.dataTable.dataTable(this.dtOptions);
    }

  editdata(uuid:string,name:string,phone:string,city:string,state:string,country:string,postal_code:string){
    this.editForm.patchValue({
      uuid : uuid,
      company_name : name,
      phone: phone,      
      city: city,      
      state: state,      
      country: country,      
      postal_code: postal_code,      
    });    
  }
  
  
    checkalldelete(event) {
      const checked = event.target.checked;
      this.numbers.forEach(chkbox => chkbox.selected = checked);    
    }
  
    checkboxes:any = [];  
    deleteselected(event:any){ 
      // $("#customCheck1").prop("checked", false);    
      if(event.target.checked==true)
      {
        if(event.target.value=='on'){
          this.checkboxes = [];
          this.numbers.forEach(lbl => {
            this.checkboxes.push(lbl.id);
          });
        }else{ 
          if(this.checkboxes.length+1==this.numbers.length){
            $( "#customCheck1" ).prop( "checked", true );
          }
          this.checkboxes.push(event.target.value);            }            
          this.ischecked = '0';
      }else{ 
        // console.log(this.checkboxes);      
        if(this.checkboxes.length!=0){
          $( "#customCheck1" ).prop( "checked", false );        
        }
        if(event.target.value!='on'){
          const index = this.checkboxes.indexOf(event.target.value);
          this.checkboxes.splice(index, 1);
        }else{
          this.checkboxes = [];
        }
        this.ischecked = '1';
      }
      // console.log(this.checkboxes); 
    }
  
    onDeleteSubmit(){    
      if(this.checkboxes.length==0){
        this.toastrService.error('Please Select Row To Delete');
      }else{
        // console.log(this.checkboxes);  
        Swal.fire({
          title: 'Are you sure?',
          text: 'You want to Delete Selected Record!',
          
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
  
            this.moduleservice.deleteSelectedNumbers(this.checkboxes).subscribe(
              res => {
                  Swal.fire(
                    'Deleted!',
                    'Records Deleted Successfully.',
                  )
                  setTimeout(() => {
                    Swal.close()
                  }, 2000);
                  this.loadcompanies();
                },
                error => this.error = error
            );      
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Your Record is safe :)',
              'error'
            )
            setTimeout(() => {
              Swal.close()
            }, 2000);
          }
        });
      }
      
    }

    private jsonObj: any = {};
    switchcompany(item,role){
      // console.log(item); return
  
      var loggedinuser = JSON.parse(localStorage.getItem('currentUser'));
      if(role == 4 || role == 0 || role == 2){
        this.moduleservice.getcompanydetailsbyuuid(item.user_uuid,this.role,loggedinuser.uuid).subscribe(
          res => {
            // console.log(res);
  
              this.jsonObj = {
                "user_id":  item.id,
                "uuid": item.user_uuid,
                "company_uuid": item.uuid,
                "first_name": res.companydetails && res.companydetails.name? res.companydetails.name: res.companies.company,
                "last_name": "",
                "pic": "",
                "token": loggedinuser.token,
                "role": role,
                "companydetails" : this.companies ? this.companies:"",
                "timezone" : res.companydetails && res.companydetails.timezone ? res.companydetails.timezone : res.companies.timezone
              }
             localStorage.setItem('currentUser', JSON.stringify(this.jsonObj));
          }
        );
      }
  
      // let cu = localStorage.getItem('currentUser');
      // console.log(cu); return
  
      //  return
      localStorage.setItem('currentCompany', JSON.stringify(item));
      setTimeout(()=>{
        this.showLoadingIndicator= false;
        window.location.reload();
      },1000);
  
      if (item) {
        this.toastrService.success('Welcome To '+item.name);
      }else{
        this.toastrService.error('Failed To Switch Company');
      }
    }
  
    Delete(id: number) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Delete Record!',
        
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.moduleservice.deleteCompany(+id).subscribe(
              res => {
                // return false;
                this.loadcompanies();
                // this.toastrService.success('Number Deleted Successfully');
                Swal.fire(
                  'Deleted!',
                  'Record Deleted Successfully.',
                )
                setTimeout(() => {
                  Swal.close()
                }, 2000);
                
                
              },
              error => this.error = error
          );       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your Record is safe :)',
            'error'
          )
          setTimeout(() => {
            Swal.close()
          }, 2000);
        }
      });
    }
  

Submitted = false;

//Add Buyer
get addcontrol() { return this.addForm.controls; }  
onSubmit () {
    const formData = new FormData();      
    this.Submitted = true;
    if (this.addForm.invalid) {
        return;
    }

    // this.currentUser = JSON.parse(localStorage.getItem('currentCompany'));
    // formData.append('company_uuid', this.currentUser.uuid);
    formData.append('company_name', this.addForm.get('company_name').value);
    formData.append('phone', this.addForm.get('phone').value);    
    formData.append('city', this.addForm.get('city').value);
    formData.append('state', this.addForm.get('state').value);
    formData.append('country', this.addForm.get('country').value);
    formData.append('postal_code', this.addForm.get('postal_code').value);
    
    this.moduleservice.addCompany(formData).subscribe(
      res => {
        // console.log(res); return false;
        if (res.status !== 'error') {
          this.toastrService.success('Comapny Added Successfully');  
          this.loadcompanies();
          this.Submitted = false;          
  
        }else{
          this.toastrService.error('Failed To Add Label');
        }
      },
      error => this.error = error,
    );     
   }

   closeModal() {    
    if(!this.addForm.invalid)
    {
      $("#addLabels").modal("hide");         
      this.Submitted = false;
      setTimeout(() => { this.addForm.reset();}, 2000);    
    }
  }



editSubmitted = false;
//Add Buyer
get editcontrol() { return this.editForm.controls; }  
onEditSubmit () {
  // alert('ok');return false;
    const formData = new FormData();      
    this.editSubmitted = true;
    if (this.editForm.invalid) {
        return;
    }
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    formData.append('company_uuid', this.currentUser.uuid);
    // formData.append('uuid', this.editForm.get('uuid').value);
    formData.append('company_name', this.editForm.get('company_name').value);
    formData.append('phone', this.editForm.get('phone').value);    
    formData.append('city', this.editForm.get('city').value);
    formData.append('state', this.editForm.get('state').value);
    formData.append('country', this.editForm.get('country').value);
    formData.append('postal_code', this.editForm.get('postal_code').value);
    // console.log(formData); 
  
    this.moduleservice.editCompany(formData,this.editForm.get('uuid').value).subscribe(
      res => {
        // console.log(res); return false;
        if (res.status !== 'error') {
          this.toastrService.success('Company Details Updated Successfully');  
          this.loadcompanies();
          this.editSubmitted = false;       
        }else{
          this.toastrService.error('Failed To Updated Company Details');
        }
      },
      error => this.error = error,
    );     
   }

   closeEditModal() {    
    if(!this.editForm.invalid){
      $("#editLabels").modal("hide");         
      this.editSubmitted = false;
      setTimeout(() => { this.editForm.reset();}, 2000);    
    }
  }

}