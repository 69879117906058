import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalVariable } from '../global';
import { ModuleService } from '../modules/module.service';
import { interval, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  private ServerUrl = GlobalVariable.BASE_API_URL;
  subscription: Subscription;
  intervalId: number;

  constructor(
    private moduleservice: ModuleService,
    public router:Router,
    private toastrService:ToastrService,
  ) { }
  public userName:string;
  public firstName: string;
  public lastName: string;
  public role: number;
  public companies: any;
  public currentcompany: any;
  showLoadingIndicator: any;
  timeIntevalSeconds:number = 0;
  selectedcompany:any = 0;
  ngOnInit() {
    var loggedinuser = JSON.parse(localStorage.getItem('currentUser'));
    let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.currentcompany = currentCompany.uuid;
    if(loggedinuser){
    this.firstName = loggedinuser.first_name;
    this.lastName = loggedinuser.last_name;
    this.role = loggedinuser.role;
    // console.log(loggedinuser.companydetails);

    if(this.role==0 || this.role==2 || this.role==4){
      let companies = loggedinuser.companydetails;
      this.selectedcompany = companies.filter(item=>item.uuid == this.currentcompany)[0]?.name
      // console.log(this.selectedcompany);
      
      // if(selectedcompany?.length>0){
      //   companies.shift(selectedcompany[0])
      // }
      this.companies = companies
      // console.log(companies);
      // console.log(this.currentcompany);
    }
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000);
    this.getcallvalue();
    const source = interval(30000);
    this.subscription = source.subscribe(val => this.getcallvalue());
    }
  }



  currentUser:any;
  currentCompany:any;
  livecall:any;
  completedcalls:any;
  getcallvalue(){
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    let date1 = {startDate: moment().format("MM/DD/YYYY 00:00"), endDate: moment().format("MM/DD/YYYY 23:59")};
    let formdata = new FormData();
    formdata.append('userdata',JSON.stringify(this.currentUser));
    formdata.append('dates',JSON.stringify(date1));

    this.moduleservice.getcallvalue(this.currentCompany.uuid,formdata).subscribe(
      res => {
        // console.log(res);return false;
        this.livecall = res.livecall;
        this.completedcalls = res.completedcall;
      }
    );
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['./login']);
  }

  private jsonObj: any = {};
  switchcompany(item,role){
    // console.log(item); return

    var loggedinuser = JSON.parse(localStorage.getItem('currentUser'));
    if(role == 4 || role == 0 || role == 2){
      this.moduleservice.getcompanydetailsbyuuid(item.user_uuid,this.role,loggedinuser.uuid).subscribe(
        res => {
          // console.log(res);

            this.jsonObj = {
              "user_id":  item.id,
              "uuid": item.user_uuid,
              "company_uuid": item.uuid,
              "first_name": res.companydetails && res.companydetails.name? res.companydetails.name: res.companies.company,
              "last_name": "",
              "pic": "",
              "token": loggedinuser.token,
              "role": role,
              "companydetails" : this.companies ? this.companies:"",
              "timezone" : res.companydetails && res.companydetails.timezone ? res.companydetails.timezone : res.companies.timezone
            }
           localStorage.setItem('currentUser', JSON.stringify(this.jsonObj));
        }
      );
    }

    // let cu = localStorage.getItem('currentUser');
    // console.log(cu); return

    //  return
    localStorage.setItem('currentCompany', JSON.stringify(item));
    setTimeout(()=>{
      this.showLoadingIndicator= false;
      window.location.reload();
    },1000);

    if (item) {
      this.toastrService.success('Welcome To '+item.name);
    }else{
      this.toastrService.error('Failed To Switch Company');
    }
  }


  // switchcompany(item,role){
  //   var loggedinuser = JSON.parse(localStorage.getItem('currentUser'));
  //   this.moduleservice.getcompanydetailsbyuuid(item.user_uuid,this.role,loggedinuser.uuid).subscribe(
  //     res => {
  //       // this.completedcalls = res.completedcall;
  //       if(role == 4){
  //         this.jsonObj = {
  //           "user_id":  item.id,
  //           "uuid": item.user_uuid,
  //           "company_uuid": item.uuid,
  //           "first_name": res.companies.company,
  //           "last_name": "",
  //           "pic": "",
  //           "token": loggedinuser.token,
  //           "role": 4,
  //           "companydetails" : this.companies,
  //           "timezone" : res.companies.timezone
  //         }
  //         // console.log(this.jsonObj);return
  //         localStorage.setItem('currentUser', JSON.stringify(this.jsonObj));

  //       }
  //       // else{
  //       //   // this.jsonObj = {
  //       //   //   "user_id":  item.id,
  //       //   //   "uuid": item.user_uuid,
  //       //   //   "company_uuid": item.uuid,
  //       //   //   "first_name": res.companydetails.name,
  //       //   //   "last_name": "",
  //       //   //   "pic": "",
  //       //   //   "token": loggedinuser.token,
  //       //   //   "role": 4,
  //       //   //   "companydetails" : this.companies,
  //       //   //   "timezone" : loggedinuser.timezone
  //       //   // }
  //       //   this.jsonObj = {
  //       //     "user_id":  loggedinuser.id,
  //       //     "uuid": loggedinuser.user_id,
  //       //     "company_uuid": loggedinuser.company_uuid,
  //       //     "first_name": loggedinuser.first_name,
  //       //     "last_name": loggedinuser.last_name,
  //       //     "pic": loggedinuser.pic,
  //       //     "token": loggedinuser.token,
  //       //     "role": loggedinuser.role,
  //       //     "companydetails" : this.companies,
  //       //     "timezone" : loggedinuser.timezone,
  //       //     "gmttime" : loggedinuser.gmttime
  //       //   }
  //       // }



  //       localStorage.setItem('currentCompany', JSON.stringify(item));

  //       setTimeout(()=>{
  //         this.showLoadingIndicator= false;
  //         window.location.reload();
  //       },1000);

  //       if (res) {
  //         this.toastrService.success('Welcome To '+res.companydetails.name);
  //       }else{
  //         this.toastrService.error('Failed To Switch Company');
  //       }

  //     }
  //   );
  // }

  clickcount:any=0;
  getSidebar(){
    if(this.clickcount==1){
      // $('body').removeClass("sidebar-mini");
      $('body').addClass("sidebar");
      this.clickcount =0;
    }else{
      $('body').removeClass("sidebar");
      // $('body').addClass("sidebar-mini");
      $('body').removeClass("sidebar-gone");
      this.clickcount =1;
    }

  }
}
