import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ModuleService } from '../../module.service';
import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router, ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from '../../../global';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { int } from 'aws-sdk/clients/datapipeline';


declare var $;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

class FSLClass {
  forlocation: [];
  forwarding: [];  
}

@Component({
  selector: 'app-number-addfslrules',
  templateUrl: './number-addfslrules.component.html',
  styleUrls: ['./number-addfslrules.component.scss'],
  
})

export class NumberAddfslrulesComponent implements OnInit {
  private ServerUrl = GlobalVariable.BASE_API_URL;
  
    @ViewChild('dataTable', {static: true}) table: ElementRef;
    @ViewChild('daysdataTable', {static: true}) daysTable: ElementRef;
    daysdataTable: any;
    dataTable: any;
    number: Number;
    action: any;
    error: {};
    showLoadingIndicator: any;
    dtOptions: DataTables.Settings = {};
    numbers: Number[];
    days: any[];
    that: Number;
    private http: HttpClient;
    currentCompany: any;
    addFSLRules: FormGroup;
    editFSLRules: FormGroup;
    FSLRulesSubmitted = false;
    FSLRulesEditSubmitted = false;
    
    addWeekDaysForm: FormGroup;
    editWeekDaysForm: FormGroup;
    daysSubmitted = false;
    daysEditSubmitted = false;

    noofforwarding: number; 
    noofforwardinglocation_1: number; 
    noofforwardinglocation_2: number; 
    noofforwardinglocation_3: number; 
    noofforwardinglocation_4: number; 
    noofforwardinglocation_5: number; 
    noofforwardinglocation_6: number; 
    noofforwardinglocation_7: number; 
    public fslrule: any; 
    rootNode: any;
    dropdownSettingss:IDropdownSettings;
    dropdownList = [];
    selectedItems = [];
    // public dateTime1: Date;

    // dropdownSettings: { singleSelection: boolean; text: string; selectAllText: string; unSelectAllText: string; enableSearchFilter: boolean; classes: string; };
    dropdownSettings = {};
  
    constructor(
      private fb: FormBuilder,
      private moduleService: ModuleService,
      private router: Router,
      private route: ActivatedRoute,
      private toastrService:ToastrService,    
      handler: HttpBackend
    ){ 
      this.http = new HttpClient(handler);
    }

   forwardingLocationdata : any;
   forwarding : any;
   forwardingtimeframe : any;
   daytype : number = 0;
   flag : int = 0;
  //  flag: number[] = [0, 1, 2];

  isName : any; 
  validatename(event:any) {   
  // console.log(event.target.value); return false; 
  if(event.target.value!=''){  
    this.moduleService.validatename(event.target.value).subscribe(
        res =>{
            // console.log(res); return false;  
          this.isName = res;
          if (this.isName.status == 'success') {
            this.isName = "success";          
          }
          else{
            this.isName = "fail";
          }
          // console.log(this.isName); return false;  
        }
      // }
      );
    }else{
      this.isName = ""; 
    }
  }

   getFSLbyuuid(uuid:string){
      // alert(uuid);
      // console.log(uuid);

      this.moduleService.getFSLbyuuid(uuid).subscribe(
        res => {
          
          this.forwarding = res.forwarding[0].name; 
          this.forwardingtimeframe = res.forwarding[0].nooftimeframe;            
          this.noofforwarding = res.forwarding[0].nooftimeframe;            
          for (let index = 0; index < res.forwarding.length; index++) {
            var start = res.forwarding[index].start_time.split(':');
            var end = res.forwarding[index].end_time.split(':');
            var startkey ='start_time_'+index;
            var endkey ='end_time_'+index;
            var daykey ='day_'+index;
            // console.log(start[0]);
            this.editFSLRules.patchValue({
              [daykey]: res.forwarding[index].timeofday,
              [startkey]: new Date(new Date().setHours(start[0], start[1])),
              [endkey]: new Date(new Date().setHours(end[0], end[1])),               
            });   
 
            for (let j = 0; j < res.forwarding[index].locations.length; j++) {
              var inc = j + 1;
              // var forlocationkey ='noofforwardinglocation_'+inc;
              var PrimaryforwardType ='PrimaryforwardType_'+j+'_day'+index;
              var number_value ='number_'+j+'_day'+index;
              var ivr_value ='ivrMenu_'+j+'_day'+index;
              var sip_value ='sipAdd_'+j+'_day'+index;
              // this.flag =res.forwarding[index].locations[j].primary_forwarding_type;
              // console.log(res.forwarding[index].locations[j].primary_forwarding_type);
              if(res.forwarding[index].locations[j].primary_forwarding_type ==0){
                this.setPrimaryforward(0,j,index);
                this.flag = 0;
              }
              if(res.forwarding[index].locations[j].primary_forwarding_type ==1){
                this.setPrimaryforward(1,j,index);
                this.flag = 1;
              }
              if(res.forwarding[index].locations[j].primary_forwarding_type ==2){
                this.flag = 2;
                this.setPrimaryforward(2,j,index);
              }
             
              if(res.forwarding[index].locations[j].primary_forwarding_number!=''){
                var number = res.forwarding[index].locations[j].primary_forwarding_number
                var formatted = '('+ number.substr(0, 3) + ') ' + number.substr(3, 3) + '-' + number.substr(6,4)  
              }else{
                var formatted="";
              }
              
              

               if(index == 0){                
                this.noofforwardinglocation_1 = res.forwarding[index].locations[j].noofforwardinglocation; 
                // if(this.noofforwardinglocation_1!=1)  {
                // this.noofforwardinglocation_1++;                             
                // }
               }  
              //  console.log(res.forwarding[index].locations[j].noofforwardinglocation);
               if(index == 1){                
                this.noofforwardinglocation_2 = res.forwarding[index].locations[j].noofforwardinglocation;        
                // if(res.forwarding[index].locations[j].noofforwardinglocation!=1)  {
                //   this.noofforwardinglocation_2++;                             
                //   }
               }  
               if(index == 2){                
                this.noofforwardinglocation_3 = res.forwarding[index].locations[j].noofforwardinglocation; 
                // if(res.forwarding[index].locations[j].noofforwardinglocation!=1)  {
                //   this.noofforwardinglocation_3++;                             
                // }                              
               }  
               if(index == 3){                
                this.noofforwardinglocation_4 = res.forwarding[index].locations[j].noofforwardinglocation;  
                // if(res.forwarding[index].locations[j].noofforwardinglocation!=1)  {
                //   this.noofforwardinglocation_4++;                             
                // }                              
               }  
               if(index == 4){                
                this.noofforwardinglocation_5 = res.forwarding[index].locations[j].noofforwardinglocation; 
                // if(res.forwarding[index].locations[j].noofforwardinglocation!=1)  {
                //   this.noofforwardinglocation_5++;                             
                // }                                
               }                
               if(index == 5){                
                this.noofforwardinglocation_6 = res.forwarding[index].locations[j].noofforwardinglocation; 
               }                
               if(index == 6){                
                this.noofforwardinglocation_7 = res.forwarding[index].locations[j].noofforwardinglocation; 
               }                
              // console.log(index);
              this.editFSLRules.patchValue({
                // [forlocationkey]: res.forwarding[index].locations[j].noofforwardinglocation,
                [PrimaryforwardType]: this.flag,
                [number_value]: formatted,
                [ivr_value]: res.forwarding[index].locations[j].primary_forwarding_ivr,
                [sip_value]: res.forwarding[index].locations[j].primary_forwarding_sip,
              });  
            }               
          }   
            
        },
        error => this.error = error,
      );  
    }

    addFSL(){
        this.FSLRulesSubmitted = false;
        this.addFSLRules.reset();    
    }  
    getWeekbyuuid(uuid:any,start_day:any,end_day:any){
        this.editWeekDaysForm.patchValue({
          'uuid':uuid,
          'start_day':start_day,
          'end_day':end_day,
        });
    }
     
  
    ngOnInit() {
      // this.showLoadingIndicator= true;
      // setTimeout(()=>{this.showLoadingIndicator= false; },1000);
      // this.loadNumbers();
      this.loadallweekdays();
      this.loadweekdays();
      this.getAllLabels();
      this.getIVRMenu();
      this.getAllLabels();
      this.getAllRuleset();
      this.getAllLabels();
  
      this.dropdownSettings = { 
          singleSelection: false, 
          text:"Select Associated Label",
          selectAllText:'Select All',
          unSelectAllText:'UnSelect All',
          enableSearchFilter: true,
          classes:"myclass custom-class"
      };   
  
      // if(localStorage.getItem('FSLRulesInsertID')){
      // this.getRules();
      // }
      this.addWeekDaysForm = this.fb.group({
        start_day: ['', Validators.required],
        end_day: ['', Validators.required],
      });
   
      this.editWeekDaysForm = this.fb.group({
        uuid: ['', ''],
        start_day: ['', Validators.required],
        end_day: ['', Validators.required],
      });

      this.addFSLRules = this.fb.group({
        name: ['', Validators.required],   
        numberoftimeframe: ['', Validators.required],   
        noofforwardinglocation_1: ['', ''],   
        noofforwardinglocation_2: ['', ''],   
        noofforwardinglocation_3: ['', ''],   
        noofforwardinglocation_4: ['', ''],   
        noofforwardinglocation_5: ['', ''],   
        noofforwardinglocation_6: ['', ''],   
        noofforwardinglocation_7: ['', ''],   
       
        // Day 1
        day_0: ['', ''],   
        start_time_0: ['', ''],   
        end_time_0: ['', ''],  
  
        PrimaryforwardType_0_day0: ['', ''],         
        number_0_day0: ['', ''],         
        ivrMenu_0_day0: ['', ''],         
        sipAdd_0_day0: ['', ''],
  
        PrimaryforwardType_1_day0: ['', ''],         
        number_1_day0: ['', ''],         
        ivrMenu_1_day0: ['', ''],         
        sipAdd_1_day0: ['', ''],
  
        PrimaryforwardType_2_day0: ['', ''],         
        number_2_day0: ['', ''],         
        ivrMenu_2_day0: ['', ''],         
        sipAdd_2_day0: ['', ''],
  
        PrimaryforwardType_3_day0: ['', ''],         
        number_3_day0: ['', ''],         
        ivrMenu_3_day0: ['', ''],         
        sipAdd_3_day0: ['', ''],
  
        PrimaryforwardType_4_day0: ['', ''],         
        number_4_day0: ['', ''],         
        ivrMenu_4_day0: ['', ''],         
        sipAdd_4_day0: ['', ''],
  
        PrimaryforwardType_5_day0: ['', ''],         
        number_5_day0: ['', ''],         
        ivrMenu_5_day0: ['', ''],         
        sipAdd_5_day0: ['', ''],
  
        PrimaryforwardType_6_day0: ['', ''],         
        number_6_day0: ['', ''],         
        ivrMenu_6_day0: ['', ''],         
        sipAdd_6_day0: ['', ''],
  
        PrimaryforwardType_7_day0: ['', ''],         
        number_7_day0: ['', ''],         
        ivrMenu_7_day0: ['', ''],         
        sipAdd_7_day0: ['', ''],
  
        PrimaryforwardType_8_day0: ['', ''],         
        number_8_day0: ['', ''],         
        ivrMenu_8_day0: ['', ''],         
        sipAdd_8_day0: ['', ''],
  
        PrimaryforwardType_9_day0: ['', ''],         
        number_9_day0: ['', ''],         
        ivrMenu_9_day0: ['', ''],         
        sipAdd_9_day0: ['', ''],
            
        // Day2
        day_1: ['', ''],   
        start_time_1: ['', ''],   
        end_time_1: ['', ''],  
       
        PrimaryforwardType_0_day1: ['', ''],         
        number_0_day1: ['', ''],         
        ivrMenu_0_day1: ['', ''],         
        sipAdd_0_day1: ['', ''],
  
        PrimaryforwardType_1_day1: ['', ''],         
        number_1_day1: ['', ''],         
        ivrMenu_1_day1: ['', ''],         
        sipAdd_1_day1: ['', ''],
  
        PrimaryforwardType_2_day1: ['', ''],         
        number_2_day1: ['', ''],         
        ivrMenu_2_day1: ['', ''],         
        sipAdd_2_day1: ['', ''],
  
        PrimaryforwardType_3_day1: ['', ''],         
        number_3_day1: ['', ''],         
        ivrMenu_3_day1: ['', ''],         
        sipAdd_3_day1: ['', ''],
  
        PrimaryforwardType_4_day1: ['', ''],         
        number_4_day1: ['', ''],         
        ivrMenu_4_day1: ['', ''],         
        sipAdd_4_day1: ['', ''],
  
        PrimaryforwardType_5_day1: ['', ''],         
        number_5_day1: ['', ''],         
        ivrMenu_5_day1: ['', ''],         
        sipAdd_5_day1: ['', ''],
  
        PrimaryforwardType_6_day1: ['', ''],         
        number_6_day1: ['', ''],         
        ivrMenu_6_day1: ['', ''],         
        sipAdd_6_day1: ['', ''],
  
        PrimaryforwardType_7_day1: ['', ''],         
        number_7_day1: ['', ''],         
        ivrMenu_7_day1: ['', ''],         
        sipAdd_7_day1: ['', ''],
  
        PrimaryforwardType_8_day1: ['', ''],         
        number_8_day1: ['', ''],         
        ivrMenu_8_day1: ['', ''],         
        sipAdd_8_day1: ['', ''],
  
        PrimaryforwardType_9_day1: ['', ''],         
        number_9_day1: ['', ''],         
        ivrMenu_9_day1: ['', ''],         
        sipAdd_9_day1: ['', ''],
  
        // Day3
        day_2: ['', ''],   
        start_time_2: ['', ''],   
        end_time_2: ['', ''],  
       
        PrimaryforwardType_0_day2: ['', ''],         
        number_0_day2: ['', ''],         
        ivrMenu_0_day2: ['', ''],         
        sipAdd_0_day2: ['', ''],
  
        PrimaryforwardType_1_day2: ['', ''],         
        number_1_day2: ['', ''],         
        ivrMenu_1_day2: ['', ''],         
        sipAdd_1_day2: ['', ''],
  
        PrimaryforwardType_2_day2: ['', ''],         
        number_2_day2: ['', ''],         
        ivrMenu_2_day2: ['', ''],         
        sipAdd_2_day2: ['', ''],
  
        PrimaryforwardType_3_day2: ['', ''],         
        number_3_day2: ['', ''],         
        ivrMenu_3_day2: ['', ''],         
        sipAdd_3_day2: ['', ''],
  
        PrimaryforwardType_4_day2: ['', ''],         
        number_4_day2: ['', ''],         
        ivrMenu_4_day2: ['', ''],         
        sipAdd_4_day2: ['', ''],
  
        PrimaryforwardType_5_day2: ['', ''],         
        number_5_day2: ['', ''],         
        ivrMenu_5_day2: ['', ''],         
        sipAdd_5_day2: ['', ''],
  
        PrimaryforwardType_6_day2: ['', ''],         
        number_6_day2: ['', ''],         
        ivrMenu_6_day2: ['', ''],         
        sipAdd_6_day2: ['', ''],
  
        PrimaryforwardType_7_day2: ['', ''],         
        number_7_day2: ['', ''],         
        ivrMenu_7_day2: ['', ''],         
        sipAdd_7_day2: ['', ''],
  
        PrimaryforwardType_8_day2: ['', ''],         
        number_8_day2: ['', ''],         
        ivrMenu_8_day2: ['', ''],         
        sipAdd_8_day2: ['', ''],
  
        PrimaryforwardType_9_day2: ['', ''],         
        number_9_day2: ['', ''],         
        ivrMenu_9_day2: ['', ''],         
        sipAdd_9_day2: ['', ''],
  
         // Day4
        day_3: ['', ''],   
        start_time_3: ['', ''],   
        end_time_3: ['', ''],  
       
        PrimaryforwardType_0_day3: ['', ''],         
        number_0_day3: ['', ''],         
        ivrMenu_0_day3: ['', ''],         
        sipAdd_0_day3: ['', ''],
  
        PrimaryforwardType_1_day3: ['', ''],         
        number_1_day3: ['', ''],         
        ivrMenu_1_day3: ['', ''],         
        sipAdd_1_day3: ['', ''],
  
        PrimaryforwardType_2_day3: ['', ''],         
        number_2_day3: ['', ''],         
        ivrMenu_2_day3: ['', ''],         
        sipAdd_2_day3: ['', ''],
  
        PrimaryforwardType_3_day3: ['', ''],         
        number_3_day3: ['', ''],         
        ivrMenu_3_day3: ['', ''],         
        sipAdd_3_day3: ['', ''],
  
        PrimaryforwardType_4_day3: ['', ''],         
        number_4_day3: ['', ''],         
        ivrMenu_4_day3: ['', ''],         
        sipAdd_4_day3: ['', ''],
  
        PrimaryforwardType_5_day3: ['', ''],         
        number_5_day3: ['', ''],         
        ivrMenu_5_day3: ['', ''],         
        sipAdd_5_day3: ['', ''],
  
        PrimaryforwardType_6_day3: ['', ''],         
        number_6_day3: ['', ''],         
        ivrMenu_6_day3: ['', ''],         
        sipAdd_6_day3: ['', ''],
  
        PrimaryforwardType_7_day3: ['', ''],         
        number_7_day3: ['', ''],         
        ivrMenu_7_day3: ['', ''],         
        sipAdd_7_day3: ['', ''],
  
        PrimaryforwardType_8_day3: ['', ''],         
        number_8_day3: ['', ''],         
        ivrMenu_8_day3: ['', ''],         
        sipAdd_8_day3: ['', ''],
  
        PrimaryforwardType_9_day3: ['', ''],         
        number_9_day3: ['', ''],         
        ivrMenu_9_day3: ['', ''],         
        sipAdd_9_day3: ['', ''],
  
         // Day5
        day_4: ['', ''],   
        start_time_4: ['', ''],   
        end_time_4: ['', ''],  
       
        PrimaryforwardType_0_day4: ['', ''],         
        number_0_day4: ['', ''],         
        ivrMenu_0_day4: ['', ''],         
        sipAdd_0_day4: ['', ''],
  
        PrimaryforwardType_1_day4: ['', ''],         
        number_1_day4: ['', ''],         
        ivrMenu_1_day4: ['', ''],         
        sipAdd_1_day4: ['', ''],
  
        PrimaryforwardType_2_day4: ['', ''],         
        number_2_day4: ['', ''],         
        ivrMenu_2_day4: ['', ''],         
        sipAdd_2_day4: ['', ''],
  
        PrimaryforwardType_3_day4: ['', ''],         
        number_3_day4: ['', ''],         
        ivrMenu_3_day4: ['', ''],         
        sipAdd_3_day4: ['', ''],
  
        PrimaryforwardType_4_day4: ['', ''],         
        number_4_day4: ['', ''],         
        ivrMenu_4_day4: ['', ''],         
        sipAdd_4_day4: ['', ''],
  
        PrimaryforwardType_5_day4: ['', ''],         
        number_5_day4: ['', ''],         
        ivrMenu_5_day4: ['', ''],         
        sipAdd_5_day4: ['', ''],
  
        PrimaryforwardType_6_day4: ['', ''],         
        number_6_day4: ['', ''],         
        ivrMenu_6_day4: ['', ''],         
        sipAdd_6_day4: ['', ''],
  
        PrimaryforwardType_7_day4: ['', ''],         
        number_7_day4: ['', ''],         
        ivrMenu_7_day4: ['', ''],         
        sipAdd_7_day4: ['', ''],
  
        PrimaryforwardType_8_day4: ['', ''],         
        number_8_day4: ['', ''],         
        ivrMenu_8_day4: ['', ''],         
        sipAdd_8_day4: ['', ''],
  
        PrimaryforwardType_9_day4: ['', ''],         
        number_9_day4: ['', ''],         
        ivrMenu_9_day4: ['', ''],         
        sipAdd_9_day4: ['', ''],

        // Day6
        day_5: ['', ''],   
        start_time_5: ['', ''],   
        end_time_5: ['', ''],  
       
        PrimaryforwardType_0_day5: ['', ''],         
        number_0_day5: ['', ''],         
        ivrMenu_0_day5: ['', ''],         
        sipAdd_0_day5: ['', ''],
  
        PrimaryforwardType_1_day5: ['', ''],         
        number_1_day5: ['', ''],         
        ivrMenu_1_day5: ['', ''],         
        sipAdd_1_day5: ['', ''],
  
        PrimaryforwardType_2_day5: ['', ''],         
        number_2_day5: ['', ''],         
        ivrMenu_2_day5: ['', ''],         
        sipAdd_2_day5: ['', ''],
  
        PrimaryforwardType_3_day5: ['', ''],         
        number_3_day5: ['', ''],         
        ivrMenu_3_day5: ['', ''],         
        sipAdd_3_day5: ['', ''],
  
        PrimaryforwardType_4_day5: ['', ''],         
        number_4_day5: ['', ''],         
        ivrMenu_4_day5: ['', ''],         
        sipAdd_4_day5: ['', ''],
  
        PrimaryforwardType_5_day5: ['', ''],         
        number_5_day5: ['', ''],         
        ivrMenu_5_day5: ['', ''],         
        sipAdd_5_day5: ['', ''],
  
        PrimaryforwardType_6_day5: ['', ''],         
        number_6_day5: ['', ''],         
        ivrMenu_6_day5: ['', ''],         
        sipAdd_6_day5: ['', ''],
  
        PrimaryforwardType_7_day5: ['', ''],         
        number_7_day5: ['', ''],         
        ivrMenu_7_day5: ['', ''],         
        sipAdd_7_day5: ['', ''],
  
        PrimaryforwardType_8_day5: ['', ''],         
        number_8_day5: ['', ''],         
        ivrMenu_8_day5: ['', ''],         
        sipAdd_8_day5: ['', ''],
  
        PrimaryforwardType_9_day5: ['', ''],         
        number_9_day5: ['', ''],         
        ivrMenu_9_day5: ['', ''],         
        sipAdd_9_day5: ['', ''],


        // Day7
        day_6: ['', ''],   
        start_time_6: ['', ''],   
        end_time_6: ['', ''],  
       
        PrimaryforwardType_0_day6: ['', ''],         
        number_0_day6: ['', ''],         
        ivrMenu_0_day6: ['', ''],         
        sipAdd_0_day6: ['', ''],
  
        PrimaryforwardType_1_day6: ['', ''],         
        number_1_day6: ['', ''],         
        ivrMenu_1_day6: ['', ''],         
        sipAdd_1_day6: ['', ''],
  
        PrimaryforwardType_2_day6: ['', ''],         
        number_2_day6: ['', ''],         
        ivrMenu_2_day6: ['', ''],         
        sipAdd_2_day6: ['', ''],
  
        PrimaryforwardType_3_day6: ['', ''],         
        number_3_day6: ['', ''],         
        ivrMenu_3_day6: ['', ''],         
        sipAdd_3_day6: ['', ''],
  
        PrimaryforwardType_4_day6: ['', ''],         
        number_4_day6: ['', ''],         
        ivrMenu_4_day6: ['', ''],         
        sipAdd_4_day6: ['', ''],
  
        PrimaryforwardType_5_day6: ['', ''],         
        number_5_day6: ['', ''],         
        ivrMenu_5_day6: ['', ''],         
        sipAdd_5_day6: ['', ''],
  
        PrimaryforwardType_6_day6: ['', ''],         
        number_6_day6: ['', ''],         
        ivrMenu_6_day6: ['', ''],         
        sipAdd_6_day6: ['', ''],
  
        PrimaryforwardType_7_day6: ['', ''],         
        number_7_day6: ['', ''],         
        ivrMenu_7_day6: ['', ''],         
        sipAdd_7_day6: ['', ''],
  
        PrimaryforwardType_8_day6: ['', ''],         
        number_8_day6: ['', ''],         
        ivrMenu_8_day6: ['', ''],         
        sipAdd_8_day6: ['', ''],
  
        PrimaryforwardType_9_day6: ['', ''],         
        number_9_day6: ['', ''],         
        ivrMenu_9_day6: ['', ''],         
        sipAdd_9_day6: ['', ''],
      }); 

      this.editFSLRules = this.fb.group({
        rulename: ['', ''],   
        numberoftimeframe: ['', Validators.required],   
        noofforwardinglocation_1: ['', ''],   
        noofforwardinglocation_2: ['', ''],   
        noofforwardinglocation_3: ['', ''],   
        noofforwardinglocation_4: ['', ''],   
        noofforwardinglocation_5: ['', ''],   
        noofforwardinglocation_6: ['', ''],   
        noofforwardinglocation_7: ['', ''],   
       
        // Day 1
        day_0: ['', ''],   
        start_time_0: ['', ''],   
        end_time_0: ['', ''],  
  
        PrimaryforwardType_0_day0: ['', ''],         
        number_0_day0: ['', ''],         
        ivrMenu_0_day0: ['', ''],         
        sipAdd_0_day0: ['', ''],
  
        PrimaryforwardType_1_day0: ['', ''],         
        number_1_day0: ['', ''],         
        ivrMenu_1_day0: ['', ''],         
        sipAdd_1_day0: ['', ''],
  
        PrimaryforwardType_2_day0: ['', ''],         
        number_2_day0: ['', ''],         
        ivrMenu_2_day0: ['', ''],         
        sipAdd_2_day0: ['', ''],
  
        PrimaryforwardType_3_day0: ['', ''],         
        number_3_day0: ['', ''],         
        ivrMenu_3_day0: ['', ''],         
        sipAdd_3_day0: ['', ''],
  
        PrimaryforwardType_4_day0: ['', ''],         
        number_4_day0: ['', ''],         
        ivrMenu_4_day0: ['', ''],         
        sipAdd_4_day0: ['', ''],
  
        PrimaryforwardType_5_day0: ['', ''],         
        number_5_day0: ['', ''],         
        ivrMenu_5_day0: ['', ''],         
        sipAdd_5_day0: ['', ''],
  
        PrimaryforwardType_6_day0: ['', ''],         
        number_6_day0: ['', ''],         
        ivrMenu_6_day0: ['', ''],         
        sipAdd_6_day0: ['', ''],
  
        PrimaryforwardType_7_day0: ['', ''],         
        number_7_day0: ['', ''],         
        ivrMenu_7_day0: ['', ''],         
        sipAdd_7_day0: ['', ''],
  
        PrimaryforwardType_8_day0: ['', ''],         
        number_8_day0: ['', ''],         
        ivrMenu_8_day0: ['', ''],         
        sipAdd_8_day0: ['', ''],
  
        PrimaryforwardType_9_day0: ['', ''],         
        number_9_day0: ['', ''],         
        ivrMenu_9_day0: ['', ''],         
        sipAdd_9_day0: ['', ''],
            
        // Day2
        day_1: ['', ''],   
        start_time_1: ['', ''],   
        end_time_1: ['', ''],  
       
        PrimaryforwardType_0_day1: ['', ''],         
        number_0_day1: ['', ''],         
        ivrMenu_0_day1: ['', ''],         
        sipAdd_0_day1: ['', ''],
  
        PrimaryforwardType_1_day1: ['', ''],         
        number_1_day1: ['', ''],         
        ivrMenu_1_day1: ['', ''],         
        sipAdd_1_day1: ['', ''],
  
        PrimaryforwardType_2_day1: ['', ''],         
        number_2_day1: ['', ''],         
        ivrMenu_2_day1: ['', ''],         
        sipAdd_2_day1: ['', ''],
  
        PrimaryforwardType_3_day1: ['', ''],         
        number_3_day1: ['', ''],         
        ivrMenu_3_day1: ['', ''],         
        sipAdd_3_day1: ['', ''],
  
        PrimaryforwardType_4_day1: ['', ''],         
        number_4_day1: ['', ''],         
        ivrMenu_4_day1: ['', ''],         
        sipAdd_4_day1: ['', ''],
  
        PrimaryforwardType_5_day1: ['', ''],         
        number_5_day1: ['', ''],         
        ivrMenu_5_day1: ['', ''],         
        sipAdd_5_day1: ['', ''],
  
        PrimaryforwardType_6_day1: ['', ''],         
        number_6_day1: ['', ''],         
        ivrMenu_6_day1: ['', ''],         
        sipAdd_6_day1: ['', ''],
  
        PrimaryforwardType_7_day1: ['', ''],         
        number_7_day1: ['', ''],         
        ivrMenu_7_day1: ['', ''],         
        sipAdd_7_day1: ['', ''],
  
        PrimaryforwardType_8_day1: ['', ''],         
        number_8_day1: ['', ''],         
        ivrMenu_8_day1: ['', ''],         
        sipAdd_8_day1: ['', ''],
  
        PrimaryforwardType_9_day1: ['', ''],         
        number_9_day1: ['', ''],         
        ivrMenu_9_day1: ['', ''],         
        sipAdd_9_day1: ['', ''],
  
        // Day3
        day_2: ['', ''],   
        start_time_2: ['', ''],   
        end_time_2: ['', ''],  
       
        PrimaryforwardType_0_day2: ['', ''],         
        number_0_day2: ['', ''],         
        ivrMenu_0_day2: ['', ''],         
        sipAdd_0_day2: ['', ''],
  
        PrimaryforwardType_1_day2: ['', ''],         
        number_1_day2: ['', ''],         
        ivrMenu_1_day2: ['', ''],         
        sipAdd_1_day2: ['', ''],
  
        PrimaryforwardType_2_day2: ['', ''],         
        number_2_day2: ['', ''],         
        ivrMenu_2_day2: ['', ''],         
        sipAdd_2_day2: ['', ''],
  
        PrimaryforwardType_3_day2: ['', ''],         
        number_3_day2: ['', ''],         
        ivrMenu_3_day2: ['', ''],         
        sipAdd_3_day2: ['', ''],
  
        PrimaryforwardType_4_day2: ['', ''],         
        number_4_day2: ['', ''],         
        ivrMenu_4_day2: ['', ''],         
        sipAdd_4_day2: ['', ''],
  
        PrimaryforwardType_5_day2: ['', ''],         
        number_5_day2: ['', ''],         
        ivrMenu_5_day2: ['', ''],         
        sipAdd_5_day2: ['', ''],
  
        PrimaryforwardType_6_day2: ['', ''],         
        number_6_day2: ['', ''],         
        ivrMenu_6_day2: ['', ''],         
        sipAdd_6_day2: ['', ''],
  
        PrimaryforwardType_7_day2: ['', ''],         
        number_7_day2: ['', ''],         
        ivrMenu_7_day2: ['', ''],         
        sipAdd_7_day2: ['', ''],
  
        PrimaryforwardType_8_day2: ['', ''],         
        number_8_day2: ['', ''],         
        ivrMenu_8_day2: ['', ''],         
        sipAdd_8_day2: ['', ''],
  
        PrimaryforwardType_9_day2: ['', ''],         
        number_9_day2: ['', ''],         
        ivrMenu_9_day2: ['', ''],         
        sipAdd_9_day2: ['', ''],
  
         // Day4
        day_3: ['', ''],   
        start_time_3: ['', ''],   
        end_time_3: ['', ''],  
       
        PrimaryforwardType_0_day3: ['', ''],         
        number_0_day3: ['', ''],         
        ivrMenu_0_day3: ['', ''],         
        sipAdd_0_day3: ['', ''],
  
        PrimaryforwardType_1_day3: ['', ''],         
        number_1_day3: ['', ''],         
        ivrMenu_1_day3: ['', ''],         
        sipAdd_1_day3: ['', ''],
  
        PrimaryforwardType_2_day3: ['', ''],         
        number_2_day3: ['', ''],         
        ivrMenu_2_day3: ['', ''],         
        sipAdd_2_day3: ['', ''],
  
        PrimaryforwardType_3_day3: ['', ''],         
        number_3_day3: ['', ''],         
        ivrMenu_3_day3: ['', ''],         
        sipAdd_3_day3: ['', ''],
  
        PrimaryforwardType_4_day3: ['', ''],         
        number_4_day3: ['', ''],         
        ivrMenu_4_day3: ['', ''],         
        sipAdd_4_day3: ['', ''],
  
        PrimaryforwardType_5_day3: ['', ''],         
        number_5_day3: ['', ''],         
        ivrMenu_5_day3: ['', ''],         
        sipAdd_5_day3: ['', ''],
  
        PrimaryforwardType_6_day3: ['', ''],         
        number_6_day3: ['', ''],         
        ivrMenu_6_day3: ['', ''],         
        sipAdd_6_day3: ['', ''],
  
        PrimaryforwardType_7_day3: ['', ''],         
        number_7_day3: ['', ''],         
        ivrMenu_7_day3: ['', ''],         
        sipAdd_7_day3: ['', ''],
  
        PrimaryforwardType_8_day3: ['', ''],         
        number_8_day3: ['', ''],         
        ivrMenu_8_day3: ['', ''],         
        sipAdd_8_day3: ['', ''],
  
        PrimaryforwardType_9_day3: ['', ''],         
        number_9_day3: ['', ''],         
        ivrMenu_9_day3: ['', ''],         
        sipAdd_9_day3: ['', ''],
  
         // Day5
        day_4: ['', ''],   
        start_time_4: ['', ''],   
        end_time_4: ['', ''],  
       
        PrimaryforwardType_0_day4: ['', ''],         
        number_0_day4: ['', ''],         
        ivrMenu_0_day4: ['', ''],         
        sipAdd_0_day4: ['', ''],
  
        PrimaryforwardType_1_day4: ['', ''],         
        number_1_day4: ['', ''],         
        ivrMenu_1_day4: ['', ''],         
        sipAdd_1_day4: ['', ''],
  
        PrimaryforwardType_2_day4: ['', ''],         
        number_2_day4: ['', ''],         
        ivrMenu_2_day4: ['', ''],         
        sipAdd_2_day4: ['', ''],
  
        PrimaryforwardType_3_day4: ['', ''],         
        number_3_day4: ['', ''],         
        ivrMenu_3_day4: ['', ''],         
        sipAdd_3_day4: ['', ''],
  
        PrimaryforwardType_4_day4: ['', ''],         
        number_4_day4: ['', ''],         
        ivrMenu_4_day4: ['', ''],         
        sipAdd_4_day4: ['', ''],
  
        PrimaryforwardType_5_day4: ['', ''],         
        number_5_day4: ['', ''],         
        ivrMenu_5_day4: ['', ''],         
        sipAdd_5_day4: ['', ''],
  
        PrimaryforwardType_6_day4: ['', ''],         
        number_6_day4: ['', ''],         
        ivrMenu_6_day4: ['', ''],         
        sipAdd_6_day4: ['', ''],
  
        PrimaryforwardType_7_day4: ['', ''],         
        number_7_day4: ['', ''],         
        ivrMenu_7_day4: ['', ''],         
        sipAdd_7_day4: ['', ''],
  
        PrimaryforwardType_8_day4: ['', ''],         
        number_8_day4: ['', ''],         
        ivrMenu_8_day4: ['', ''],         
        sipAdd_8_day4: ['', ''],
  
        PrimaryforwardType_9_day4: ['', ''],         
        number_9_day4: ['', ''],         
        ivrMenu_9_day4: ['', ''],         
        sipAdd_9_day4: ['', ''],

        // Day6
        day_5: ['', ''],   
        start_time_5: ['', ''],   
        end_time_5: ['', ''],  
       
        PrimaryforwardType_0_day5: ['', ''],         
        number_0_day5: ['', ''],         
        ivrMenu_0_day5: ['', ''],         
        sipAdd_0_day5: ['', ''],
  
        PrimaryforwardType_1_day5: ['', ''],         
        number_1_day5: ['', ''],         
        ivrMenu_1_day5: ['', ''],         
        sipAdd_1_day5: ['', ''],
  
        PrimaryforwardType_2_day5: ['', ''],         
        number_2_day5: ['', ''],         
        ivrMenu_2_day5: ['', ''],         
        sipAdd_2_day5: ['', ''],
  
        PrimaryforwardType_3_day5: ['', ''],         
        number_3_day5: ['', ''],         
        ivrMenu_3_day5: ['', ''],         
        sipAdd_3_day5: ['', ''],
  
        PrimaryforwardType_4_day5: ['', ''],         
        number_4_day5: ['', ''],         
        ivrMenu_4_day5: ['', ''],         
        sipAdd_4_day5: ['', ''],
  
        PrimaryforwardType_5_day5: ['', ''],         
        number_5_day5: ['', ''],         
        ivrMenu_5_day5: ['', ''],         
        sipAdd_5_day5: ['', ''],
  
        PrimaryforwardType_6_day5: ['', ''],         
        number_6_day5: ['', ''],         
        ivrMenu_6_day5: ['', ''],         
        sipAdd_6_day5: ['', ''],
  
        PrimaryforwardType_7_day5: ['', ''],         
        number_7_day5: ['', ''],         
        ivrMenu_7_day5: ['', ''],         
        sipAdd_7_day5: ['', ''],
  
        PrimaryforwardType_8_day5: ['', ''],         
        number_8_day5: ['', ''],         
        ivrMenu_8_day5: ['', ''],         
        sipAdd_8_day5: ['', ''],
  
        PrimaryforwardType_9_day5: ['', ''],         
        number_9_day5: ['', ''],         
        ivrMenu_9_day5: ['', ''],         
        sipAdd_9_day5: ['', ''],


        // Day7
        day_6: ['', ''],   
        start_time_6: ['', ''],   
        end_time_6: ['', ''],  
       
        PrimaryforwardType_0_day6: ['', ''],         
        number_0_day6: ['', ''],         
        ivrMenu_0_day6: ['', ''],         
        sipAdd_0_day6: ['', ''],
  
        PrimaryforwardType_1_day6: ['', ''],         
        number_1_day6: ['', ''],         
        ivrMenu_1_day6: ['', ''],         
        sipAdd_1_day6: ['', ''],
  
        PrimaryforwardType_2_day6: ['', ''],         
        number_2_day6: ['', ''],         
        ivrMenu_2_day6: ['', ''],         
        sipAdd_2_day6: ['', ''],
  
        PrimaryforwardType_3_day6: ['', ''],         
        number_3_day6: ['', ''],         
        ivrMenu_3_day6: ['', ''],         
        sipAdd_3_day6: ['', ''],
  
        PrimaryforwardType_4_day6: ['', ''],         
        number_4_day6: ['', ''],         
        ivrMenu_4_day6: ['', ''],         
        sipAdd_4_day6: ['', ''],
  
        PrimaryforwardType_5_day6: ['', ''],         
        number_5_day6: ['', ''],         
        ivrMenu_5_day6: ['', ''],         
        sipAdd_5_day6: ['', ''],
  
        PrimaryforwardType_6_day6: ['', ''],         
        number_6_day6: ['', ''],         
        ivrMenu_6_day6: ['', ''],         
        sipAdd_6_day6: ['', ''],
  
        PrimaryforwardType_7_day6: ['', ''],         
        number_7_day6: ['', ''],         
        ivrMenu_7_day6: ['', ''],         
        sipAdd_7_day6: ['', ''],
  
        PrimaryforwardType_8_day6: ['', ''],         
        number_8_day6: ['', ''],         
        ivrMenu_8_day6: ['', ''],         
        sipAdd_8_day6: ['', ''],
  
        PrimaryforwardType_9_day6: ['', ''],         
        number_9_day6: ['', ''],         
        ivrMenu_9_day6: ['', ''],         
        sipAdd_9_day6: ['', ''],
      }); 
      
    }
   
    loadweekdays(){   
      
            if ($.fn.DataTable.isDataTable(this.daysTable.nativeElement) ) {
              $(this.daysTable.nativeElement).dataTable().fnDestroy();
            }  
            this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
            this.dtOptions = {
              "pagingType": 'full_numbers',
              "processing": true,
              "serverSide": true,          
              "pageLength": 20,            
              "order": [[ 1, "desc" ]],
              ajax: (dataTablesParameters: any, callback) => {
                this.http.post<DataTablesResponse>(
                  this.ServerUrl+'getallweekdays/'+this.currentCompany.uuid,
                  dataTablesParameters, {}
                ).subscribe(resp => {
                        // console.log(resp);return false;
                          this.days = resp.data;   
                          callback({
                              recordsTotal: resp.recordsTotal,
                              recordsFiltered: resp.recordsFiltered,
                              data: [],
                          });
                      });
              },
              columns: [
                {data:"start_day"},{data:"end_day"},{data:"created_at"},{data:"action"},
              ],
          };
      
        this.daysdataTable = $(this.daysTable.nativeElement);
        this.daysdataTable.dataTable(this.dtOptions);
    }
    
    // loadNumbers(){        
    //         if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
    //           $(this.table.nativeElement).dataTable().fnDestroy();
    //         }    
    //         this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    //         var that = this;
    //         this.dtOptions = {
    //           "pagingType": 'full_numbers',
    //           "processing": true,
    //           "serverSide": true,          
    //           "pageLength": 20,            
    //           "order": [[ 1, "desc" ]],
    //           ajax: (dataTablesParameters: any, callback) => {
    //             that.http.post<DataTablesResponse>(
    //               this.ServerUrl+'getallfslrules/'+this.currentCompany.uuid,
    //               dataTablesParameters, {}
    //             ).subscribe(resp => {
    //                     // console.log(resp);return false;
    //                       that.numbers = resp.data;   
    //                       callback({
    //                           recordsTotal: resp.recordsTotal,
    //                           recordsFiltered: resp.recordsFiltered,
    //                           data: [],
    //                       });
    //                   });
    //           },
    //           columns: [
    //             {data:"name"},{data:"created_at"},{data:"action"},
    //           ],
    //       };
      
    //     this.dataTable = $(this.table.nativeElement);
    //     this.dataTable.dataTable(this.dtOptions);
    // }

    public rulesData: any;
    public allRulesData: any;
    public allRulesbyname: any;
    public FSLRulesInsertID: any;
    public ruletext: any;
    
    
    closeModal() {
      if(this.noofforwarding)
      {
        $("#addFSLRules").modal("hide");    
        setTimeout(() => {
          this.FSLRulesSubmitted= false;
          this.addFSLRules.reset();      
          this.isName = ""; 
          this.getAllRules();
        }, 2000);    
      }
    }
    
    addweekcloseModal() {
      if(this.addWeekDaysForm.get('start_day').value && this.addWeekDaysForm.get('end_day').value)
      {
        $("#addWeekDays").modal("hide");    
        setTimeout(() => {
          this.daysSubmitted= false;
          this.addWeekDaysForm.reset({
            'start_day':'',
            'end_day':''
          });      
          
        }, 2000);    
      }
    }
    
    editweekcloseModal() {
      if(this.editWeekDaysForm.get('start_day').value && this.editWeekDaysForm.get('end_day').value)
      {
        $("#editweekdays").modal("hide");    
        setTimeout(() => {
        }, 2000);    
      }
    }

    // editcloseModal() {
    //   if(this.noofforwarding)
    //   {
    //     $("#editFSLRules").modal("hide");    
    //     this.FSLRulesEditSubmitted=false;
    //     setTimeout(() => {
    //       // this.editFSLRules.reset();      
    //       this.loadNumbers();
    //     }, 2000);    
    //   }
    // }
    // getRules(){
    //   // alert('ok');
    //   this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    //   this.FSLRulesInsertID = localStorage.getItem('FSLRulesInsertID');
    //   // console.log(this.FSLRulesInsertID);return false;
    //   this.moduleService.getRecentRules(this.FSLRulesInsertID,this.currentCompany.uuid).subscribe(
    //     res => {
    //       // console.log(res.noofforwardinglocation);
    //       this.rulesData = res;            
    //     },
    //     error => this.error = error,
    //   );  
    // }
    allweekdays:any;
    loadallweekdays(){
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.moduleService.loadallweekdays(this.currentCompany.uuid).subscribe(
        res => {
          //  console.log(res); return false;  
          this.allweekdays = res.data;
        },
        error => this.error = error,
      );  
    }
  
    getAllRules(){
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.moduleService.getAllRules(this.currentCompany.uuid).subscribe(
        res => {
          //  console.log(res); return false;  
          this.allRulesData = res;
        },
        error => this.error = error,
      );  
    }
  
    getAllLabels(){
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.moduleService.getAllLabels(this.currentCompany.uuid).subscribe(
        res => {
          this.dropdownList = res
        },
        error => this.error = error,
      );  
    }
  
  
    getRulesByName(name: string){
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.moduleService.getRulesByName(name,this.currentCompany.uuid).subscribe(
        res => {
          this.allRulesbyname = res;            
        },
        error => this.error = error,
      );  
    }
  
    _keyPress(event: any) {
      const isNumericInput = (event) => {
        const key = event.keyCode;
        return ((key >= 48 && key <= 57) || // Allow number line
          (key >= 96 && key <= 105) // Allow number pad
        );
      };
      
      const isModifierKey = (event) => {
        const key = event.keyCode;
        return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
          (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
          (key > 36 && key < 41) || // Allow left, up, right, down
          (
            // Allow Ctrl/Command + A,C,V,X,Z
            (event.ctrlKey === true || event.metaKey === true) &&
            (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
          )
      };
      
      const enforceFormat = (event) => {
        // Input must be of a valid number format or a modifier key, and not longer than ten digits
        if(!isNumericInput(event) && !isModifierKey(event)){
          event.preventDefault();
        }
      };
      
      const formatToPhone = (event) => {
        if(isModifierKey(event)) {return;}
      
        const target = event.target;
        const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
        const zip = input.substring(0,3);
        const middle = input.substring(3,6);
        const last = input.substring(6,10);
      
        if(input.length > 6){target.value = `(${zip}) ${middle}-${last}`;}
        else if(input.length > 3){target.value = `(${zip}) ${middle}`;}
        else if(input.length > 0){target.value = `(${zip}`;}
      };
      const inputElement = document.getElementById('number');
      const number_0_day0 = document.getElementById('number_0_day0');
      const number_1_day0 = document.getElementById('number_1_day0');
      const number_2_day0 = document.getElementById('number_2_day0');
      const number_3_day0 = document.getElementById('number_3_day0');
      const number_4_day0 = document.getElementById('number_4_day0');
      const number_5_day0 = document.getElementById('number_5_day0');
      const number_6_day0 = document.getElementById('number_6_day0');
      const number_7_day0 = document.getElementById('number_7_day0');
      const number_8_day0 = document.getElementById('number_8_day0');
      const number_9_day0 = document.getElementById('number_9_day0');
  
      const number_0_day1 = document.getElementById('number_0_day1');
      const number_1_day1 = document.getElementById('number_1_day1');
      const number_2_day1 = document.getElementById('number_2_day1');
      const number_3_day1 = document.getElementById('number_3_day1');
      const number_4_day1 = document.getElementById('number_4_day1');
      const number_5_day1 = document.getElementById('number_5_day1');
      const number_6_day1 = document.getElementById('number_6_day1');
      const number_7_day1 = document.getElementById('number_7_day1');
      const number_8_day1 = document.getElementById('number_8_day1');
      const number_9_day1 = document.getElementById('number_9_day1');
  
      const number_0_day2 = document.getElementById('number_0_day2');
      const number_1_day2 = document.getElementById('number_1_day2');
      const number_2_day2 = document.getElementById('number_2_day2');
      const number_3_day2 = document.getElementById('number_3_day2');
      const number_4_day2 = document.getElementById('number_4_day2');
      const number_5_day2 = document.getElementById('number_5_day2');
      const number_6_day2 = document.getElementById('number_6_day2');
      const number_7_day2 = document.getElementById('number_7_day2');
      const number_8_day2 = document.getElementById('number_8_day2');
      const number_9_day2 = document.getElementById('number_9_day2');
  
      const number_0_day3 = document.getElementById('number_0_day3');
      const number_1_day3 = document.getElementById('number_1_day3');
      const number_2_day3 = document.getElementById('number_2_day3');
      const number_3_day3 = document.getElementById('number_3_day3');
      const number_4_day3 = document.getElementById('number_4_day3');
      const number_5_day3 = document.getElementById('number_5_day3');
      const number_6_day3 = document.getElementById('number_6_day3');
      const number_7_day3 = document.getElementById('number_7_day3');
      const number_8_day3 = document.getElementById('number_8_day3');
      const number_9_day3 = document.getElementById('number_9_day3');
  
      const number_0_day4 = document.getElementById('number_0_day4');
      const number_1_day4 = document.getElementById('number_1_day4');
      const number_2_day4 = document.getElementById('number_2_day4');
      const number_3_day4 = document.getElementById('number_3_day4');
      const number_4_day4 = document.getElementById('number_4_day4');
      const number_5_day4 = document.getElementById('number_5_day4');
      const number_6_day4 = document.getElementById('number_6_day4');
      const number_7_day4 = document.getElementById('number_7_day4');
      const number_8_day4 = document.getElementById('number_8_day4');
      const number_9_day4 = document.getElementById('number_9_day4');
      
      const number_0_day5 = document.getElementById('number_0_day5');
      const number_1_day5 = document.getElementById('number_1_day5');
      const number_2_day5 = document.getElementById('number_2_day5');
      const number_3_day5 = document.getElementById('number_3_day5');
      const number_4_day5 = document.getElementById('number_4_day5');
      const number_5_day5 = document.getElementById('number_5_day5');
      const number_6_day5 = document.getElementById('number_6_day5');
      const number_7_day5 = document.getElementById('number_7_day5');
      const number_8_day5 = document.getElementById('number_8_day5');
      const number_9_day5 = document.getElementById('number_9_day5');
      
      const number_0_day6 = document.getElementById('number_0_day6');
      const number_1_day6 = document.getElementById('number_1_day6');
      const number_2_day6 = document.getElementById('number_2_day6');
      const number_3_day6 = document.getElementById('number_3_day6');
      const number_4_day6 = document.getElementById('number_4_day6');
      const number_5_day6 = document.getElementById('number_5_day6');
      const number_6_day6 = document.getElementById('number_6_day6');
      const number_7_day6 = document.getElementById('number_7_day6');
      const number_8_day6 = document.getElementById('number_8_day6');
      const number_9_day6 = document.getElementById('number_9_day6');
     
        if(inputElement){
        inputElement.addEventListener('keydown',enforceFormat);
        inputElement.addEventListener('keyup',formatToPhone);    
        }  
        if(number_0_day0){
          number_0_day0.addEventListener('keydown',enforceFormat);
          number_0_day0.addEventListener('keyup',formatToPhone);
        }
        if(number_1_day0){
          number_1_day0.addEventListener('keydown',enforceFormat);
          number_1_day0.addEventListener('keyup',formatToPhone);
        }
        if(number_2_day0){
          number_2_day0.addEventListener('keydown',enforceFormat);
          number_2_day0.addEventListener('keyup',formatToPhone);
        }
        if(number_3_day0){
          number_3_day0.addEventListener('keydown',enforceFormat);
          number_3_day0.addEventListener('keyup',formatToPhone);
        }
        if(number_4_day0){
          number_4_day0.addEventListener('keydown',enforceFormat);
          number_4_day0.addEventListener('keyup',formatToPhone);
        }
        if(number_5_day0){
          number_5_day0.addEventListener('keydown',enforceFormat);
          number_5_day0.addEventListener('keyup',formatToPhone);
        }
        if(number_6_day0){
          number_6_day0.addEventListener('keydown',enforceFormat);
          number_6_day0.addEventListener('keyup',formatToPhone);
        }
        if(number_7_day0){
          number_7_day0.addEventListener('keydown',enforceFormat);
          number_7_day0.addEventListener('keyup',formatToPhone);
        }
        if(number_8_day0){
          number_8_day0.addEventListener('keydown',enforceFormat);
          number_8_day0.addEventListener('keyup',formatToPhone);
        }
        if(number_9_day0){
          number_9_day0.addEventListener('keydown',enforceFormat);
          number_9_day0.addEventListener('keyup',formatToPhone);
        }
        
  
        if(number_0_day1){
          number_0_day1.addEventListener('keydown',enforceFormat);
          number_0_day1.addEventListener('keyup',formatToPhone);
        }
        if(number_1_day1){
          number_1_day1.addEventListener('keydown',enforceFormat);
          number_1_day1.addEventListener('keyup',formatToPhone);
        }
        if(number_2_day1){
          number_2_day1.addEventListener('keydown',enforceFormat);
          number_2_day1.addEventListener('keyup',formatToPhone);
        }
        if(number_3_day1){
          number_3_day1.addEventListener('keydown',enforceFormat);
          number_3_day1.addEventListener('keyup',formatToPhone);
        }
        if(number_4_day1){
          number_4_day1.addEventListener('keydown',enforceFormat);
          number_4_day1.addEventListener('keyup',formatToPhone);
        }
        if(number_5_day1){
          number_5_day1.addEventListener('keydown',enforceFormat);
          number_5_day1.addEventListener('keyup',formatToPhone);
        }
        if(number_6_day1){
          number_6_day1.addEventListener('keydown',enforceFormat);
          number_6_day1.addEventListener('keyup',formatToPhone);
        }
        if(number_7_day1){
          number_7_day1.addEventListener('keydown',enforceFormat);
          number_7_day1.addEventListener('keyup',formatToPhone);
        }
        if(number_8_day1){
          number_8_day1.addEventListener('keydown',enforceFormat);
          number_8_day1.addEventListener('keyup',formatToPhone);
        }
        if(number_9_day1){
          number_9_day1.addEventListener('keydown',enforceFormat);
          number_9_day1.addEventListener('keyup',formatToPhone);
        }
  
        if(number_0_day2){
          number_0_day2.addEventListener('keydown',enforceFormat);
          number_0_day2.addEventListener('keyup',formatToPhone);
        }
        if(number_1_day2){
          number_1_day2.addEventListener('keydown',enforceFormat);
          number_1_day2.addEventListener('keyup',formatToPhone);
        }
        if(number_2_day2){
          number_2_day2.addEventListener('keydown',enforceFormat);
          number_2_day2.addEventListener('keyup',formatToPhone);
        }
        if(number_3_day2){
          number_3_day2.addEventListener('keydown',enforceFormat);
          number_3_day2.addEventListener('keyup',formatToPhone);
        }
        if(number_4_day2){
          number_4_day2.addEventListener('keydown',enforceFormat);
          number_4_day2.addEventListener('keyup',formatToPhone);
        }
        if(number_5_day2){
          number_5_day2.addEventListener('keydown',enforceFormat);
          number_5_day2.addEventListener('keyup',formatToPhone);
        }
        if(number_6_day2){
          number_6_day2.addEventListener('keydown',enforceFormat);
          number_6_day2.addEventListener('keyup',formatToPhone);
        }
        if(number_7_day2){
          number_7_day2.addEventListener('keydown',enforceFormat);
          number_7_day2.addEventListener('keyup',formatToPhone);
        }
        if(number_8_day2){
          number_8_day2.addEventListener('keydown',enforceFormat);
          number_8_day2.addEventListener('keyup',formatToPhone);
        }
        if(number_9_day2){
          number_9_day2.addEventListener('keydown',enforceFormat);
          number_9_day2.addEventListener('keyup',formatToPhone);
        }
  
        if(number_0_day3){
          number_0_day3.addEventListener('keydown',enforceFormat);
          number_0_day3.addEventListener('keyup',formatToPhone);
        }
        if(number_1_day3){
          number_1_day3.addEventListener('keydown',enforceFormat);
          number_1_day3.addEventListener('keyup',formatToPhone);
        }
        if(number_2_day3){
          number_2_day3.addEventListener('keydown',enforceFormat);
          number_2_day3.addEventListener('keyup',formatToPhone);
        }
        if(number_3_day3){
          number_3_day3.addEventListener('keydown',enforceFormat);
          number_3_day3.addEventListener('keyup',formatToPhone);
        }
        if(number_4_day3){
          number_4_day3.addEventListener('keydown',enforceFormat);
          number_4_day3.addEventListener('keyup',formatToPhone);
        }
        if(number_5_day3){
          number_5_day3.addEventListener('keydown',enforceFormat);
          number_5_day3.addEventListener('keyup',formatToPhone);
        }
        if(number_6_day3){
          number_6_day3.addEventListener('keydown',enforceFormat);
          number_6_day3.addEventListener('keyup',formatToPhone);
        }
        if(number_7_day3){
          number_7_day3.addEventListener('keydown',enforceFormat);
          number_7_day3.addEventListener('keyup',formatToPhone);
        }
        if(number_8_day3){
          number_8_day3.addEventListener('keydown',enforceFormat);
          number_8_day3.addEventListener('keyup',formatToPhone);
        }
        if(number_9_day3){
          number_9_day3.addEventListener('keydown',enforceFormat);
          number_9_day3.addEventListener('keyup',formatToPhone);
        }
  
        if(number_0_day4){
          number_0_day4.addEventListener('keydown',enforceFormat);
          number_0_day4.addEventListener('keyup',formatToPhone);
        }
        if(number_1_day4){
          number_1_day4.addEventListener('keydown',enforceFormat);
          number_1_day4.addEventListener('keyup',formatToPhone);
        }
        if(number_2_day4){
          number_2_day4.addEventListener('keydown',enforceFormat);
          number_2_day4.addEventListener('keyup',formatToPhone);
        }
        if(number_3_day4){
          number_3_day4.addEventListener('keydown',enforceFormat);
          number_3_day4.addEventListener('keyup',formatToPhone);
        }
        if(number_4_day4){
          number_4_day4.addEventListener('keydown',enforceFormat);
          number_4_day4.addEventListener('keyup',formatToPhone);
        }
        if(number_5_day4){
          number_5_day4.addEventListener('keydown',enforceFormat);
          number_5_day4.addEventListener('keyup',formatToPhone);
        }
        if(number_6_day4){
          number_6_day4.addEventListener('keydown',enforceFormat);
          number_6_day4.addEventListener('keyup',formatToPhone);
        }
        if(number_7_day4){
          number_7_day4.addEventListener('keydown',enforceFormat);
          number_7_day4.addEventListener('keyup',formatToPhone);
        }
        if(number_8_day4){
          number_8_day4.addEventListener('keydown',enforceFormat);
          number_8_day4.addEventListener('keyup',formatToPhone);
        }
        if(number_9_day4){
          number_9_day4.addEventListener('keydown',enforceFormat);
          number_9_day4.addEventListener('keyup',formatToPhone);
        }
        if(number_0_day4){
          number_0_day4.addEventListener('keydown',enforceFormat);
          number_0_day4.addEventListener('keyup',formatToPhone);
        }
        if(number_1_day4){
          number_1_day4.addEventListener('keydown',enforceFormat);
          number_1_day4.addEventListener('keyup',formatToPhone);
        }
        if(number_2_day4){
          number_2_day4.addEventListener('keydown',enforceFormat);
          number_2_day4.addEventListener('keyup',formatToPhone);
        }
        if(number_3_day4){
          number_3_day4.addEventListener('keydown',enforceFormat);
          number_3_day4.addEventListener('keyup',formatToPhone);
        }
        if(number_4_day4){
          number_4_day4.addEventListener('keydown',enforceFormat);
          number_4_day4.addEventListener('keyup',formatToPhone);
        }
        if(number_5_day4){
          number_5_day4.addEventListener('keydown',enforceFormat);
          number_5_day4.addEventListener('keyup',formatToPhone);
        }
        if(number_6_day4){
          number_6_day4.addEventListener('keydown',enforceFormat);
          number_6_day4.addEventListener('keyup',formatToPhone);
        }
        if(number_7_day4){
          number_7_day4.addEventListener('keydown',enforceFormat);
          number_7_day4.addEventListener('keyup',formatToPhone);
        }
        if(number_8_day4){
          number_8_day4.addEventListener('keydown',enforceFormat);
          number_8_day4.addEventListener('keyup',formatToPhone);
        }
        if(number_9_day4){
          number_9_day4.addEventListener('keydown',enforceFormat);
          number_9_day4.addEventListener('keyup',formatToPhone);
        }
       
        if(number_0_day5){
          number_0_day5.addEventListener('keydown',enforceFormat);
          number_0_day5.addEventListener('keyup',formatToPhone);
        }
        if(number_1_day5){
          number_1_day5.addEventListener('keydown',enforceFormat);
          number_1_day5.addEventListener('keyup',formatToPhone);
        }
        if(number_2_day5){
          number_2_day5.addEventListener('keydown',enforceFormat);
          number_2_day5.addEventListener('keyup',formatToPhone);
        }
        if(number_3_day5){
          number_3_day5.addEventListener('keydown',enforceFormat);
          number_3_day5.addEventListener('keyup',formatToPhone);
        }
        if(number_4_day5){
          number_4_day5.addEventListener('keydown',enforceFormat);
          number_4_day5.addEventListener('keyup',formatToPhone);
        }
        if(number_5_day5){
          number_5_day5.addEventListener('keydown',enforceFormat);
          number_5_day5.addEventListener('keyup',formatToPhone);
        }
        if(number_6_day5){
          number_6_day5.addEventListener('keydown',enforceFormat);
          number_6_day5.addEventListener('keyup',formatToPhone);
        }
        if(number_7_day5){
          number_7_day5.addEventListener('keydown',enforceFormat);
          number_7_day5.addEventListener('keyup',formatToPhone);
        }
        if(number_8_day5){
          number_8_day5.addEventListener('keydown',enforceFormat);
          number_8_day5.addEventListener('keyup',formatToPhone);
        }
        if(number_9_day5){
          number_9_day5.addEventListener('keydown',enforceFormat);
          number_9_day5.addEventListener('keyup',formatToPhone);
        }
        
        if(number_0_day6){
          number_0_day6.addEventListener('keydown',enforceFormat);
          number_0_day6.addEventListener('keyup',formatToPhone);
        }
        if(number_1_day6){
          number_1_day6.addEventListener('keydown',enforceFormat);
          number_1_day6.addEventListener('keyup',formatToPhone);
        }
        if(number_2_day6){
          number_2_day6.addEventListener('keydown',enforceFormat);
          number_2_day6.addEventListener('keyup',formatToPhone);
        }
        if(number_3_day6){
          number_3_day6.addEventListener('keydown',enforceFormat);
          number_3_day6.addEventListener('keyup',formatToPhone);
        }
        if(number_4_day6){
          number_4_day6.addEventListener('keydown',enforceFormat);
          number_4_day6.addEventListener('keyup',formatToPhone);
        }
        if(number_5_day6){
          number_5_day6.addEventListener('keydown',enforceFormat);
          number_5_day6.addEventListener('keyup',formatToPhone);
        }
        if(number_6_day6){
          number_6_day6.addEventListener('keydown',enforceFormat);
          number_6_day6.addEventListener('keyup',formatToPhone);
        }
        if(number_7_day6){
          number_7_day6.addEventListener('keydown',enforceFormat);
          number_7_day6.addEventListener('keyup',formatToPhone);
        }
        if(number_8_day6){
          number_8_day6.addEventListener('keydown',enforceFormat);
          number_8_day6.addEventListener('keyup',formatToPhone);
        }
        if(number_9_day6){
          number_9_day6.addEventListener('keydown',enforceFormat);
          number_9_day6.addEventListener('keyup',formatToPhone);
        }


        const editnumber_0_day0 = document.getElementById('editnumber_0_day0');
        const editnumber_1_day0 = document.getElementById('editnumber_1_day0');
        const editnumber_2_day0 = document.getElementById('editnumber_2_day0');
        const editnumber_3_day0 = document.getElementById('editnumber_3_day0');
        const editnumber_4_day0 = document.getElementById('editnumber_4_day0');
        const editnumber_5_day0 = document.getElementById('editnumber_5_day0');
        const editnumber_6_day0 = document.getElementById('editnumber_6_day0');
        const editnumber_7_day0 = document.getElementById('editnumber_7_day0');
        const editnumber_8_day0 = document.getElementById('editnumber_8_day0');        
        const editnumber_9_day0 = document.getElementById('editnumber_9_day0');        
        
        const editnumber_0_day1 = document.getElementById('editnumber_0_day1');
        const editnumber_1_day1 = document.getElementById('editnumber_1_day1');
        const editnumber_2_day1 = document.getElementById('editnumber_2_day1');
        const editnumber_3_day1 = document.getElementById('editnumber_3_day1');
        const editnumber_4_day1 = document.getElementById('editnumber_4_day1');
        const editnumber_5_day1 = document.getElementById('editnumber_5_day1');
        const editnumber_6_day1 = document.getElementById('editnumber_6_day1');
        const editnumber_7_day1 = document.getElementById('editnumber_7_day1');
        const editnumber_8_day1 = document.getElementById('editnumber_8_day1');        
        const editnumber_9_day1 = document.getElementById('editnumber_9_day1');        
        
        const editnumber_0_day2 = document.getElementById('editnumber_0_day2');
        const editnumber_1_day2 = document.getElementById('editnumber_1_day2');
        const editnumber_2_day2 = document.getElementById('editnumber_2_day2');
        const editnumber_3_day2 = document.getElementById('editnumber_3_day2');
        const editnumber_4_day2 = document.getElementById('editnumber_4_day2');
        const editnumber_5_day2 = document.getElementById('editnumber_5_day2');
        const editnumber_6_day2 = document.getElementById('editnumber_6_day2');
        const editnumber_7_day2 = document.getElementById('editnumber_7_day2');
        const editnumber_8_day2 = document.getElementById('editnumber_8_day2');        
        const editnumber_9_day2 = document.getElementById('editnumber_9_day2');        
        
        const editnumber_0_day3 = document.getElementById('editnumber_0_day3');
        const editnumber_1_day3 = document.getElementById('editnumber_1_day3');
        const editnumber_2_day3 = document.getElementById('editnumber_2_day3');
        const editnumber_3_day3 = document.getElementById('editnumber_3_day3');
        const editnumber_4_day3 = document.getElementById('editnumber_4_day3');
        const editnumber_5_day3 = document.getElementById('editnumber_5_day3');
        const editnumber_6_day3 = document.getElementById('editnumber_6_day3');
        const editnumber_7_day3 = document.getElementById('editnumber_7_day3');
        const editnumber_8_day3 = document.getElementById('editnumber_8_day3');        
        const editnumber_9_day3 = document.getElementById('editnumber_9_day3');        
        
        const editnumber_0_day4 = document.getElementById('editnumber_0_day4');
        const editnumber_1_day4 = document.getElementById('editnumber_1_day4');
        const editnumber_2_day4 = document.getElementById('editnumber_2_day4');
        const editnumber_3_day4 = document.getElementById('editnumber_3_day4');
        const editnumber_4_day4 = document.getElementById('editnumber_4_day4');
        const editnumber_5_day4 = document.getElementById('editnumber_5_day4');
        const editnumber_6_day4 = document.getElementById('editnumber_6_day4');
        const editnumber_7_day4 = document.getElementById('editnumber_7_day4');
        const editnumber_8_day4 = document.getElementById('editnumber_8_day4');        
        const editnumber_9_day4 = document.getElementById('editnumber_9_day4');        
        
        const editnumber_0_day5 = document.getElementById('editnumber_0_day5');
        const editnumber_1_day5 = document.getElementById('editnumber_1_day5');
        const editnumber_2_day5 = document.getElementById('editnumber_2_day5');
        const editnumber_3_day5 = document.getElementById('editnumber_3_day5');
        const editnumber_4_day5 = document.getElementById('editnumber_4_day5');
        const editnumber_5_day5 = document.getElementById('editnumber_5_day5');
        const editnumber_6_day5 = document.getElementById('editnumber_6_day5');
        const editnumber_7_day5 = document.getElementById('editnumber_7_day5');
        const editnumber_8_day5 = document.getElementById('editnumber_8_day5');        
        const editnumber_9_day5 = document.getElementById('editnumber_9_day5');        
        
        const editnumber_0_day6 = document.getElementById('editnumber_0_day6');
        const editnumber_1_day6 = document.getElementById('editnumber_1_day6');
        const editnumber_2_day6 = document.getElementById('editnumber_2_day6');
        const editnumber_3_day6 = document.getElementById('editnumber_3_day6');
        const editnumber_4_day6 = document.getElementById('editnumber_4_day6');
        const editnumber_5_day6 = document.getElementById('editnumber_5_day6');
        const editnumber_6_day6 = document.getElementById('editnumber_6_day6');
        const editnumber_7_day6 = document.getElementById('editnumber_7_day6');
        const editnumber_8_day6 = document.getElementById('editnumber_8_day6');        
        const editnumber_9_day6 = document.getElementById('editnumber_9_day6');        
      
        if(editnumber_0_day0){
          editnumber_0_day0.addEventListener('keydown',enforceFormat);
          editnumber_0_day0.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_1_day0){
          editnumber_1_day0.addEventListener('keydown',enforceFormat);
          editnumber_1_day0.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_2_day0){
          editnumber_2_day0.addEventListener('keydown',enforceFormat);
          editnumber_2_day0.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_3_day0){
          editnumber_3_day0.addEventListener('keydown',enforceFormat);
          editnumber_3_day0.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_4_day0){
          editnumber_4_day0.addEventListener('keydown',enforceFormat);
          editnumber_4_day0.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_5_day0){
          editnumber_5_day0.addEventListener('keydown',enforceFormat);
          editnumber_5_day0.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_6_day0){
          editnumber_6_day0.addEventListener('keydown',enforceFormat);
          editnumber_6_day0.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_7_day0){
          editnumber_7_day0.addEventListener('keydown',enforceFormat);
          editnumber_7_day0.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_8_day0){
          editnumber_8_day0.addEventListener('keydown',enforceFormat);
          editnumber_8_day0.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_9_day0){
          editnumber_9_day0.addEventListener('keydown',enforceFormat);
          editnumber_9_day0.addEventListener('keyup',formatToPhone);    
        }  
        
        if(editnumber_0_day1){
          editnumber_0_day1.addEventListener('keydown',enforceFormat);
          editnumber_0_day1.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_1_day1){
          editnumber_1_day1.addEventListener('keydown',enforceFormat);
          editnumber_1_day1.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_2_day1){
          editnumber_2_day1.addEventListener('keydown',enforceFormat);
          editnumber_2_day1.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_3_day1){
          editnumber_3_day1.addEventListener('keydown',enforceFormat);
          editnumber_3_day1.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_4_day1){
          editnumber_4_day1.addEventListener('keydown',enforceFormat);
          editnumber_4_day1.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_5_day1){
          editnumber_5_day1.addEventListener('keydown',enforceFormat);
          editnumber_5_day1.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_6_day1){
          editnumber_6_day1.addEventListener('keydown',enforceFormat);
          editnumber_6_day1.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_7_day1){
          editnumber_7_day1.addEventListener('keydown',enforceFormat);
          editnumber_7_day1.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_8_day1){
          editnumber_8_day1.addEventListener('keydown',enforceFormat);
          editnumber_8_day1.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_9_day1){
          editnumber_9_day1.addEventListener('keydown',enforceFormat);
          editnumber_9_day1.addEventListener('keyup',formatToPhone);    
        }  
        
        if(editnumber_0_day2){
          editnumber_0_day2.addEventListener('keydown',enforceFormat);
          editnumber_0_day2.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_1_day2){
          editnumber_1_day2.addEventListener('keydown',enforceFormat);
          editnumber_1_day2.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_2_day2){
          editnumber_2_day2.addEventListener('keydown',enforceFormat);
          editnumber_2_day2.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_3_day2){
          editnumber_3_day2.addEventListener('keydown',enforceFormat);
          editnumber_3_day2.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_4_day2){
          editnumber_4_day2.addEventListener('keydown',enforceFormat);
          editnumber_4_day2.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_5_day2){
          editnumber_5_day2.addEventListener('keydown',enforceFormat);
          editnumber_5_day2.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_6_day2){
          editnumber_6_day2.addEventListener('keydown',enforceFormat);
          editnumber_6_day2.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_7_day2){
          editnumber_7_day2.addEventListener('keydown',enforceFormat);
          editnumber_7_day2.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_8_day2){
          editnumber_8_day2.addEventListener('keydown',enforceFormat);
          editnumber_8_day2.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_9_day2){
          editnumber_9_day2.addEventListener('keydown',enforceFormat);
          editnumber_9_day2.addEventListener('keyup',formatToPhone);    
        }  
        
        if(editnumber_0_day3){
          editnumber_0_day3.addEventListener('keydown',enforceFormat);
          editnumber_0_day3.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_1_day3){
          editnumber_1_day3.addEventListener('keydown',enforceFormat);
          editnumber_1_day3.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_2_day3){
          editnumber_2_day3.addEventListener('keydown',enforceFormat);
          editnumber_2_day3.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_3_day3){
          editnumber_3_day3.addEventListener('keydown',enforceFormat);
          editnumber_3_day3.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_4_day3){
          editnumber_4_day3.addEventListener('keydown',enforceFormat);
          editnumber_4_day3.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_5_day3){
          editnumber_5_day3.addEventListener('keydown',enforceFormat);
          editnumber_5_day3.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_6_day3){
          editnumber_6_day3.addEventListener('keydown',enforceFormat);
          editnumber_6_day3.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_7_day3){
          editnumber_7_day3.addEventListener('keydown',enforceFormat);
          editnumber_7_day3.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_8_day3){
          editnumber_8_day3.addEventListener('keydown',enforceFormat);
          editnumber_8_day3.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_9_day3){
          editnumber_9_day3.addEventListener('keydown',enforceFormat);
          editnumber_9_day3.addEventListener('keyup',formatToPhone);    
        }  
        
        if(editnumber_0_day4){
          editnumber_0_day4.addEventListener('keydown',enforceFormat);
          editnumber_0_day4.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_1_day4){
          editnumber_1_day4.addEventListener('keydown',enforceFormat);
          editnumber_1_day4.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_2_day4){
          editnumber_2_day4.addEventListener('keydown',enforceFormat);
          editnumber_2_day4.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_3_day4){
          editnumber_3_day4.addEventListener('keydown',enforceFormat);
          editnumber_3_day4.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_4_day4){
          editnumber_4_day4.addEventListener('keydown',enforceFormat);
          editnumber_4_day4.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_5_day4){
          editnumber_5_day4.addEventListener('keydown',enforceFormat);
          editnumber_5_day4.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_6_day4){
          editnumber_6_day4.addEventListener('keydown',enforceFormat);
          editnumber_6_day4.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_7_day4){
          editnumber_7_day4.addEventListener('keydown',enforceFormat);
          editnumber_7_day4.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_8_day4){
          editnumber_8_day4.addEventListener('keydown',enforceFormat);
          editnumber_8_day4.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_9_day4){
          editnumber_9_day4.addEventListener('keydown',enforceFormat);
          editnumber_9_day4.addEventListener('keyup',formatToPhone);    
        }  
        
        if(editnumber_0_day5){
          editnumber_0_day5.addEventListener('keydown',enforceFormat);
          editnumber_0_day5.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_1_day5){
          editnumber_1_day5.addEventListener('keydown',enforceFormat);
          editnumber_1_day5.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_2_day5){
          editnumber_2_day5.addEventListener('keydown',enforceFormat);
          editnumber_2_day5.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_3_day5){
          editnumber_3_day5.addEventListener('keydown',enforceFormat);
          editnumber_3_day5.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_4_day5){
          editnumber_4_day5.addEventListener('keydown',enforceFormat);
          editnumber_4_day5.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_5_day5){
          editnumber_5_day5.addEventListener('keydown',enforceFormat);
          editnumber_5_day5.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_6_day5){
          editnumber_6_day5.addEventListener('keydown',enforceFormat);
          editnumber_6_day5.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_7_day5){
          editnumber_7_day5.addEventListener('keydown',enforceFormat);
          editnumber_7_day5.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_8_day5){
          editnumber_8_day5.addEventListener('keydown',enforceFormat);
          editnumber_8_day5.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_9_day5){
          editnumber_9_day5.addEventListener('keydown',enforceFormat);
          editnumber_9_day5.addEventListener('keyup',formatToPhone);    
        }  
        
        if(editnumber_0_day6){
          editnumber_0_day6.addEventListener('keydown',enforceFormat);
          editnumber_0_day6.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_1_day6){
          editnumber_1_day6.addEventListener('keydown',enforceFormat);
          editnumber_1_day6.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_2_day6){
          editnumber_2_day6.addEventListener('keydown',enforceFormat);
          editnumber_2_day6.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_3_day6){
          editnumber_3_day6.addEventListener('keydown',enforceFormat);
          editnumber_3_day6.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_4_day6){
          editnumber_4_day6.addEventListener('keydown',enforceFormat);
          editnumber_4_day6.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_5_day6){
          editnumber_5_day6.addEventListener('keydown',enforceFormat);
          editnumber_5_day6.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_6_day6){
          editnumber_6_day6.addEventListener('keydown',enforceFormat);
          editnumber_6_day6.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_7_day6){
          editnumber_7_day6.addEventListener('keydown',enforceFormat);
          editnumber_7_day6.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_8_day6){
          editnumber_8_day6.addEventListener('keydown',enforceFormat);
          editnumber_8_day6.addEventListener('keyup',formatToPhone);    
        }  
        if(editnumber_9_day6){
          editnumber_9_day6.addEventListener('keydown',enforceFormat);
          editnumber_9_day6.addEventListener('keyup',formatToPhone);    
        }  
    }
  
  allrules : any; 
  getAllRuleset(){
    this.moduleService.getRules(this.currentCompany.uuid).subscribe(
      res =>{
        // console.log(res);        
        this.allrules = res.rule_data;     
      }  
    ); 
  }
  

    ivrmenus : any;
    getIVRMenu(){
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.moduleService.getIVRMenus(this.currentCompany.uuid).subscribe(
        res => { 
          this.ivrmenus = res;
          // console.log(this.ivrmenus);
        },
      error => this.error = error,
      );      
    }
   
    get rules() { return this.addFSLRules.controls; }
    onFSLSubmit () {
      this.FSLRulesSubmitted = true;
      // stop here if form is invalid
      if (this.addFSLRules.invalid) {
          return;
      }  
      // console.log(this.addFSLRules.get('start_time_0').value._d);
      //  return false;
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      // console.log(this.addFSLRules.value); return false;     
      this.moduleService.addFSLRules(this.addFSLRules.value,this.currentCompany.uuid).subscribe(
        res => {      
          // console.log(res);
          // return false;  
          // localStorage.setItem('FSLRulesInsertID', JSON.stringify(res.insertid));
          this.addFSL();
          if (res.status !== 'error') {
            this.toastrService.success('FSL Rules Added Successfully');          
          }else{
            this.toastrService.error('Failed To Add');
          }
        },
        error => this.error = error,
      );     
      
    }
   
    get editrules() { return this.editFSLRules.controls; }
    onFSLEditSubmit () {
      this.FSLRulesEditSubmitted = true;
      // stop here if form is invalid
      if (this.editFSLRules.invalid) {
          return;
      }  
      this.editFSLRules.get('rulename').setValue(this.forwarding)
      // console.log(this.editFSLRules.value);
      // return false;
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      // console.log(this.currentCompany.uuid); return false;     
      this.moduleService.editFSLRules(this.editFSLRules.value,this.currentCompany.uuid).subscribe(
        res => {      
          // console.log(res);
          // return false;  
          // localStorage.setItem('FSLRulesInsertID', JSON.stringify(res.insertid));
          if (res.status !== 'error') {
            this.toastrService.success('FSL Rules Updated Successfully');          
          }else{
            this.toastrService.error('Failed To Add');
          }
        },
        error => this.error = error,
      );     
      
    }


    get daycontrol() { return this.addWeekDaysForm.controls; }
    onWeekdaySubmit () {
      this.daysSubmitted = true;
      // stop here if form is invalid
      if (this.addWeekDaysForm.invalid) {
          return;
      }  
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.moduleService.addWeekdays(this.addWeekDaysForm.value,this.currentCompany.uuid).subscribe(
        res => {      
          // console.log(res);
          // return false;  
          this.loadweekdays();
          this.loadallweekdays();
          if (res.status !== 'error') {
            this.toastrService.success('Week Day Added Successfully');          
          }else{
            this.toastrService.error('Failed To Add');
          }
        },
        error => this.error = error,
      );     
      
    }
    
    get dayeditcontrol() { return this.editWeekDaysForm.controls; }
    oneditWeekdaySubmit () {
      this.daysEditSubmitted = true;
      // stop here if form is invalid
      if (this.editWeekDaysForm.invalid) {
          return;
      } 
      // console.log(this.currentCompany.value);return false; 
      this.moduleService.editWeekdays(this.editWeekDaysForm.value,this.editWeekDaysForm.get('uuid').value).subscribe(
        res => {      
          // console.log(res);
          // return false;  
          this.loadweekdays();
          this.loadallweekdays();
          if (res.status !== 'error') {
            this.toastrService.success('Week Day Updated Successfully');          
          }else{
            this.toastrService.error('Failed To Add');
          }
        },
        error => this.error = error,
      );     
      
    }

    ite:any;
  selectednoofforwardinglocation(){
    let array = [];
    if(this.noofforwardinglocation_1>=10){
      this.ite = 10;
    }else{
      this.ite = this.noofforwardinglocation_1;
    } 
    for (let i = 0; i < this.ite; i++) {
      array.push(i);
    }
    
    return array;
  }
  ite2:any;
  selectednoofforwardinglocation_2(){
    let array = [];
    if(this.noofforwardinglocation_2>=10){
      this.ite2 = 10;
    }else{
      this.ite2 = this.noofforwardinglocation_2;
    }  
    for (let i = 0; i < this.ite2; i++) {
      array.push(i);
    }
    return array;
  }
  ite3:any;
  selectednoofforwardinglocation_3(){
    let array = [];
    if(this.noofforwardinglocation_3>=10){
      this.ite3 = 10;
    }else{
      this.ite3 = this.noofforwardinglocation_3;
    }  
    for (let i = 0; i < this.ite3; i++) {
      array.push(i);
    }
    return array;
  }
  ite4:any;
  selectednoofforwardinglocation_4(){
    let array = [];
    if(this.noofforwardinglocation_4>=10){
      this.ite4 = 10;
    }else{
      this.ite4 = this.noofforwardinglocation_4;
    }  
    for (let i = 0; i < this.ite4; i++) {
      array.push(i);
    }
    return array;
  }
  ite5:any;
  selectednoofforwardinglocation_5(){
    let array = [];
    if(this.noofforwardinglocation_5>=10){
      this.ite5 = 10;
    }else{
      this.ite5 = this.noofforwardinglocation_5;
    }  
    for (let i = 0; i < this.ite5; i++) {
      array.push(i);
    }
    return array;
  }
  ite6:any;
  selectednoofforwardinglocation_6(){
    let array = [];
    if(this.noofforwardinglocation_6>=10){
      this.ite6 = 10;
    }else{
      this.ite6 = this.noofforwardinglocation_6;
    }  
    for (let i = 0; i < this.ite6; i++) {
      array.push(i);
    }
    return array;
  }
  ite7:any;
  selectednoofforwardinglocation_7(){
    let array = [];
    if(this.noofforwardinglocation_7>=10){
      this.ite7 = 10;
    }else{
      this.ite7 = this.noofforwardinglocation_7;
    }  
    for (let i = 0; i < this.ite7; i++) {
      array.push(i);
    }
    return array;
  }

  // Day0
  private selectedPrimaryForaward_0_day0: number=0;      
  private selectedPrimaryCount_0_day0: number=0; 
  private selected_0_day_0: number=0; 

  private selectedPrimaryForaward_1_day0: number=0;      
  private selectedPrimaryCount_1_day0: number=0; 
  private selected_1_day_0: number=0;  

  private selectedPrimaryForaward_2_day0: number=0;      
  private selectedPrimaryCount_2_day0: number=0; 
  private selected_2_day_0: number=0; 

  private selectedPrimaryForaward_3_day0: number=0;      
  private selectedPrimaryCount_3_day0: number=0; 
  private selected_3_day_0: number=0; 

  private selectedPrimaryForaward_4_day0: number=0;      
  private selectedPrimaryCount_4_day0: number=0; 
  private selected_4_day_0: number=0; 

  private selectedPrimaryForaward_5_day0: number=0;      
  private selectedPrimaryCount_5_day0: number=0; 
  private selected_5_day_0: number=0; 

  private selectedPrimaryForaward_6_day0: number=0;      
  private selectedPrimaryCount_6_day0: number=0; 
  private selected_6_day_0: number=0; 

  private selectedPrimaryForaward_7_day0: number=0;      
  private selectedPrimaryCount_7_day0: number=0; 
  private selected_7_day_0: number=0; 

  private selectedPrimaryForaward_8_day0: number=0;      
  private selectedPrimaryCount_8_day0: number=0; 
  private selected_8_day_0: number=0; 

  private selectedPrimaryForaward_9_day0: number=0;      
  private selectedPrimaryCount_9_day0: number=0; 
  private selected_9_day_0: number=0; 

 // Day1
 private selectedPrimaryForaward_0_day1: number=0;      
 private selectedPrimaryCount_0_day1: number=0; 
 private selected_0_day_1: number=0; 

 private selectedPrimaryForaward_1_day1: number=0;      
 private selectedPrimaryCount_1_day1: number=0; 
 private selected_1_day_1: number=0;  

 private selectedPrimaryForaward_2_day1: number=0;      
 private selectedPrimaryCount_2_day1: number=0; 
 private selected_2_day_1: number=0; 

 private selectedPrimaryForaward_3_day1: number=0;      
 private selectedPrimaryCount_3_day1: number=0; 
 private selected_3_day_1: number=0; 

 private selectedPrimaryForaward_4_day1: number=0;      
 private selectedPrimaryCount_4_day1: number=0; 
 private selected_4_day_1: number=0; 

 private selectedPrimaryForaward_5_day1: number=0;      
 private selectedPrimaryCount_5_day1: number=0; 
 private selected_5_day_1: number=0; 

 private selectedPrimaryForaward_6_day1: number=0;      
 private selectedPrimaryCount_6_day1: number=0; 
 private selected_6_day_1: number=0; 

 private selectedPrimaryForaward_7_day1: number=0;      
 private selectedPrimaryCount_7_day1: number=0; 
 private selected_7_day_1: number=0; 

 private selectedPrimaryForaward_8_day1: number=0;      
 private selectedPrimaryCount_8_day1: number=0; 
 private selected_8_day_1: number=0; 

 private selectedPrimaryForaward_9_day1: number=0;      
 private selectedPrimaryCount_9_day1: number=0; 
 private selected_9_day_1: number=0; 

// Day2
private selectedPrimaryForaward_0_day2: number=0;      
private selectedPrimaryCount_0_day2: number=0; 
private selected_0_day_2: number=0; 

private selectedPrimaryForaward_1_day2: number=0;      
private selectedPrimaryCount_1_day2: number=0; 
private selected_1_day_2: number=0;  

private selectedPrimaryForaward_2_day2: number=0;      
private selectedPrimaryCount_2_day2: number=0; 
private selected_2_day_2: number=0; 

private selectedPrimaryForaward_3_day2: number=0;      
private selectedPrimaryCount_3_day2: number=0; 
private selected_3_day_2: number=0; 

private selectedPrimaryForaward_4_day2: number=0;      
private selectedPrimaryCount_4_day2: number=0; 
private selected_4_day_2: number=0; 

private selectedPrimaryForaward_5_day2: number=0;      
private selectedPrimaryCount_5_day2: number=0; 
private selected_5_day_2: number=0; 

private selectedPrimaryForaward_6_day2: number=0;      
private selectedPrimaryCount_6_day2: number=0; 
private selected_6_day_2: number=0; 

private selectedPrimaryForaward_7_day2: number=0;      
private selectedPrimaryCount_7_day2: number=0; 
private selected_7_day_2: number=0; 

private selectedPrimaryForaward_8_day2: number=0;      
private selectedPrimaryCount_8_day2: number=0; 
private selected_8_day_2: number=0; 

private selectedPrimaryForaward_9_day2: number=0;      
private selectedPrimaryCount_9_day2: number=0; 
private selected_9_day_2: number=0; 

// Day3
private selectedPrimaryForaward_0_day3: number=0;      
private selectedPrimaryCount_0_day3: number=0; 
private selected_0_day_3: number=0; 

private selectedPrimaryForaward_1_day3: number=0;      
private selectedPrimaryCount_1_day3: number=0; 
private selected_1_day_3: number=0;  

private selectedPrimaryForaward_2_day3: number=0;      
private selectedPrimaryCount_2_day3: number=0; 
private selected_2_day_3: number=0; 

private selectedPrimaryForaward_3_day3: number=0;      
private selectedPrimaryCount_3_day3: number=0; 
private selected_3_day_3: number=0; 

private selectedPrimaryForaward_4_day3: number=0;      
private selectedPrimaryCount_4_day3: number=0; 
private selected_4_day_3: number=0; 

private selectedPrimaryForaward_5_day3: number=0;      
private selectedPrimaryCount_5_day3: number=0; 
private selected_5_day_3: number=0; 

private selectedPrimaryForaward_6_day3: number=0;      
private selectedPrimaryCount_6_day3: number=0; 
private selected_6_day_3: number=0; 

private selectedPrimaryForaward_7_day3: number=0;      
private selectedPrimaryCount_7_day3: number=0; 
private selected_7_day_3: number=0; 

private selectedPrimaryForaward_8_day3: number=0;      
private selectedPrimaryCount_8_day3: number=0; 
private selected_8_day_3: number=0; 

private selectedPrimaryForaward_9_day3: number=0;      
private selectedPrimaryCount_9_day3: number=0; 
private selected_9_day_3: number=0; 

// Day4
private selectedPrimaryForaward_0_day4: number=0;      
private selectedPrimaryCount_0_day4: number=0; 
private selected_0_day_4: number=0; 

private selectedPrimaryForaward_1_day4: number=0;      
private selectedPrimaryCount_1_day4: number=0; 
private selected_1_day_4: number=0;  

private selectedPrimaryForaward_2_day4: number=0;      
private selectedPrimaryCount_2_day4: number=0; 
private selected_2_day_4: number=0; 

private selectedPrimaryForaward_3_day4: number=0;      
private selectedPrimaryCount_3_day4: number=0; 
private selected_3_day_4: number=0; 

private selectedPrimaryForaward_4_day4: number=0;      
private selectedPrimaryCount_4_day4: number=0; 
private selected_4_day_4: number=0; 

private selectedPrimaryForaward_5_day4: number=0;      
private selectedPrimaryCount_5_day4: number=0; 
private selected_5_day_4: number=0; 

private selectedPrimaryForaward_6_day4: number=0;      
private selectedPrimaryCount_6_day4: number=0; 
private selected_6_day_4: number=0; 

private selectedPrimaryForaward_7_day4: number=0;      
private selectedPrimaryCount_7_day4: number=0; 
private selected_7_day_4: number=0; 

private selectedPrimaryForaward_8_day4: number=0;      
private selectedPrimaryCount_8_day4: number=0; 
private selected_8_day_4: number=0; 

private selectedPrimaryForaward_9_day4: number=0;      
private selectedPrimaryCount_9_day4: number=0; 
private selected_9_day_4: number=0; 

// Day5
private selectedPrimaryForaward_0_day5: number=0;      
private selectedPrimaryCount_0_day5: number=0; 
private selected_0_day_5: number=0; 

private selectedPrimaryForaward_1_day5: number=0;      
private selectedPrimaryCount_1_day5: number=0; 
private selected_1_day_5: number=0;  

private selectedPrimaryForaward_2_day5: number=0;      
private selectedPrimaryCount_2_day5: number=0; 
private selected_2_day_5: number=0; 

private selectedPrimaryForaward_3_day5: number=0;      
private selectedPrimaryCount_3_day5: number=0; 
private selected_3_day_5: number=0; 

private selectedPrimaryForaward_4_day5: number=0;      
private selectedPrimaryCount_4_day5: number=0; 
private selected_4_day_5: number=0; 

private selectedPrimaryForaward_5_day5: number=0;      
private selectedPrimaryCount_5_day5: number=0; 
private selected_5_day_5: number=0; 

private selectedPrimaryForaward_6_day5: number=0;      
private selectedPrimaryCount_6_day5: number=0; 
private selected_6_day_5: number=0; 

private selectedPrimaryForaward_7_day5: number=0;      
private selectedPrimaryCount_7_day5: number=0; 
private selected_7_day_5: number=0; 

private selectedPrimaryForaward_8_day5: number=0;      
private selectedPrimaryCount_8_day5: number=0; 
private selected_8_day_5: number=0; 

private selectedPrimaryForaward_9_day5: number=0;      
private selectedPrimaryCount_9_day5: number=0; 
private selected_9_day_5: number=0; 

// Day6
private selectedPrimaryForaward_0_day6: number=0;      
private selectedPrimaryCount_0_day6: number=0; 
private selected_0_day_6: number=0; 

private selectedPrimaryForaward_1_day6: number=0;      
private selectedPrimaryCount_1_day6: number=0; 
private selected_1_day_6: number=0;  

private selectedPrimaryForaward_2_day6: number=0;      
private selectedPrimaryCount_2_day6: number=0; 
private selected_2_day_6: number=0; 

private selectedPrimaryForaward_3_day6: number=0;      
private selectedPrimaryCount_3_day6: number=0; 
private selected_3_day_6: number=0; 

private selectedPrimaryForaward_4_day6: number=0;      
private selectedPrimaryCount_4_day6: number=0; 
private selected_4_day_6: number=0; 

private selectedPrimaryForaward_5_day6: number=0;      
private selectedPrimaryCount_5_day6: number=0; 
private selected_5_day_6: number=0; 

private selectedPrimaryForaward_6_day6: number=0;      
private selectedPrimaryCount_6_day6: number=0; 
private selected_6_day_6: number=0; 

private selectedPrimaryForaward_7_day6: number=0;      
private selectedPrimaryCount_7_day6: number=0; 
private selected_7_day_6: number=0; 

private selectedPrimaryForaward_8_day6: number=0;      
private selectedPrimaryCount_8_day6: number=0; 
private selected_8_day_6: number=0; 

private selectedPrimaryForaward_9_day6: number=0;      
private selectedPrimaryCount_9_day6: number=0; 
private selected_9_day_6: number=0; 

setPrimaryforward(id:number,count:number,day:number){
  // Day0
  if(count==0 && day==0){
    this.selectedPrimaryForaward_0_day0 = id;    
    this.selectedPrimaryCount_0_day0 = count;
    this.selected_0_day_0 = day;
  }
  if(count==1 && day==0){
    this.selectedPrimaryForaward_1_day0 = id;    
    this.selectedPrimaryCount_1_day0 = count;
    this.selected_1_day_0 = day;
  }
  if(count==2 && day==0){
    this.selectedPrimaryForaward_2_day0 = id;    
    this.selectedPrimaryCount_2_day0 = count;
    this.selected_2_day_0 = day;
  }
  if(count==3 && day==0){
    this.selectedPrimaryForaward_3_day0 = id;    
    this.selectedPrimaryCount_3_day0 = count;
    this.selected_3_day_0 = day;
  }
  if(count==4 && day==0){
    this.selectedPrimaryForaward_4_day0 = id;    
    this.selectedPrimaryCount_4_day0 = count;
    this.selected_4_day_0 = day;
  }
  if(count==5 && day==0){
    this.selectedPrimaryForaward_5_day0 = id;    
    this.selectedPrimaryCount_5_day0 = count;
    this.selected_5_day_0 = day;
  }
  if(count==6 && day==0){
    this.selectedPrimaryForaward_6_day0 = id;    
    this.selectedPrimaryCount_6_day0 = count;
    this.selected_6_day_0 = day;
  }
  if(count==7 && day==0){
    this.selectedPrimaryForaward_7_day0 = id;    
    this.selectedPrimaryCount_7_day0 = count;
    this.selected_7_day_0 = day;
  }
  if(count==8 && day==0){
    this.selectedPrimaryForaward_8_day0 = id;    
    this.selectedPrimaryCount_8_day0 = count;
    this.selected_8_day_0 = day;
  }
  if(count==9 && day==0){
    this.selectedPrimaryForaward_9_day0 = id;    
    this.selectedPrimaryCount_9_day0 = count;
    this.selected_9_day_0 = day;
  }


 // Day1
 if(count==0 && day==1){
  this.selectedPrimaryForaward_0_day1 = id;    
  this.selectedPrimaryCount_0_day1 = count;
  this.selected_0_day_1 = day;
}
if(count==1 && day==1){
  this.selectedPrimaryForaward_1_day1 = id;    
  this.selectedPrimaryCount_1_day1 = count;
  this.selected_1_day_1 = day;
}
if(count==2 && day==1){
  this.selectedPrimaryForaward_2_day1 = id;    
  this.selectedPrimaryCount_2_day1 = count;
  this.selected_2_day_1 = day;
}
if(count==3 && day==1){
  this.selectedPrimaryForaward_3_day1 = id;    
  this.selectedPrimaryCount_3_day1 = count;
  this.selected_3_day_1 = day;
}
if(count==4 && day==1){
  this.selectedPrimaryForaward_4_day1 = id;    
  this.selectedPrimaryCount_4_day1 = count;
  this.selected_4_day_1 = day;
}
if(count==5 && day==1){
  this.selectedPrimaryForaward_5_day1 = id;    
  this.selectedPrimaryCount_5_day1 = count;
  this.selected_5_day_1 = day;
}
if(count==6 && day==1){
  this.selectedPrimaryForaward_6_day1 = id;    
  this.selectedPrimaryCount_6_day1 = count;
  this.selected_6_day_1 = day;
}
if(count==7 && day==1){
  this.selectedPrimaryForaward_7_day1 = id;    
  this.selectedPrimaryCount_7_day1 = count;
  this.selected_7_day_1 = day;
}
if(count==8 && day==1){
  this.selectedPrimaryForaward_8_day1 = id;    
  this.selectedPrimaryCount_8_day1 = count;
  this.selected_8_day_1 = day;
}
if(count==9 && day==1){
  this.selectedPrimaryForaward_9_day1 = id;    
  this.selectedPrimaryCount_9_day1 = count;
  this.selected_9_day_1 = day;
}

// Day2
if(count==0 && day==2){
  this.selectedPrimaryForaward_0_day2 = id;    
  this.selectedPrimaryCount_0_day2 = count;
  this.selected_0_day_2 = day;
}
if(count==1 && day==2){
  this.selectedPrimaryForaward_1_day2 = id;    
  this.selectedPrimaryCount_1_day2 = count;
  this.selected_1_day_2 = day;
}
if(count==2 && day==2){
  this.selectedPrimaryForaward_2_day2 = id;    
  this.selectedPrimaryCount_2_day2 = count;
  this.selected_2_day_2 = day;
}
if(count==3 && day==2){
  this.selectedPrimaryForaward_3_day2 = id;    
  this.selectedPrimaryCount_3_day2 = count;
  this.selected_3_day_2 = day;
}
if(count==4 && day==2){
  this.selectedPrimaryForaward_4_day2 = id;    
  this.selectedPrimaryCount_4_day2 = count;
  this.selected_4_day_2 = day;
}
if(count==5 && day==2){
  this.selectedPrimaryForaward_5_day2 = id;    
  this.selectedPrimaryCount_5_day2 = count;
  this.selected_5_day_2 = day;
}
if(count==6 && day==2){
  this.selectedPrimaryForaward_6_day2 = id;    
  this.selectedPrimaryCount_6_day2 = count;
  this.selected_6_day_2 = day;
}
if(count==7 && day==2){
  this.selectedPrimaryForaward_7_day2 = id;    
  this.selectedPrimaryCount_7_day2 = count;
  this.selected_7_day_2 = day;
}
if(count==8 && day==2){
  this.selectedPrimaryForaward_8_day2 = id;    
  this.selectedPrimaryCount_8_day2 = count;
  this.selected_8_day_2 = day;
}
if(count==9 && day==2){
  this.selectedPrimaryForaward_9_day2 = id;    
  this.selectedPrimaryCount_9_day2 = count;
  this.selected_9_day_2 = day;
}

 // Day3
 if(count==0 && day==3){
  this.selectedPrimaryForaward_0_day3 = id;    
  this.selectedPrimaryCount_0_day3 = count;
  this.selected_0_day_3 = day;
}
if(count==1 && day==3){
  this.selectedPrimaryForaward_1_day3 = id;    
  this.selectedPrimaryCount_1_day3 = count;
  this.selected_1_day_3 = day;
}
if(count==2 && day==3){
  this.selectedPrimaryForaward_2_day3 = id;    
  this.selectedPrimaryCount_2_day3 = count;
  this.selected_2_day_3 = day;
}
if(count==3 && day==3){
  this.selectedPrimaryForaward_3_day3 = id;    
  this.selectedPrimaryCount_3_day3 = count;
  this.selected_3_day_3 = day;
}
if(count==4 && day==3){
  this.selectedPrimaryForaward_4_day3 = id;    
  this.selectedPrimaryCount_4_day3 = count;
  this.selected_4_day_3 = day;
}
if(count==5 && day==3){
  this.selectedPrimaryForaward_5_day3 = id;    
  this.selectedPrimaryCount_5_day3 = count;
  this.selected_5_day_3 = day;
}
if(count==6 && day==3){
  this.selectedPrimaryForaward_6_day3 = id;    
  this.selectedPrimaryCount_6_day3 = count;
  this.selected_6_day_3 = day;
}
if(count==7 && day==3){
  this.selectedPrimaryForaward_7_day3 = id;    
  this.selectedPrimaryCount_7_day3 = count;
  this.selected_7_day_3 = day;
}
if(count==8 && day==3){
  this.selectedPrimaryForaward_8_day3 = id;    
  this.selectedPrimaryCount_8_day3 = count;
  this.selected_8_day_3 = day;
}
if(count==9 && day==3){
  this.selectedPrimaryForaward_9_day3 = id;    
  this.selectedPrimaryCount_9_day3 = count;
  this.selected_9_day_3 = day;
}

// Day4
if(count==0 && day==4){
  this.selectedPrimaryForaward_0_day4 = id;    
  this.selectedPrimaryCount_0_day4 = count;
  this.selected_0_day_4 = day;
}
if(count==1 && day==4){
  this.selectedPrimaryForaward_1_day4 = id;    
  this.selectedPrimaryCount_1_day4 = count;
  this.selected_1_day_4 = day;
}
if(count==2 && day==4){
  this.selectedPrimaryForaward_2_day4 = id;    
  this.selectedPrimaryCount_2_day4 = count;
  this.selected_2_day_4 = day;
}
if(count==3 && day==4){
  this.selectedPrimaryForaward_3_day4 = id;    
  this.selectedPrimaryCount_3_day4 = count;
  this.selected_3_day_4 = day;
}
if(count==4 && day==4){
  this.selectedPrimaryForaward_4_day4 = id;    
  this.selectedPrimaryCount_4_day4 = count;
  this.selected_4_day_4 = day;
}
if(count==5 && day==4){
  this.selectedPrimaryForaward_5_day4 = id;    
  this.selectedPrimaryCount_5_day4 = count;
  this.selected_5_day_4 = day;
}
if(count==6 && day==4){
  this.selectedPrimaryForaward_6_day4 = id;    
  this.selectedPrimaryCount_6_day4 = count;
  this.selected_6_day_4 = day;
}
if(count==7 && day==4){
  this.selectedPrimaryForaward_7_day4 = id;    
  this.selectedPrimaryCount_7_day4 = count;
  this.selected_7_day_4 = day;
}
if(count==8 && day==4){
  this.selectedPrimaryForaward_8_day4 = id;    
  this.selectedPrimaryCount_8_day4 = count;
  this.selected_8_day_4 = day;
}
if(count==9 && day==4){
  this.selectedPrimaryForaward_9_day4 = id;    
  this.selectedPrimaryCount_9_day4 = count;
  this.selected_9_day_4 = day;
}

// Day5
if(count==0 && day==5){
  this.selectedPrimaryForaward_0_day5 = id;    
  this.selectedPrimaryCount_0_day5 = count;
  this.selected_0_day_5 = day;
}
if(count==1 && day==5){
  this.selectedPrimaryForaward_1_day5 = id;    
  this.selectedPrimaryCount_1_day5 = count;
  this.selected_1_day_5 = day;
}
if(count==2 && day==5){
  this.selectedPrimaryForaward_2_day5 = id;    
  this.selectedPrimaryCount_2_day5 = count;
  this.selected_2_day_5 = day;
}
if(count==3 && day==5){
  this.selectedPrimaryForaward_3_day5 = id;    
  this.selectedPrimaryCount_3_day5 = count;
  this.selected_3_day_5 = day;
}
if(count==4 && day==5){
  this.selectedPrimaryForaward_4_day5 = id;    
  this.selectedPrimaryCount_4_day5 = count;
  this.selected_4_day_5 = day;
}
if(count==5 && day==5){
  this.selectedPrimaryForaward_5_day5 = id;    
  this.selectedPrimaryCount_5_day5 = count;
  this.selected_5_day_5 = day;
}
if(count==6 && day==5){
  this.selectedPrimaryForaward_6_day5 = id;    
  this.selectedPrimaryCount_6_day5 = count;
  this.selected_6_day_5 = day;
}
if(count==7 && day==5){
  this.selectedPrimaryForaward_7_day5 = id;    
  this.selectedPrimaryCount_7_day5 = count;
  this.selected_7_day_5 = day;
}
if(count==8 && day==5){
  this.selectedPrimaryForaward_8_day5 = id;    
  this.selectedPrimaryCount_8_day5 = count;
  this.selected_8_day_5 = day;
}
if(count==9 && day==5){
  this.selectedPrimaryForaward_9_day5 = id;    
  this.selectedPrimaryCount_9_day5 = count;
  this.selected_9_day_5 = day;
}

// Day6
if(count==0 && day==6){
  this.selectedPrimaryForaward_0_day6 = id;    
  this.selectedPrimaryCount_0_day6 = count;
  this.selected_0_day_6 = day;
}
if(count==1 && day==6){
  this.selectedPrimaryForaward_1_day6 = id;    
  this.selectedPrimaryCount_1_day6 = count;
  this.selected_1_day_6 = day;
}
if(count==2 && day==6){
  this.selectedPrimaryForaward_2_day6 = id;    
  this.selectedPrimaryCount_2_day6 = count;
  this.selected_2_day_6 = day;
}
if(count==3 && day==6){
  this.selectedPrimaryForaward_3_day6 = id;    
  this.selectedPrimaryCount_3_day6 = count;
  this.selected_3_day_6 = day;
}
if(count==4 && day==6){
  this.selectedPrimaryForaward_4_day6 = id;    
  this.selectedPrimaryCount_4_day6 = count;
  this.selected_4_day_6 = day;
}
if(count==5 && day==6){
  this.selectedPrimaryForaward_5_day6 = id;    
  this.selectedPrimaryCount_5_day6 = count;
  this.selected_5_day_6 = day;
}
if(count==6 && day==6){
  this.selectedPrimaryForaward_6_day6 = id;    
  this.selectedPrimaryCount_6_day6 = count;
  this.selected_6_day_6 = day;
}
if(count==7 && day==6){
  this.selectedPrimaryForaward_7_day6 = id;    
  this.selectedPrimaryCount_7_day6 = count;
  this.selected_7_day_6 = day;
}
if(count==8 && day==6){
  this.selectedPrimaryForaward_8_day6 = id;    
  this.selectedPrimaryCount_8_day6 = count;
  this.selected_8_day_6 = day;
}
if(count==9 && day==6){
  this.selectedPrimaryForaward_9_day6 = id;    
  this.selectedPrimaryCount_9_day6 = count;
  this.selected_9_day_6 = day;
}

}

  isPrimarySelected(id:number,count:number,day:number): boolean   
  {
    // Day0
    if(count==0 && day==0){
      if(this.selectedPrimaryForaward_0_day0 == id && this.selectedPrimaryCount_0_day0 === count && this.selected_0_day_0== day)
      return true;
      else 
      return false; 
    }
    if(count==1 && day==0){
      if(this.selectedPrimaryForaward_1_day0 == id && this.selectedPrimaryCount_1_day0 === count && this.selected_1_day_0== day)
      return true;
      else 
      return false; 
    }
    if(count==2 && day==0){
      if(this.selectedPrimaryForaward_2_day0 == id && this.selectedPrimaryCount_2_day0 === count && this.selected_2_day_0== day)
      return true;
      else 
      return false; 
    }
    if(count==3 && day==0){
      if(this.selectedPrimaryForaward_3_day0 == id && this.selectedPrimaryCount_3_day0 === count && this.selected_3_day_0== day)
      return true;
      else 
      return false; 
    }
    if(count==4 && day==0){
      if(this.selectedPrimaryForaward_4_day0 == id && this.selectedPrimaryCount_4_day0 === count && this.selected_4_day_0== day)
      return true;
      else 
      return false; 
    }
    if(count==5 && day==0){
      if(this.selectedPrimaryForaward_5_day0 == id && this.selectedPrimaryCount_5_day0 === count && this.selected_5_day_0== day)
      return true;
      else 
      return false; 
    }
    if(count==6 && day==0){
      if(this.selectedPrimaryForaward_6_day0 == id && this.selectedPrimaryCount_6_day0 === count && this.selected_6_day_0== day)
      return true;
      else 
      return false; 
    }
    if(count==7 && day==0){
      if(this.selectedPrimaryForaward_7_day0 == id && this.selectedPrimaryCount_7_day0 === count && this.selected_7_day_0== day)
      return true;
      else 
      return false; 
    }
    if(count==8 && day==0){
      if(this.selectedPrimaryForaward_8_day0 == id && this.selectedPrimaryCount_8_day0 === count && this.selected_8_day_0== day)
      return true;
      else 
      return false; 
    }

    if(count==9 && day==0){
      if(this.selectedPrimaryForaward_9_day0 == id && this.selectedPrimaryCount_9_day0 === count && this.selected_9_day_0== day)
      return true;
      else 
      return false; 
    }

    // Day1
    if(count==0 && day==1){
      if(this.selectedPrimaryForaward_0_day1 == id && this.selectedPrimaryCount_0_day1 === count && this.selected_0_day_1== day)
      return true;
      else 
      return false; 
    }
    if(count==1 && day==1){
      if(this.selectedPrimaryForaward_1_day1 == id && this.selectedPrimaryCount_1_day1 === count && this.selected_1_day_1== day)
      return true;
      else 
      return false; 
    }
    if(count==2 && day==1){
      if(this.selectedPrimaryForaward_2_day1 == id && this.selectedPrimaryCount_2_day1 === count && this.selected_2_day_1== day)
      return true;
      else 
      return false; 
    }
    if(count==3 && day==1){
      if(this.selectedPrimaryForaward_3_day1 == id && this.selectedPrimaryCount_3_day1 === count && this.selected_3_day_1== day)
      return true;
      else 
      return false; 
    }
    if(count==4 && day==1){
      if(this.selectedPrimaryForaward_4_day1 == id && this.selectedPrimaryCount_4_day1 === count && this.selected_4_day_1== day)
      return true;
      else 
      return false; 
    }
    if(count==5 && day==1){
      if(this.selectedPrimaryForaward_5_day1 == id && this.selectedPrimaryCount_5_day1 === count && this.selected_5_day_1== day)
      return true;
      else 
      return false; 
    }
    if(count==6 && day==1){
      if(this.selectedPrimaryForaward_6_day1 == id && this.selectedPrimaryCount_6_day1 === count && this.selected_6_day_1== day)
      return true;
      else 
      return false; 
    }
    if(count==7 && day==1){
      if(this.selectedPrimaryForaward_7_day1 == id && this.selectedPrimaryCount_7_day1 === count && this.selected_7_day_1== day)
      return true;
      else 
      return false; 
    }
    if(count==8 && day==1){
      if(this.selectedPrimaryForaward_8_day1 == id && this.selectedPrimaryCount_8_day1 === count && this.selected_8_day_1== day)
      return true;
      else 
      return false; 
    }

    if(count==9 && day==1){
      if(this.selectedPrimaryForaward_9_day1 == id && this.selectedPrimaryCount_9_day1 === count && this.selected_9_day_1== day)
      return true;
      else 
      return false; 
    }

    // Day2
    if(count==0 && day==2){
      if(this.selectedPrimaryForaward_0_day2 == id && this.selectedPrimaryCount_0_day2 === count && this.selected_0_day_2== day)
      return true;
      else 
      return false; 
    }
    if(count==1 && day==2){
      if(this.selectedPrimaryForaward_1_day2 == id && this.selectedPrimaryCount_1_day2 === count && this.selected_1_day_2== day)
      return true;
      else 
      return false; 
    }
    if(count==2 && day==2){
      if(this.selectedPrimaryForaward_2_day2 == id && this.selectedPrimaryCount_2_day2 === count && this.selected_2_day_2== day)
      return true;
      else 
      return false; 
    }
    if(count==3 && day==2){
      if(this.selectedPrimaryForaward_3_day2 == id && this.selectedPrimaryCount_3_day2 === count && this.selected_3_day_2== day)
      return true;
      else 
      return false; 
    }
    if(count==4 && day==2){
      if(this.selectedPrimaryForaward_4_day2 == id && this.selectedPrimaryCount_4_day2 === count && this.selected_4_day_2== day)
      return true;
      else 
      return false; 
    }
    if(count==5 && day==2){
      if(this.selectedPrimaryForaward_5_day2 == id && this.selectedPrimaryCount_5_day2 === count && this.selected_5_day_2== day)
      return true;
      else 
      return false; 
    }
    if(count==6 && day==2){
      if(this.selectedPrimaryForaward_6_day2 == id && this.selectedPrimaryCount_6_day2 === count && this.selected_6_day_2== day)
      return true;
      else 
      return false; 
    }
    if(count==7 && day==2){
      if(this.selectedPrimaryForaward_7_day2 == id && this.selectedPrimaryCount_7_day2 === count && this.selected_7_day_2== day)
      return true;
      else 
      return false; 
    }
    if(count==8 && day==2){
      if(this.selectedPrimaryForaward_8_day2 == id && this.selectedPrimaryCount_8_day2 === count && this.selected_8_day_2== day)
      return true;
      else 
      return false; 
    }

    if(count==9 && day==2){
      if(this.selectedPrimaryForaward_9_day2 == id && this.selectedPrimaryCount_9_day2 === count && this.selected_9_day_2== day)
      return true;
      else 
      return false; 
    }

    // Day3
    if(count==0 && day==3){
      if(this.selectedPrimaryForaward_0_day3 == id && this.selectedPrimaryCount_0_day3 === count && this.selected_0_day_3== day)
      return true;
      else 
      return false; 
    }
    if(count==1 && day==3){
      if(this.selectedPrimaryForaward_1_day3 == id && this.selectedPrimaryCount_1_day3 === count && this.selected_1_day_3== day)
      return true;
      else 
      return false; 
    }
    if(count==2 && day==3){
      if(this.selectedPrimaryForaward_2_day3 == id && this.selectedPrimaryCount_2_day3 === count && this.selected_2_day_3== day)
      return true;
      else 
      return false; 
    }
    if(count==3 && day==3){
      if(this.selectedPrimaryForaward_3_day3 == id && this.selectedPrimaryCount_3_day3 === count && this.selected_3_day_3== day)
      return true;
      else 
      return false; 
    }
    if(count==4 && day==3){
      if(this.selectedPrimaryForaward_4_day3 == id && this.selectedPrimaryCount_4_day3 === count && this.selected_4_day_3== day)
      return true;
      else 
      return false; 
    }
    if(count==5 && day==3){
      if(this.selectedPrimaryForaward_5_day3 == id && this.selectedPrimaryCount_5_day3 === count && this.selected_5_day_3== day)
      return true;
      else 
      return false; 
    }
    if(count==6 && day==3){
      if(this.selectedPrimaryForaward_6_day3 == id && this.selectedPrimaryCount_6_day3 === count && this.selected_6_day_3== day)
      return true;
      else 
      return false; 
    }
    if(count==7 && day==3){
      if(this.selectedPrimaryForaward_7_day3 == id && this.selectedPrimaryCount_7_day3 === count && this.selected_7_day_3== day)
      return true;
      else 
      return false; 
    }
    if(count==8 && day==3){
      if(this.selectedPrimaryForaward_8_day3 == id && this.selectedPrimaryCount_8_day3 === count && this.selected_8_day_3== day)
      return true;
      else 
      return false; 
    }

    if(count==9 && day==3){
      if(this.selectedPrimaryForaward_9_day3 == id && this.selectedPrimaryCount_9_day3 === count && this.selected_9_day_3== day)
      return true;
      else 
      return false; 
    }

    // Day4
    if(count==0 && day==4){
      if(this.selectedPrimaryForaward_0_day4 == id && this.selectedPrimaryCount_0_day4 === count && this.selected_0_day_4== day)
      return true;
      else 
      return false; 
    }
    if(count==1 && day==4){
      if(this.selectedPrimaryForaward_1_day4 == id && this.selectedPrimaryCount_1_day4 === count && this.selected_1_day_4== day)
      return true;
      else 
      return false; 
    }
    if(count==2 && day==4){
      if(this.selectedPrimaryForaward_2_day4 == id && this.selectedPrimaryCount_2_day4 === count && this.selected_2_day_4== day)
      return true;
      else 
      return false; 
    }
    if(count==3 && day==4){
      if(this.selectedPrimaryForaward_3_day4 == id && this.selectedPrimaryCount_3_day4 === count && this.selected_3_day_4== day)
      return true;
      else 
      return false; 
    }
    if(count==4 && day==4){
      if(this.selectedPrimaryForaward_4_day4 == id && this.selectedPrimaryCount_4_day4 === count && this.selected_4_day_4== day)
      return true;
      else 
      return false; 
    }
    if(count==5 && day==4){
      if(this.selectedPrimaryForaward_5_day4 == id && this.selectedPrimaryCount_5_day4 === count && this.selected_5_day_4== day)
      return true;
      else 
      return false; 
    }
    if(count==6 && day==4){
      if(this.selectedPrimaryForaward_6_day4 == id && this.selectedPrimaryCount_6_day4 === count && this.selected_6_day_4== day)
      return true;
      else 
      return false; 
    }
    if(count==7 && day==4){
      if(this.selectedPrimaryForaward_7_day4 == id && this.selectedPrimaryCount_7_day4 === count && this.selected_7_day_4== day)
      return true;
      else 
      return false; 
    }
    if(count==8 && day==4){
      if(this.selectedPrimaryForaward_8_day4 == id && this.selectedPrimaryCount_8_day4 === count && this.selected_8_day_4== day)
      return true;
      else 
      return false; 
    }

    if(count==9 && day==4){
      if(this.selectedPrimaryForaward_9_day4 == id && this.selectedPrimaryCount_9_day4 === count && this.selected_9_day_4== day)
      return true;
      else 
      return false; 
    }
    
    // Day5
    if(count==0 && day==5){
      if(this.selectedPrimaryForaward_0_day5 == id && this.selectedPrimaryCount_0_day5 === count && this.selected_0_day_5== day)
      return true;
      else 
      return false; 
    }
    if(count==1 && day==5){
      if(this.selectedPrimaryForaward_1_day5 == id && this.selectedPrimaryCount_1_day5 === count && this.selected_1_day_5== day)
      return true;
      else 
      return false; 
    }
    if(count==2 && day==5){
      if(this.selectedPrimaryForaward_2_day5 == id && this.selectedPrimaryCount_2_day5 === count && this.selected_2_day_5== day)
      return true;
      else 
      return false; 
    }
    if(count==3 && day==5){
      if(this.selectedPrimaryForaward_3_day5 == id && this.selectedPrimaryCount_3_day5 === count && this.selected_3_day_5== day)
      return true;
      else 
      return false; 
    }
    if(count==4 && day==5){
      if(this.selectedPrimaryForaward_4_day5 == id && this.selectedPrimaryCount_4_day5 === count && this.selected_4_day_5== day)
      return true;
      else 
      return false; 
    }
    if(count==5 && day==5){
      if(this.selectedPrimaryForaward_5_day5 == id && this.selectedPrimaryCount_5_day5 === count && this.selected_5_day_5== day)
      return true;
      else 
      return false; 
    }
    if(count==6 && day==5){
      if(this.selectedPrimaryForaward_6_day5 == id && this.selectedPrimaryCount_6_day5 === count && this.selected_6_day_5== day)
      return true;
      else 
      return false; 
    }
    if(count==7 && day==5){
      if(this.selectedPrimaryForaward_7_day5 == id && this.selectedPrimaryCount_7_day5 === count && this.selected_7_day_5== day)
      return true;
      else 
      return false; 
    }
    if(count==8 && day==5){
      if(this.selectedPrimaryForaward_8_day5 == id && this.selectedPrimaryCount_8_day5 === count && this.selected_8_day_5== day)
      return true;
      else 
      return false; 
    }

    if(count==9 && day==5){
      if(this.selectedPrimaryForaward_9_day5 == id && this.selectedPrimaryCount_9_day5 === count && this.selected_9_day_5== day)
      return true;
      else 
      return false; 
    }
    
    // Day6
    if(count==0 && day==6){
      if(this.selectedPrimaryForaward_0_day6 == id && this.selectedPrimaryCount_0_day6 === count && this.selected_0_day_6== day)
      return true;
      else 
      return false; 
    }
    if(count==1 && day==6){
      if(this.selectedPrimaryForaward_1_day6 == id && this.selectedPrimaryCount_1_day6 === count && this.selected_1_day_6== day)
      return true;
      else 
      return false; 
    }
    if(count==2 && day==6){
      if(this.selectedPrimaryForaward_2_day6 == id && this.selectedPrimaryCount_2_day6 === count && this.selected_2_day_6== day)
      return true;
      else 
      return false; 
    }
    if(count==3 && day==6){
      if(this.selectedPrimaryForaward_3_day6 == id && this.selectedPrimaryCount_3_day6 === count && this.selected_3_day_6== day)
      return true;
      else 
      return false; 
    }
    if(count==4 && day==6){
      if(this.selectedPrimaryForaward_4_day6 == id && this.selectedPrimaryCount_4_day6 === count && this.selected_4_day_6== day)
      return true;
      else 
      return false; 
    }
    if(count==5 && day==6){
      if(this.selectedPrimaryForaward_5_day6 == id && this.selectedPrimaryCount_5_day6 === count && this.selected_5_day_6== day)
      return true;
      else 
      return false; 
    }
    if(count==6 && day==6){
      if(this.selectedPrimaryForaward_6_day6 == id && this.selectedPrimaryCount_6_day6 === count && this.selected_6_day_6== day)
      return true;
      else 
      return false; 
    }
    if(count==7 && day==6){
      if(this.selectedPrimaryForaward_7_day6 == id && this.selectedPrimaryCount_7_day6 === count && this.selected_7_day_6== day)
      return true;
      else 
      return false; 
    }
    if(count==8 && day==6){
      if(this.selectedPrimaryForaward_8_day6 == id && this.selectedPrimaryCount_8_day6 === count && this.selected_8_day_6== day)
      return true;
      else 
      return false; 
    }

    if(count==9 && day==6){
      if(this.selectedPrimaryForaward_9_day6 == id && this.selectedPrimaryCount_9_day6 === count && this.selected_9_day_6== day)
      return true;
      else 
      return false; 
    }
      
  }

  onFSLReset() {
    this.FSLRulesSubmitted = false;
    this.addFSLRules.reset();
  }


  selectedLevel :number =0;
  selected(){
    let array = [];
    for (let i = 0; i < this.noofforwarding; i++) {
      array.push(i);
    }
    return array;
  }

  
  Delete(uuid: string) {
    // alert(uuid);return false
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Delete Record!',
      
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.moduleService.deleteFSLRules(uuid).subscribe(
            res => {
              // this.toastrService.success('Number Deleted Successfully');
              // this.FSLRulesSubmitted = false;
              this.addFSLRules.reset();
              Swal.fire(
                'Deleted!',
                'Record Deleted Successfully.',
              )
              setTimeout(() => {
                Swal.close()
              }, 2000);
              
              // this.loadNumbers();
            },
            error => this.error = error
        );       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your Record is safe :)',
          'error'
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
      }
    });
  }
 
  DeleteWeekDay(uuid: string) {
    // alert(uuid);return false
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Delete Record!',
      
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.moduleService.deleteWeekday(uuid).subscribe(
            res => {
              // this.toastrService.success('Number Deleted Successfully');
              // this.FSLRulesSubmitted = false;
              this.addFSLRules.reset();
              Swal.fire(
                'Deleted!',
                'Record Deleted Successfully.',
              )
              setTimeout(() => {
                Swal.close()
              }, 2000);
              
              this.loadweekdays();
            },
            error => this.error = error
        );       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your Record is safe :)',
          'error'
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
      }
    });
  }

  
    // exportdata(){
    //   var options = { 
    //     fieldSeparator: ',',
    //     quoteStrings: '"',
    //     decimalseparator: '.',
    //     showLabels: true, 
    //     // showTitle: true,
    //     // title: 'Your title',
    //     useBom: true,
    //     // noDownload: true,
    //     headers: ["Number", "FSL Rule Name", "Rule Set","Note","Associated abel","Created At"]
    //   };
  
    //   this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    //   this.moduleservice.getNumberForExport(this.currentCompany.uuid).subscribe(
    //     res => {
    //       console.log(res);
    //       new   ngxCsv(res, 'My Report', options);
    //     },
    //     error => this.error = error
    // );    
   
    // }
}
