import { ModuleService } from './../../module.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import * as moment from 'moment';
import { int } from 'aws-sdk/clients/datapipeline';
declare var $;
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Title } from '@angular/platform-browser';

class filterarr
{
  filteradvanced: number;
  filterlabel: string;
  containfiltertype: number;
  containfiltertext: string;
  filtertype: number=1;
  isorand: number;
  uuid: string;
  name: string;
  isdup: Number;
}

@Component({
  selector: 'app-numberedit',
  templateUrl: './numberedit.component.html',
  styleUrls: ['./numberedit.component.scss']
})
export class NumbereditComponent implements OnInit {
  pageTitle: string;
  error: string;
  editForm: FormGroup;
  submitted = false;
  addBuyerForm: FormGroup;
  addFSLRules: FormGroup;
  FSLRulesSubmitted = false;
  noofforwarding: number;
  noofforwardinglocation_1: number;
  noofforwardinglocation_2: number;
  noofforwardinglocation_3: number;
  noofforwardinglocation_4: number;
  noofforwardinglocation_5: number;
  noofforwardinglocation_6: number;
  noofforwardinglocation_7: number;
  public fslrule: any;
  public currentCompany:any;

  @ViewChild('myInput')
  myInputVariable: ElementRef;

  dropdownSettingss:IDropdownSettings;
  dropdownSettings:{};
  dropdownList = [];
  selectedItemsBuyer = [];
  public allBuyers: any=[];

  private http: HttpClient;

  editFSLRules: FormGroup;
  FSLRulesEditSubmitted = false;

  addWeekDaysForm: FormGroup;
  editWeekDaysForm: FormGroup;
  daysSubmitted = false;
  daysEditSubmitted = false;
  rootNode: any;
  loader: any;

  constructor(
    private fb: FormBuilder,
    private moduleService: ModuleService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService:ToastrService,
    handler: HttpBackend,
    private cdRef: ChangeDetectorRef,
    private ngxLoader: NgxUiLoaderService,
    private titleService: Title

  ) {
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.http = new HttpClient(handler);
    this.loader = {
      loaderId: 'customLoaderId',
    };
   }

   public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }


  is12or24:any=1;
  starttime_default:any;
  endtime_default:any;
  starttime_sunday:any;
  endtime_sunday:any;
  starttime_monday:any;
  endtime_monday:any;
  starttime_tuesday:any;
  endtime_tuesday:any;
  starttime_wednesday:any;
  endtime_wednesday:any;
  starttime_thursday:any;
  endtime_thursday:any;
  starttime_friday:any;
  endtime_friday:any;
  starttime_saturday:any;
  endtime_saturday:any;

  is12or24_toggle(event:any){
    // default
    let default_ampm = this.editForm.get('default_ampm').value==0 ? "AM":"PM"
    let default_ampm_close = this.editForm.get('default_ampm_close').value==0 ? "AM":"PM"
    let starttime = this.editForm.get('start_hrs').value+':'+this.editForm.get('start_min').value +' '+default_ampm;
    let endtime = this.editForm.get('end_hrs').value+':'+this.editForm.get('end_min').value +' '+default_ampm_close;
    // sunday
    let sunday_ampm = this.editForm.get('sunday_ampm').value==0 ? "AM":"PM"
    let sunday_ampm_close = this.editForm.get('sunday_ampm_close').value==0 ? "AM":"PM"
    let sun_starttime = this.editForm.get('start_hrs_sunday').value+':'+this.editForm.get('start_min_sunday').value +' '+sunday_ampm;
    let sun_endtime = this.editForm.get('end_hrs_sunday').value+':'+this.editForm.get('end_min_sunday').value +' '+sunday_ampm_close;

    // monday
    let monday_ampm = this.editForm.get('monday_ampm').value==0 ? "AM":"PM"
    let monday_ampm_close = this.editForm.get('monday_ampm_close').value==0 ? "AM":"PM"
    let mon_starttime = this.editForm.get('start_hrs_monday').value+':'+this.editForm.get('start_min_monday').value +' '+monday_ampm;
    let mon_endtime = this.editForm.get('end_hrs_monday').value+':'+this.editForm.get('end_min_monday').value +' '+monday_ampm_close;

    // tuesday
    let tuesday_ampm = this.editForm.get('tuesday_ampm').value==0 ? "AM":"PM"
    let tuesday_ampm_close = this.editForm.get('tuesday_ampm_close').value==0 ? "AM":"PM"
    let tue_starttime = this.editForm.get('start_hrs_tuesday').value+':'+this.editForm.get('start_min_tuesday').value +' '+tuesday_ampm;
    let tue_endtime = this.editForm.get('end_hrs_tuesday').value+':'+this.editForm.get('end_min_tuesday').value +' '+tuesday_ampm_close;

    // wednesday
    let wednesday_ampm = this.editForm.get('wednesday_ampm').value==0 ? "AM":"PM"
    let wednesday_ampm_close = this.editForm.get('wednesday_ampm_close').value==0 ? "AM":"PM"
    let wed_starttime = this.editForm.get('start_hrs_wednesday').value+':'+this.editForm.get('start_min_wednesday').value +' '+wednesday_ampm;
    let wed_endtime = this.editForm.get('end_hrs_wednesday').value+':'+this.editForm.get('end_min_wednesday').value +' '+wednesday_ampm_close;

    // thursday
    let thursday_ampm = this.editForm.get('thursday_ampm').value==0 ? "AM":"PM"
    let thursday_ampm_close = this.editForm.get('thursday_ampm_close').value==0 ? "AM":"PM"
    let thu_starttime = this.editForm.get('start_hrs_thursday').value+':'+this.editForm.get('start_min_thursday').value +' '+thursday_ampm;
    let thu_endtime = this.editForm.get('end_hrs_thursday').value+':'+this.editForm.get('end_min_thursday').value +' '+thursday_ampm_close;

    // friday
    let friday_ampm = this.editForm.get('friday_ampm').value==0 ? "AM":"PM"
    let friday_ampm_close = this.editForm.get('friday_ampm_close').value==0 ? "AM":"PM"
    let fri_starttime = this.editForm.get('start_hrs_friday').value+':'+this.editForm.get('start_min_friday').value +' '+friday_ampm;
    let fri_endtime = this.editForm.get('end_hrs_friday').value+':'+this.editForm.get('end_min_friday').value +' '+friday_ampm_close;

    // saturday
    let saturday_ampm = this.editForm.get('saturday_ampm').value==0 ? "AM":"PM"
    let saturday_ampm_close = this.editForm.get('saturday_ampm_close').value==0 ? "AM":"PM"
    let sat_starttime = this.editForm.get('start_hrs_saturday').value+':'+this.editForm.get('start_min_saturday').value +' '+saturday_ampm;
    let sat_endtime = this.editForm.get('end_hrs_saturday').value+':'+this.editForm.get('end_min_saturday').value +' '+saturday_ampm_close;

    if(event==false){
      this.is12or24 = 0;
      this.starttime_default =  moment(starttime, 'h:mm A').format('HH:mm A')
      this.endtime_default = moment(endtime, 'h:mm A').format('HH:mm A')

      this.starttime_sunday =  moment(sun_starttime, 'h:mm A').format('HH:mm A')
      this.endtime_sunday = moment(sun_endtime, 'h:mm A').format('HH:mm A')

      this.starttime_monday =  moment(mon_starttime, 'h:mm A').format('HH:mm A')
      this.endtime_monday = moment(mon_endtime, 'h:mm A').format('HH:mm A')

      this.starttime_tuesday =  moment(tue_starttime, 'h:mm A').format('HH:mm A')
      this.endtime_tuesday = moment(tue_endtime, 'h:mm A').format('HH:mm A')

      this.starttime_wednesday =  moment(wed_starttime, 'h:mm A').format('HH:mm A')
      this.endtime_wednesday = moment(wed_endtime, 'h:mm A').format('HH:mm A')

      this.starttime_thursday =  moment(thu_starttime, 'h:mm A').format('HH:mm A')
      this.endtime_thursday = moment(thu_endtime, 'h:mm A').format('HH:mm A')

      this.starttime_friday =  moment(fri_starttime, 'h:mm A').format('HH:mm A')
      this.endtime_friday = moment(fri_endtime, 'h:mm A').format('HH:mm A')

      this.starttime_saturday =  moment(sat_starttime, 'h:mm A').format('HH:mm A')
      this.endtime_saturday = moment(sat_endtime, 'h:mm A').format('HH:mm A')
    }

    if(event==true){
      this.is12or24 = 1;
      this.starttime_default = moment(starttime, 'hh:mm A').format('hh:mm A')
      this.endtime_default = moment(endtime, 'hh:mm A').format('hh:mm A')

      this.starttime_sunday =  moment(sun_starttime, 'h:mm A').format('hh:mm A')
      this.endtime_sunday = moment(sun_endtime, 'h:mm A').format('hh:mm A')

      this.starttime_monday =  moment(mon_starttime, 'h:mm A').format('hh:mm A')
      this.endtime_monday = moment(mon_endtime, 'h:mm A').format('hh:mm A')

      this.starttime_tuesday =  moment(tue_starttime, 'h:mm A').format('hh:mm A')
      this.endtime_tuesday = moment(tue_endtime, 'h:mm A').format('hh:mm A')

      this.starttime_wednesday =  moment(wed_starttime, 'h:mm A').format('hh:mm A')
      this.endtime_wednesday = moment(wed_endtime, 'h:mm A').format('hh:mm A')

      this.starttime_thursday =  moment(thu_starttime, 'h:mm A').format('hh:mm A')
      this.endtime_thursday = moment(thu_endtime, 'h:mm A').format('hh:mm A')

      this.starttime_friday =  moment(fri_starttime, 'h:mm A').format('hh:mm A')
      this.endtime_friday = moment(fri_endtime, 'h:mm A').format('hh:mm A')

      this.starttime_saturday =  moment(sat_starttime, 'h:mm A').format('hh:mm A')
      this.endtime_saturday = moment(sat_endtime, 'h:mm A').format('hh:mm A')
    }

    this.editForm.get('start_hrs').setValue(this.starttime_default.substring(0,2));
    this.editForm.get('start_min').setValue(this.starttime_default.substring(3,5));
    this.editForm.get('end_hrs').setValue(this.endtime_default.substring(0,2));
    this.editForm.get('end_min').setValue(this.endtime_default.substring(3,5));

    this.editForm.get('start_hrs_sunday').setValue(this.starttime_sunday.substring(0,2));
    this.editForm.get('start_min_sunday').setValue(this.starttime_sunday.substring(3,5));
    this.editForm.get('end_hrs_sunday').setValue(this.endtime_sunday.substring(0,2));
    this.editForm.get('end_min_sunday').setValue(this.endtime_sunday.substring(3,5));

    this.editForm.get('start_hrs_monday').setValue(this.starttime_monday.substring(0,2));
    this.editForm.get('start_min_monday').setValue(this.starttime_monday.substring(3,5));
    this.editForm.get('end_hrs_monday').setValue(this.endtime_monday.substring(0,2));
    this.editForm.get('end_min_monday').setValue(this.endtime_monday.substring(3,5));

    this.editForm.get('start_hrs_tuesday').setValue(this.starttime_tuesday.substring(0,2));
    this.editForm.get('start_min_tuesday').setValue(this.starttime_tuesday.substring(3,5));
    this.editForm.get('end_hrs_tuesday').setValue(this.endtime_tuesday.substring(0,2));
    this.editForm.get('end_min_tuesday').setValue(this.endtime_tuesday.substring(3,5));

    this.editForm.get('start_hrs_wednesday').setValue(this.starttime_wednesday.substring(0,2));
    this.editForm.get('start_min_wednesday').setValue(this.starttime_wednesday.substring(3,5));
    this.editForm.get('end_hrs_wednesday').setValue(this.endtime_wednesday.substring(0,2));
    this.editForm.get('end_min_wednesday').setValue(this.endtime_wednesday.substring(3,5));

    this.editForm.get('start_hrs_thursday').setValue(this.starttime_thursday.substring(0,2));
    this.editForm.get('start_min_thursday').setValue(this.starttime_thursday.substring(3,5));
    this.editForm.get('end_hrs_thursday').setValue(this.endtime_thursday.substring(0,2));
    this.editForm.get('end_min_thursday').setValue(this.endtime_thursday.substring(3,5));

    this.editForm.get('start_hrs_friday').setValue(this.starttime_friday.substring(0,2));
    this.editForm.get('start_min_friday').setValue(this.starttime_friday.substring(3,5));
    this.editForm.get('end_hrs_friday').setValue(this.endtime_friday.substring(0,2));
    this.editForm.get('end_min_friday').setValue(this.endtime_friday.substring(3,5));

    this.editForm.get('start_hrs_saturday').setValue(this.starttime_saturday.substring(0,2));
    this.editForm.get('start_min_saturday').setValue(this.starttime_saturday.substring(3,5));
    this.editForm.get('end_hrs_saturday').setValue(this.endtime_saturday.substring(0,2));
    this.editForm.get('end_min_saturday').setValue(this.endtime_saturday.substring(3,5));
  }

   forwardingLocationdata : any;
   forwarding : any;
   forwardingtimeframe : any;
   daytype : number = 0;
   flag : int = 0;

   closeModal() {
    if(this.noofforwarding)
    {
      $("#addFSLRules").modal("hide");
      setTimeout(() => {
        this.addFSLRules.reset({
          'name': ' ',
          'numberoftimeframe': ' ',
         });
        // this.getAllRules();
        this.getalldata();
      }, 2000);
    }
  }

  editcloseModal() {
    if(this.noofforwarding)
    {
      $("#editFSLRules").modal("hide");
      this.FSLRulesEditSubmitted=false;
      setTimeout(() => {
        this.getalldata();
        this.getRulesByName(this.editForm.get('fslrulesname').value);
      }, 2000);
    }
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  public rulesData: any;
  public allRulesData: any;
  public allRulesbyname: any;
  public FSLRulesInsertID: any;
  public ruletext: any;

  getAllLabels(){
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleService.getAllLabels(this.currentCompany.uuid).subscribe(
      res => {
        this.dropdownList = res
      },
      error => this.error = error,
    );
  }


  getRulesByName(name: string){
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleService.getRulesByName(name,this.currentCompany.uuid).subscribe(
      res => {
        this.allRulesbyname = res;
      },
      error => this.error = error,
    );
  }

  _keyPress(event: any) {
    const isNumericInput = (event) => {
      const key = event.keyCode;
      return ((key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
      );
    };

    const isModifierKey = (event) => {
      const key = event.keyCode;
      return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
        (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
        (key > 36 && key < 41) || // Allow left, up, right, down
        (
          // Allow Ctrl/Command + A,C,V,X,Z
          (event.ctrlKey === true || event.metaKey === true) &&
          (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
        )
    };

    const enforceFormat = (event) => {
      // Input must be of a valid number format or a modifier key, and not longer than ten digits
      if(!isNumericInput(event) && !isModifierKey(event)){
        event.preventDefault();
      }
    };

    const formatToPhone = (event) => {
      if(isModifierKey(event)) {return;}

      const target = event.target;
      const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
      const zip = input.substring(0,3);
      const middle = input.substring(3,6);
      const last = input.substring(6,10);

      if(input.length > 6){target.value = `(${zip}) ${middle}-${last}`;}
      else if(input.length > 3){target.value = `(${zip}) ${middle}`;}
      else if(input.length > 0){target.value = `(${zip}`;}
    };
    const inputElement = document.getElementById('number');
    const number_0_day0 = document.getElementById('number_0_day0');
    const number_1_day0 = document.getElementById('number_1_day0');
    const number_2_day0 = document.getElementById('number_2_day0');
    const number_3_day0 = document.getElementById('number_3_day0');
    const number_4_day0 = document.getElementById('number_4_day0');
    const number_5_day0 = document.getElementById('number_5_day0');
    const number_6_day0 = document.getElementById('number_6_day0');
    const number_7_day0 = document.getElementById('number_7_day0');
    const number_8_day0 = document.getElementById('number_8_day0');
    const number_9_day0 = document.getElementById('number_9_day0');

    const number_0_day1 = document.getElementById('number_0_day1');
    const number_1_day1 = document.getElementById('number_1_day1');
    const number_2_day1 = document.getElementById('number_2_day1');
    const number_3_day1 = document.getElementById('number_3_day1');
    const number_4_day1 = document.getElementById('number_4_day1');
    const number_5_day1 = document.getElementById('number_5_day1');
    const number_6_day1 = document.getElementById('number_6_day1');
    const number_7_day1 = document.getElementById('number_7_day1');
    const number_8_day1 = document.getElementById('number_8_day1');
    const number_9_day1 = document.getElementById('number_9_day1');

    const number_0_day2 = document.getElementById('number_0_day2');
    const number_1_day2 = document.getElementById('number_1_day2');
    const number_2_day2 = document.getElementById('number_2_day2');
    const number_3_day2 = document.getElementById('number_3_day2');
    const number_4_day2 = document.getElementById('number_4_day2');
    const number_5_day2 = document.getElementById('number_5_day2');
    const number_6_day2 = document.getElementById('number_6_day2');
    const number_7_day2 = document.getElementById('number_7_day2');
    const number_8_day2 = document.getElementById('number_8_day2');
    const number_9_day2 = document.getElementById('number_9_day2');

    const number_0_day3 = document.getElementById('number_0_day3');
    const number_1_day3 = document.getElementById('number_1_day3');
    const number_2_day3 = document.getElementById('number_2_day3');
    const number_3_day3 = document.getElementById('number_3_day3');
    const number_4_day3 = document.getElementById('number_4_day3');
    const number_5_day3 = document.getElementById('number_5_day3');
    const number_6_day3 = document.getElementById('number_6_day3');
    const number_7_day3 = document.getElementById('number_7_day3');
    const number_8_day3 = document.getElementById('number_8_day3');
    const number_9_day3 = document.getElementById('number_9_day3');

    const number_0_day4 = document.getElementById('number_0_day4');
    const number_1_day4 = document.getElementById('number_1_day4');
    const number_2_day4 = document.getElementById('number_2_day4');
    const number_3_day4 = document.getElementById('number_3_day4');
    const number_4_day4 = document.getElementById('number_4_day4');
    const number_5_day4 = document.getElementById('number_5_day4');
    const number_6_day4 = document.getElementById('number_6_day4');
    const number_7_day4 = document.getElementById('number_7_day4');
    const number_8_day4 = document.getElementById('number_8_day4');
    const number_9_day4 = document.getElementById('number_9_day4');

    const number_0_day5 = document.getElementById('number_0_day5');
    const number_1_day5 = document.getElementById('number_1_day5');
    const number_2_day5 = document.getElementById('number_2_day5');
    const number_3_day5 = document.getElementById('number_3_day5');
    const number_4_day5 = document.getElementById('number_4_day5');
    const number_5_day5 = document.getElementById('number_5_day5');
    const number_6_day5 = document.getElementById('number_6_day5');
    const number_7_day5 = document.getElementById('number_7_day5');
    const number_8_day5 = document.getElementById('number_8_day5');
    const number_9_day5 = document.getElementById('number_9_day5');

    const number_0_day6 = document.getElementById('number_0_day6');
    const number_1_day6 = document.getElementById('number_1_day6');
    const number_2_day6 = document.getElementById('number_2_day6');
    const number_3_day6 = document.getElementById('number_3_day6');
    const number_4_day6 = document.getElementById('number_4_day6');
    const number_5_day6 = document.getElementById('number_5_day6');
    const number_6_day6 = document.getElementById('number_6_day6');
    const number_7_day6 = document.getElementById('number_7_day6');
    const number_8_day6 = document.getElementById('number_8_day6');
    const number_9_day6 = document.getElementById('number_9_day6');

      if(inputElement){
      inputElement.addEventListener('keydown',enforceFormat);
      inputElement.addEventListener('keyup',formatToPhone);
      }
      if(number_0_day0){
        number_0_day0.addEventListener('keydown',enforceFormat);
        number_0_day0.addEventListener('keyup',formatToPhone);
      }
      if(number_1_day0){
        number_1_day0.addEventListener('keydown',enforceFormat);
        number_1_day0.addEventListener('keyup',formatToPhone);
      }
      if(number_2_day0){
        number_2_day0.addEventListener('keydown',enforceFormat);
        number_2_day0.addEventListener('keyup',formatToPhone);
      }
      if(number_3_day0){
        number_3_day0.addEventListener('keydown',enforceFormat);
        number_3_day0.addEventListener('keyup',formatToPhone);
      }
      if(number_4_day0){
        number_4_day0.addEventListener('keydown',enforceFormat);
        number_4_day0.addEventListener('keyup',formatToPhone);
      }
      if(number_5_day0){
        number_5_day0.addEventListener('keydown',enforceFormat);
        number_5_day0.addEventListener('keyup',formatToPhone);
      }
      if(number_6_day0){
        number_6_day0.addEventListener('keydown',enforceFormat);
        number_6_day0.addEventListener('keyup',formatToPhone);
      }
      if(number_7_day0){
        number_7_day0.addEventListener('keydown',enforceFormat);
        number_7_day0.addEventListener('keyup',formatToPhone);
      }
      if(number_8_day0){
        number_8_day0.addEventListener('keydown',enforceFormat);
        number_8_day0.addEventListener('keyup',formatToPhone);
      }
      if(number_9_day0){
        number_9_day0.addEventListener('keydown',enforceFormat);
        number_9_day0.addEventListener('keyup',formatToPhone);
      }


      if(number_0_day1){
        number_0_day1.addEventListener('keydown',enforceFormat);
        number_0_day1.addEventListener('keyup',formatToPhone);
      }
      if(number_1_day1){
        number_1_day1.addEventListener('keydown',enforceFormat);
        number_1_day1.addEventListener('keyup',formatToPhone);
      }
      if(number_2_day1){
        number_2_day1.addEventListener('keydown',enforceFormat);
        number_2_day1.addEventListener('keyup',formatToPhone);
      }
      if(number_3_day1){
        number_3_day1.addEventListener('keydown',enforceFormat);
        number_3_day1.addEventListener('keyup',formatToPhone);
      }
      if(number_4_day1){
        number_4_day1.addEventListener('keydown',enforceFormat);
        number_4_day1.addEventListener('keyup',formatToPhone);
      }
      if(number_5_day1){
        number_5_day1.addEventListener('keydown',enforceFormat);
        number_5_day1.addEventListener('keyup',formatToPhone);
      }
      if(number_6_day1){
        number_6_day1.addEventListener('keydown',enforceFormat);
        number_6_day1.addEventListener('keyup',formatToPhone);
      }
      if(number_7_day1){
        number_7_day1.addEventListener('keydown',enforceFormat);
        number_7_day1.addEventListener('keyup',formatToPhone);
      }
      if(number_8_day1){
        number_8_day1.addEventListener('keydown',enforceFormat);
        number_8_day1.addEventListener('keyup',formatToPhone);
      }
      if(number_9_day1){
        number_9_day1.addEventListener('keydown',enforceFormat);
        number_9_day1.addEventListener('keyup',formatToPhone);
      }

      if(number_0_day2){
        number_0_day2.addEventListener('keydown',enforceFormat);
        number_0_day2.addEventListener('keyup',formatToPhone);
      }
      if(number_1_day2){
        number_1_day2.addEventListener('keydown',enforceFormat);
        number_1_day2.addEventListener('keyup',formatToPhone);
      }
      if(number_2_day2){
        number_2_day2.addEventListener('keydown',enforceFormat);
        number_2_day2.addEventListener('keyup',formatToPhone);
      }
      if(number_3_day2){
        number_3_day2.addEventListener('keydown',enforceFormat);
        number_3_day2.addEventListener('keyup',formatToPhone);
      }
      if(number_4_day2){
        number_4_day2.addEventListener('keydown',enforceFormat);
        number_4_day2.addEventListener('keyup',formatToPhone);
      }
      if(number_5_day2){
        number_5_day2.addEventListener('keydown',enforceFormat);
        number_5_day2.addEventListener('keyup',formatToPhone);
      }
      if(number_6_day2){
        number_6_day2.addEventListener('keydown',enforceFormat);
        number_6_day2.addEventListener('keyup',formatToPhone);
      }
      if(number_7_day2){
        number_7_day2.addEventListener('keydown',enforceFormat);
        number_7_day2.addEventListener('keyup',formatToPhone);
      }
      if(number_8_day2){
        number_8_day2.addEventListener('keydown',enforceFormat);
        number_8_day2.addEventListener('keyup',formatToPhone);
      }
      if(number_9_day2){
        number_9_day2.addEventListener('keydown',enforceFormat);
        number_9_day2.addEventListener('keyup',formatToPhone);
      }

      if(number_0_day3){
        number_0_day3.addEventListener('keydown',enforceFormat);
        number_0_day3.addEventListener('keyup',formatToPhone);
      }
      if(number_1_day3){
        number_1_day3.addEventListener('keydown',enforceFormat);
        number_1_day3.addEventListener('keyup',formatToPhone);
      }
      if(number_2_day3){
        number_2_day3.addEventListener('keydown',enforceFormat);
        number_2_day3.addEventListener('keyup',formatToPhone);
      }
      if(number_3_day3){
        number_3_day3.addEventListener('keydown',enforceFormat);
        number_3_day3.addEventListener('keyup',formatToPhone);
      }
      if(number_4_day3){
        number_4_day3.addEventListener('keydown',enforceFormat);
        number_4_day3.addEventListener('keyup',formatToPhone);
      }
      if(number_5_day3){
        number_5_day3.addEventListener('keydown',enforceFormat);
        number_5_day3.addEventListener('keyup',formatToPhone);
      }
      if(number_6_day3){
        number_6_day3.addEventListener('keydown',enforceFormat);
        number_6_day3.addEventListener('keyup',formatToPhone);
      }
      if(number_7_day3){
        number_7_day3.addEventListener('keydown',enforceFormat);
        number_7_day3.addEventListener('keyup',formatToPhone);
      }
      if(number_8_day3){
        number_8_day3.addEventListener('keydown',enforceFormat);
        number_8_day3.addEventListener('keyup',formatToPhone);
      }
      if(number_9_day3){
        number_9_day3.addEventListener('keydown',enforceFormat);
        number_9_day3.addEventListener('keyup',formatToPhone);
      }

      if(number_0_day4){
        number_0_day4.addEventListener('keydown',enforceFormat);
        number_0_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_1_day4){
        number_1_day4.addEventListener('keydown',enforceFormat);
        number_1_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_2_day4){
        number_2_day4.addEventListener('keydown',enforceFormat);
        number_2_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_3_day4){
        number_3_day4.addEventListener('keydown',enforceFormat);
        number_3_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_4_day4){
        number_4_day4.addEventListener('keydown',enforceFormat);
        number_4_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_5_day4){
        number_5_day4.addEventListener('keydown',enforceFormat);
        number_5_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_6_day4){
        number_6_day4.addEventListener('keydown',enforceFormat);
        number_6_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_7_day4){
        number_7_day4.addEventListener('keydown',enforceFormat);
        number_7_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_8_day4){
        number_8_day4.addEventListener('keydown',enforceFormat);
        number_8_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_9_day4){
        number_9_day4.addEventListener('keydown',enforceFormat);
        number_9_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_0_day4){
        number_0_day4.addEventListener('keydown',enforceFormat);
        number_0_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_1_day4){
        number_1_day4.addEventListener('keydown',enforceFormat);
        number_1_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_2_day4){
        number_2_day4.addEventListener('keydown',enforceFormat);
        number_2_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_3_day4){
        number_3_day4.addEventListener('keydown',enforceFormat);
        number_3_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_4_day4){
        number_4_day4.addEventListener('keydown',enforceFormat);
        number_4_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_5_day4){
        number_5_day4.addEventListener('keydown',enforceFormat);
        number_5_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_6_day4){
        number_6_day4.addEventListener('keydown',enforceFormat);
        number_6_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_7_day4){
        number_7_day4.addEventListener('keydown',enforceFormat);
        number_7_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_8_day4){
        number_8_day4.addEventListener('keydown',enforceFormat);
        number_8_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_9_day4){
        number_9_day4.addEventListener('keydown',enforceFormat);
        number_9_day4.addEventListener('keyup',formatToPhone);
      }

      if(number_0_day5){
        number_0_day5.addEventListener('keydown',enforceFormat);
        number_0_day5.addEventListener('keyup',formatToPhone);
      }
      if(number_1_day5){
        number_1_day5.addEventListener('keydown',enforceFormat);
        number_1_day5.addEventListener('keyup',formatToPhone);
      }
      if(number_2_day5){
        number_2_day5.addEventListener('keydown',enforceFormat);
        number_2_day5.addEventListener('keyup',formatToPhone);
      }
      if(number_3_day5){
        number_3_day5.addEventListener('keydown',enforceFormat);
        number_3_day5.addEventListener('keyup',formatToPhone);
      }
      if(number_4_day5){
        number_4_day5.addEventListener('keydown',enforceFormat);
        number_4_day5.addEventListener('keyup',formatToPhone);
      }
      if(number_5_day5){
        number_5_day5.addEventListener('keydown',enforceFormat);
        number_5_day5.addEventListener('keyup',formatToPhone);
      }
      if(number_6_day5){
        number_6_day5.addEventListener('keydown',enforceFormat);
        number_6_day5.addEventListener('keyup',formatToPhone);
      }
      if(number_7_day5){
        number_7_day5.addEventListener('keydown',enforceFormat);
        number_7_day5.addEventListener('keyup',formatToPhone);
      }
      if(number_8_day5){
        number_8_day5.addEventListener('keydown',enforceFormat);
        number_8_day5.addEventListener('keyup',formatToPhone);
      }
      if(number_9_day5){
        number_9_day5.addEventListener('keydown',enforceFormat);
        number_9_day5.addEventListener('keyup',formatToPhone);
      }

      if(number_0_day6){
        number_0_day6.addEventListener('keydown',enforceFormat);
        number_0_day6.addEventListener('keyup',formatToPhone);
      }
      if(number_1_day6){
        number_1_day6.addEventListener('keydown',enforceFormat);
        number_1_day6.addEventListener('keyup',formatToPhone);
      }
      if(number_2_day6){
        number_2_day6.addEventListener('keydown',enforceFormat);
        number_2_day6.addEventListener('keyup',formatToPhone);
      }
      if(number_3_day6){
        number_3_day6.addEventListener('keydown',enforceFormat);
        number_3_day6.addEventListener('keyup',formatToPhone);
      }
      if(number_4_day6){
        number_4_day6.addEventListener('keydown',enforceFormat);
        number_4_day6.addEventListener('keyup',formatToPhone);
      }
      if(number_5_day6){
        number_5_day6.addEventListener('keydown',enforceFormat);
        number_5_day6.addEventListener('keyup',formatToPhone);
      }
      if(number_6_day6){
        number_6_day6.addEventListener('keydown',enforceFormat);
        number_6_day6.addEventListener('keyup',formatToPhone);
      }
      if(number_7_day6){
        number_7_day6.addEventListener('keydown',enforceFormat);
        number_7_day6.addEventListener('keyup',formatToPhone);
      }
      if(number_8_day6){
        number_8_day6.addEventListener('keydown',enforceFormat);
        number_8_day6.addEventListener('keyup',formatToPhone);
      }
      if(number_9_day6){
        number_9_day6.addEventListener('keydown',enforceFormat);
        number_9_day6.addEventListener('keyup',formatToPhone);
      }


      const editnumber_0_day0 = document.getElementById('editnumber_0_day0');
      const editnumber_1_day0 = document.getElementById('editnumber_1_day0');
      const editnumber_2_day0 = document.getElementById('editnumber_2_day0');
      const editnumber_3_day0 = document.getElementById('editnumber_3_day0');
      const editnumber_4_day0 = document.getElementById('editnumber_4_day0');
      const editnumber_5_day0 = document.getElementById('editnumber_5_day0');
      const editnumber_6_day0 = document.getElementById('editnumber_6_day0');
      const editnumber_7_day0 = document.getElementById('editnumber_7_day0');
      const editnumber_8_day0 = document.getElementById('editnumber_8_day0');
      const editnumber_9_day0 = document.getElementById('editnumber_9_day0');

      const editnumber_0_day1 = document.getElementById('editnumber_0_day1');
      const editnumber_1_day1 = document.getElementById('editnumber_1_day1');
      const editnumber_2_day1 = document.getElementById('editnumber_2_day1');
      const editnumber_3_day1 = document.getElementById('editnumber_3_day1');
      const editnumber_4_day1 = document.getElementById('editnumber_4_day1');
      const editnumber_5_day1 = document.getElementById('editnumber_5_day1');
      const editnumber_6_day1 = document.getElementById('editnumber_6_day1');
      const editnumber_7_day1 = document.getElementById('editnumber_7_day1');
      const editnumber_8_day1 = document.getElementById('editnumber_8_day1');
      const editnumber_9_day1 = document.getElementById('editnumber_9_day1');

      const editnumber_0_day2 = document.getElementById('editnumber_0_day2');
      const editnumber_1_day2 = document.getElementById('editnumber_1_day2');
      const editnumber_2_day2 = document.getElementById('editnumber_2_day2');
      const editnumber_3_day2 = document.getElementById('editnumber_3_day2');
      const editnumber_4_day2 = document.getElementById('editnumber_4_day2');
      const editnumber_5_day2 = document.getElementById('editnumber_5_day2');
      const editnumber_6_day2 = document.getElementById('editnumber_6_day2');
      const editnumber_7_day2 = document.getElementById('editnumber_7_day2');
      const editnumber_8_day2 = document.getElementById('editnumber_8_day2');
      const editnumber_9_day2 = document.getElementById('editnumber_9_day2');

      const editnumber_0_day3 = document.getElementById('editnumber_0_day3');
      const editnumber_1_day3 = document.getElementById('editnumber_1_day3');
      const editnumber_2_day3 = document.getElementById('editnumber_2_day3');
      const editnumber_3_day3 = document.getElementById('editnumber_3_day3');
      const editnumber_4_day3 = document.getElementById('editnumber_4_day3');
      const editnumber_5_day3 = document.getElementById('editnumber_5_day3');
      const editnumber_6_day3 = document.getElementById('editnumber_6_day3');
      const editnumber_7_day3 = document.getElementById('editnumber_7_day3');
      const editnumber_8_day3 = document.getElementById('editnumber_8_day3');
      const editnumber_9_day3 = document.getElementById('editnumber_9_day3');

      const editnumber_0_day4 = document.getElementById('editnumber_0_day4');
      const editnumber_1_day4 = document.getElementById('editnumber_1_day4');
      const editnumber_2_day4 = document.getElementById('editnumber_2_day4');
      const editnumber_3_day4 = document.getElementById('editnumber_3_day4');
      const editnumber_4_day4 = document.getElementById('editnumber_4_day4');
      const editnumber_5_day4 = document.getElementById('editnumber_5_day4');
      const editnumber_6_day4 = document.getElementById('editnumber_6_day4');
      const editnumber_7_day4 = document.getElementById('editnumber_7_day4');
      const editnumber_8_day4 = document.getElementById('editnumber_8_day4');
      const editnumber_9_day4 = document.getElementById('editnumber_9_day4');

      const editnumber_0_day5 = document.getElementById('editnumber_0_day5');
      const editnumber_1_day5 = document.getElementById('editnumber_1_day5');
      const editnumber_2_day5 = document.getElementById('editnumber_2_day5');
      const editnumber_3_day5 = document.getElementById('editnumber_3_day5');
      const editnumber_4_day5 = document.getElementById('editnumber_4_day5');
      const editnumber_5_day5 = document.getElementById('editnumber_5_day5');
      const editnumber_6_day5 = document.getElementById('editnumber_6_day5');
      const editnumber_7_day5 = document.getElementById('editnumber_7_day5');
      const editnumber_8_day5 = document.getElementById('editnumber_8_day5');
      const editnumber_9_day5 = document.getElementById('editnumber_9_day5');

      const editnumber_0_day6 = document.getElementById('editnumber_0_day6');
      const editnumber_1_day6 = document.getElementById('editnumber_1_day6');
      const editnumber_2_day6 = document.getElementById('editnumber_2_day6');
      const editnumber_3_day6 = document.getElementById('editnumber_3_day6');
      const editnumber_4_day6 = document.getElementById('editnumber_4_day6');
      const editnumber_5_day6 = document.getElementById('editnumber_5_day6');
      const editnumber_6_day6 = document.getElementById('editnumber_6_day6');
      const editnumber_7_day6 = document.getElementById('editnumber_7_day6');
      const editnumber_8_day6 = document.getElementById('editnumber_8_day6');
      const editnumber_9_day6 = document.getElementById('editnumber_9_day6');

      if(editnumber_0_day0){
        editnumber_0_day0.addEventListener('keydown',enforceFormat);
        editnumber_0_day0.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_1_day0){
        editnumber_1_day0.addEventListener('keydown',enforceFormat);
        editnumber_1_day0.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_2_day0){
        editnumber_2_day0.addEventListener('keydown',enforceFormat);
        editnumber_2_day0.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_3_day0){
        editnumber_3_day0.addEventListener('keydown',enforceFormat);
        editnumber_3_day0.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_4_day0){
        editnumber_4_day0.addEventListener('keydown',enforceFormat);
        editnumber_4_day0.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_5_day0){
        editnumber_5_day0.addEventListener('keydown',enforceFormat);
        editnumber_5_day0.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_6_day0){
        editnumber_6_day0.addEventListener('keydown',enforceFormat);
        editnumber_6_day0.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_7_day0){
        editnumber_7_day0.addEventListener('keydown',enforceFormat);
        editnumber_7_day0.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_8_day0){
        editnumber_8_day0.addEventListener('keydown',enforceFormat);
        editnumber_8_day0.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_9_day0){
        editnumber_9_day0.addEventListener('keydown',enforceFormat);
        editnumber_9_day0.addEventListener('keyup',formatToPhone);
      }

      if(editnumber_0_day1){
        editnumber_0_day1.addEventListener('keydown',enforceFormat);
        editnumber_0_day1.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_1_day1){
        editnumber_1_day1.addEventListener('keydown',enforceFormat);
        editnumber_1_day1.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_2_day1){
        editnumber_2_day1.addEventListener('keydown',enforceFormat);
        editnumber_2_day1.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_3_day1){
        editnumber_3_day1.addEventListener('keydown',enforceFormat);
        editnumber_3_day1.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_4_day1){
        editnumber_4_day1.addEventListener('keydown',enforceFormat);
        editnumber_4_day1.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_5_day1){
        editnumber_5_day1.addEventListener('keydown',enforceFormat);
        editnumber_5_day1.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_6_day1){
        editnumber_6_day1.addEventListener('keydown',enforceFormat);
        editnumber_6_day1.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_7_day1){
        editnumber_7_day1.addEventListener('keydown',enforceFormat);
        editnumber_7_day1.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_8_day1){
        editnumber_8_day1.addEventListener('keydown',enforceFormat);
        editnumber_8_day1.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_9_day1){
        editnumber_9_day1.addEventListener('keydown',enforceFormat);
        editnumber_9_day1.addEventListener('keyup',formatToPhone);
      }

      if(editnumber_0_day2){
        editnumber_0_day2.addEventListener('keydown',enforceFormat);
        editnumber_0_day2.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_1_day2){
        editnumber_1_day2.addEventListener('keydown',enforceFormat);
        editnumber_1_day2.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_2_day2){
        editnumber_2_day2.addEventListener('keydown',enforceFormat);
        editnumber_2_day2.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_3_day2){
        editnumber_3_day2.addEventListener('keydown',enforceFormat);
        editnumber_3_day2.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_4_day2){
        editnumber_4_day2.addEventListener('keydown',enforceFormat);
        editnumber_4_day2.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_5_day2){
        editnumber_5_day2.addEventListener('keydown',enforceFormat);
        editnumber_5_day2.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_6_day2){
        editnumber_6_day2.addEventListener('keydown',enforceFormat);
        editnumber_6_day2.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_7_day2){
        editnumber_7_day2.addEventListener('keydown',enforceFormat);
        editnumber_7_day2.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_8_day2){
        editnumber_8_day2.addEventListener('keydown',enforceFormat);
        editnumber_8_day2.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_9_day2){
        editnumber_9_day2.addEventListener('keydown',enforceFormat);
        editnumber_9_day2.addEventListener('keyup',formatToPhone);
      }

      if(editnumber_0_day3){
        editnumber_0_day3.addEventListener('keydown',enforceFormat);
        editnumber_0_day3.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_1_day3){
        editnumber_1_day3.addEventListener('keydown',enforceFormat);
        editnumber_1_day3.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_2_day3){
        editnumber_2_day3.addEventListener('keydown',enforceFormat);
        editnumber_2_day3.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_3_day3){
        editnumber_3_day3.addEventListener('keydown',enforceFormat);
        editnumber_3_day3.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_4_day3){
        editnumber_4_day3.addEventListener('keydown',enforceFormat);
        editnumber_4_day3.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_5_day3){
        editnumber_5_day3.addEventListener('keydown',enforceFormat);
        editnumber_5_day3.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_6_day3){
        editnumber_6_day3.addEventListener('keydown',enforceFormat);
        editnumber_6_day3.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_7_day3){
        editnumber_7_day3.addEventListener('keydown',enforceFormat);
        editnumber_7_day3.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_8_day3){
        editnumber_8_day3.addEventListener('keydown',enforceFormat);
        editnumber_8_day3.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_9_day3){
        editnumber_9_day3.addEventListener('keydown',enforceFormat);
        editnumber_9_day3.addEventListener('keyup',formatToPhone);
      }

      if(editnumber_0_day4){
        editnumber_0_day4.addEventListener('keydown',enforceFormat);
        editnumber_0_day4.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_1_day4){
        editnumber_1_day4.addEventListener('keydown',enforceFormat);
        editnumber_1_day4.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_2_day4){
        editnumber_2_day4.addEventListener('keydown',enforceFormat);
        editnumber_2_day4.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_3_day4){
        editnumber_3_day4.addEventListener('keydown',enforceFormat);
        editnumber_3_day4.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_4_day4){
        editnumber_4_day4.addEventListener('keydown',enforceFormat);
        editnumber_4_day4.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_5_day4){
        editnumber_5_day4.addEventListener('keydown',enforceFormat);
        editnumber_5_day4.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_6_day4){
        editnumber_6_day4.addEventListener('keydown',enforceFormat);
        editnumber_6_day4.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_7_day4){
        editnumber_7_day4.addEventListener('keydown',enforceFormat);
        editnumber_7_day4.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_8_day4){
        editnumber_8_day4.addEventListener('keydown',enforceFormat);
        editnumber_8_day4.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_9_day4){
        editnumber_9_day4.addEventListener('keydown',enforceFormat);
        editnumber_9_day4.addEventListener('keyup',formatToPhone);
      }

      if(editnumber_0_day5){
        editnumber_0_day5.addEventListener('keydown',enforceFormat);
        editnumber_0_day5.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_1_day5){
        editnumber_1_day5.addEventListener('keydown',enforceFormat);
        editnumber_1_day5.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_2_day5){
        editnumber_2_day5.addEventListener('keydown',enforceFormat);
        editnumber_2_day5.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_3_day5){
        editnumber_3_day5.addEventListener('keydown',enforceFormat);
        editnumber_3_day5.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_4_day5){
        editnumber_4_day5.addEventListener('keydown',enforceFormat);
        editnumber_4_day5.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_5_day5){
        editnumber_5_day5.addEventListener('keydown',enforceFormat);
        editnumber_5_day5.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_6_day5){
        editnumber_6_day5.addEventListener('keydown',enforceFormat);
        editnumber_6_day5.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_7_day5){
        editnumber_7_day5.addEventListener('keydown',enforceFormat);
        editnumber_7_day5.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_8_day5){
        editnumber_8_day5.addEventListener('keydown',enforceFormat);
        editnumber_8_day5.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_9_day5){
        editnumber_9_day5.addEventListener('keydown',enforceFormat);
        editnumber_9_day5.addEventListener('keyup',formatToPhone);
      }

      if(editnumber_0_day6){
        editnumber_0_day6.addEventListener('keydown',enforceFormat);
        editnumber_0_day6.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_1_day6){
        editnumber_1_day6.addEventListener('keydown',enforceFormat);
        editnumber_1_day6.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_2_day6){
        editnumber_2_day6.addEventListener('keydown',enforceFormat);
        editnumber_2_day6.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_3_day6){
        editnumber_3_day6.addEventListener('keydown',enforceFormat);
        editnumber_3_day6.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_4_day6){
        editnumber_4_day6.addEventListener('keydown',enforceFormat);
        editnumber_4_day6.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_5_day6){
        editnumber_5_day6.addEventListener('keydown',enforceFormat);
        editnumber_5_day6.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_6_day6){
        editnumber_6_day6.addEventListener('keydown',enforceFormat);
        editnumber_6_day6.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_7_day6){
        editnumber_7_day6.addEventListener('keydown',enforceFormat);
        editnumber_7_day6.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_8_day6){
        editnumber_8_day6.addEventListener('keydown',enforceFormat);
        editnumber_8_day6.addEventListener('keyup',formatToPhone);
      }
      if(editnumber_9_day6){
        editnumber_9_day6.addEventListener('keydown',enforceFormat);
        editnumber_9_day6.addEventListener('keyup',formatToPhone);
      }
  }

  isName : any;
  validatename() {
  this.moduleService.validatename(this.addFSLRules.get('name').value).subscribe(
    res =>{
        // console.log(res); return false;
      this.isName = res;
      if (this.isName.status == 'success') {
        this.isName = "success";
      }
      else{
        this.isName = "fail";
      }
      // console.log(this.isName); return false;
    }
  // }
  );
}

  allrules : any;
  getAllRuleset(){
    this.moduleService.getRules(this.currentCompany.uuid).subscribe(
      res =>{
        // console.log(res);
        this.allrules = res.rule_data;
      }
    );
  }

  alltimezone:any=[];
  // gettimezone(){
  //   this.moduleService.gettimezone().subscribe(
  //     res => {
  //       this.alltimezone = res;
  //       // console.log(res);
  //     }
  //   );
  // }


  increment_hrs(index:string){
    let val = this.editForm.get(index).value;
    if(this.is12or24==0){
      if(val<23){
        let fianlval = Number(val)+1;
        this.editForm.get(index).setValue(fianlval<10?"0"+fianlval:fianlval);
        }
    }else{
      if(val<12){
        let fianlval = Number(val)+1;
        this.editForm.get(index).setValue(fianlval<10?"0"+fianlval:fianlval);
        }
    }
  }
  decrement_hrs(index:string){
    let val = this.editForm.get(index).value;
    if(val>0){
      let fianlval = Number(val)-1;
      this.editForm.get(index).setValue(fianlval<10?"0"+fianlval:fianlval);
    }
  }

  increment_min(index:string){
    let val = this.editForm.get(index).value;
    if(val<59){
    let fianlval = Number(val)+1;
    this.editForm.get(index).setValue(fianlval<10?"0"+fianlval:fianlval);
    }
  }
  decrement_min(index:string){
    let val = this.editForm.get(index).value;
    if(val>0){
      let fianlval = Number(val)-1;
      this.editForm.get(index).setValue(fianlval<10?"0"+fianlval:fianlval);
    }
  }

  appm_change(index:string){
    let val = this.editForm.get(index).value;
    if(val==0){
      this.editForm.get(index).setValue(1);
    }else{
      this.editForm.get(index).setValue(0);
    }
  }

  allcity:any;
  getdata(){
      this.moduleService.loadcity(this.editForm.get('citystate').value).subscribe(
        res => {
          //  console.log(res); return false;
          this.allcity = res;
        },
        error => this.error = error,
      );
   }

   // this.fslrule = 1;
    // this.getAllRules();
    // this.getAllLabels();
    // this.getAllRuleset();
    // this.loadallweekdays();

  allcitystate:any = [];
  getcitystate(type:any,datatype:any){
    this.moduleService.loadcitystate(type,datatype).subscribe(
      res => {
        //  console.log(res); return false;
        this.allcitystate = res;
      },
      error => this.error = error,
    );
  }

  allpublisher:any = [];
  allpublisherbkp:any = [];
  getAllpublishers(){
    let cc = JSON.parse(localStorage.getItem('currentCompany'))
    this.moduleService.getAllpublishers(cc.uuid).subscribe(
      res => {
        //  console.log(res); return false;
        this.allpublisher = res;
        this.allpublisherbkp = res;
      },
      error => this.error = error,
    );
  }

  getpubfiltertype(event:any){
    console.log(event.target.value);
    let filterdata = [];
    if(event.target.value==1){
      this.allpublisherbkp.forEach(element => {
        let obj = {
          'id' :element.id,
          'itemName' :element.id,
          'text' :element.itemName,
        }
        filterdata.push(obj);
      });
    }else{
      this.allpublisherbkp.forEach(element => {
        let obj = {
          'id' :element.id,
          'itemName' :element.itemName,
          'text' :element.itemName,
        }
        filterdata.push(obj);
      });
    }
    this.allpublisher = filterdata;
  }

  addRow(e) {
    this.addkeyword.push(e);
  }
  removeRow(e) {
    this.addkeyword.pop(e);
  }

  jklval:number;
  ivrdropdownSettings = {};
  addkeyword : any = [];
  settings = {};
  selectedItems = [];
  selectedItemsBuyer1 = [];
  selected_ivr = [];
  showLoadingIndicator:any;
  ngOnInit() {
    // this.showLoadingIndicator= true;
    this.ngxLoader.startLoader('customLoaderId');
    this.settings = {
      enableSearchFilter: true,
      singleSelection: true, showCheckbox:false,text:"Select Buyer"};

      this.getAllpublishers()
    this.getcitystate(1,1);
    this.getalldata();
    // this.getIVRMenu().then((res)=>{
    //   this.ivrmenus = res
    // });
    // this.getAllRules().then((res)=>{
    //   this.allBuyers = res
    // })

    this.addBuyerForm = this.fb.group({
      company : ['', Validators.required],
      email : ['', Validators.required],
      password: [''],
      sub_id: [''],
      allow_buyer_to_pause_target: [''],
      allow_buyer_to_set_target_concurrency: [''],
      allow_buyer_to_dispute_call_conversions: [''],
      limit_revenue: [''],
    });

    this.editForm = this.fb.group({
      name: ['',Validators.required],
      subid: [''],
      buyer: [''],
      settingToggleBlockCallsIfCapped: [''],
      settingToggleType: [''],
      number: [''],
      ivrMenu: [''],
      sip_address: [''],
      voice_mail: [''],

      url: [''],
      http_method: [''],
      content_type: [''],
      body: [''],
      http_headers_name: [''],
      http_headers_value: [''],
      keyword0: [''],
      keyword1: [''],
      keyword2: [''],

      // start_hrs: [''],
      // start_min: [''],
      // end_hrs: [''],
      // end_min: [''],
      // timezone: [''],

      // hoursofoperation_start_1: [''],
      // hoursofoperation_end_1: [''],
      // start_hrs_1: [''],
      // start_min_1: [''],
      // end_hrs_1: [''],
      // end_min_1: [''],
      // timezone1: [''],

      hoursofoperation_start: ['Monday'],
      hoursofoperation_end: ['Sunday'],
      start_hrs: ['00'],
      start_min: ['01'],
      end_hrs: ['11'],
      end_min: ['59'],
      timezone: [],
      default_ampm: ['0'],
      default_ampm_close: ['1'],

      start_hrs_sunday: ['00'],
      start_min_sunday: ['01'],
      end_hrs_sunday: ['11'],
      end_min_sunday: ['59'],
      sunday_ampm: ['0'],
      sunday_ampm_close: ['1'],

      start_hrs_monday: ['00'],
      start_min_monday: ['01'],
      end_hrs_monday: ['11'],
      end_min_monday: ['59'],
      monday_ampm: ['0'],
      monday_ampm_close: ['1'],

      start_hrs_tuesday: ['00'],
      start_min_tuesday: ['01'],
      end_hrs_tuesday: ['11'],
      end_min_tuesday: ['59'],
      tuesday_ampm: ['0'],
      tuesday_ampm_close: ['1'],

      start_hrs_wednesday: ['00'],
      start_min_wednesday: ['01'],
      end_hrs_wednesday: ['11'],
      end_min_wednesday: ['59'],
      wednesday_ampm: ['0'],
      wednesday_ampm_close: ['1'],

      start_hrs_thursday: ['00'],
      start_min_thursday: ['11'],
      end_hrs_thursday: ['11'],
      end_min_thursday: ['59'],
      thursday_ampm: ['0'],
      thursday_ampm_close: ['1'],

      start_hrs_friday: ['00'],
      start_min_friday: ['01'],
      end_hrs_friday: ['11'],
      end_min_friday: ['59'],
      friday_ampm: ['0'],
      friday_ampm_close: ['1'],

      start_hrs_saturday: ['00'],
      start_min_saturday: ['01'],
      end_hrs_saturday: ['11'],
      end_min_saturday: ['59'],
      saturday_ampm: ['0'],
      saturday_ampm_close: ['1'],

      // timezone: ['',Validators.required],
      // hoursofoperation_start: [''],
      // hoursofoperation_end: [''],
      capon: [''],

      global_revenuecapvalue: [''],
      monthly_revenuecapvalue: [''],
      daily_revenuecapvalue: [''],
      hourly_revenuecapvalue: [''],

      global_capvalue: [''],
      monthly_capvalue: [''],
      daily_capvalue: [''],
      hourly_capvalue: [''],

      dtmf_value: [''],
      dtmf_interval: [''],
      dtmf_percent: [''],

      maxconvalue: [''],
      hourlyconcurrencyvalue: [''],

      restrictdup: [''],
      restrictafter: [''],
      days: [''],
      settingToggleDurationval: [''],
      settingToggleDuration: [''],

      isfilter: [''],
      citystate: [''],
      cityval: [''],
      citystatetype: [''],
      iscontains: [''],

      ispubfilter:[''],
      pubvalue:[''],
      pubfiltertype: ['0'],
      pubiscontains: ['0'],
    });

    // this.editForm.patchValue({
    //   citystatetype:1,
    //   citystate:""
    // });

    this.addWeekDaysForm = this.fb.group({
      start_day: ['', Validators.required],
      end_day: ['', Validators.required],
    });

    this.editWeekDaysForm = this.fb.group({
      uuid: ['', ''],
      start_day: ['', Validators.required],
      end_day: ['', Validators.required],
    });

    this.addFSLRules = this.fb.group({
      name: ['', Validators.required],
      numberoftimeframe: ['', Validators.required],
      noofforwardinglocation_1: ['', ''],
      noofforwardinglocation_2: ['', ''],
      noofforwardinglocation_3: ['', ''],
      noofforwardinglocation_4: ['', ''],
      noofforwardinglocation_5: ['', ''],
      noofforwardinglocation_6: ['', ''],
      noofforwardinglocation_7: ['', ''],

      // Day 1
      day_0: ['', ''],
      start_hrs_0: ['', ''],
      start_min_0: ['', ''],
      end_hrs_0: ['', ''],
      end_min_0: ['', ''],

      PrimaryforwardType_0_day0: ['', ''],
      number_0_day0: ['', ''],
      ivrMenu_0_day0: ['', ''],
      sipAdd_0_day0: ['', ''],

      PrimaryforwardType_1_day0: ['', ''],
      number_1_day0: ['', ''],
      ivrMenu_1_day0: ['', ''],
      sipAdd_1_day0: ['', ''],

      PrimaryforwardType_2_day0: ['', ''],
      number_2_day0: ['', ''],
      ivrMenu_2_day0: ['', ''],
      sipAdd_2_day0: ['', ''],

      PrimaryforwardType_3_day0: ['', ''],
      number_3_day0: ['', ''],
      ivrMenu_3_day0: ['', ''],
      sipAdd_3_day0: ['', ''],

      PrimaryforwardType_4_day0: ['', ''],
      number_4_day0: ['', ''],
      ivrMenu_4_day0: ['', ''],
      sipAdd_4_day0: ['', ''],

      PrimaryforwardType_5_day0: ['', ''],
      number_5_day0: ['', ''],
      ivrMenu_5_day0: ['', ''],
      sipAdd_5_day0: ['', ''],

      PrimaryforwardType_6_day0: ['', ''],
      number_6_day0: ['', ''],
      ivrMenu_6_day0: ['', ''],
      sipAdd_6_day0: ['', ''],

      PrimaryforwardType_7_day0: ['', ''],
      number_7_day0: ['', ''],
      ivrMenu_7_day0: ['', ''],
      sipAdd_7_day0: ['', ''],

      PrimaryforwardType_8_day0: ['', ''],
      number_8_day0: ['', ''],
      ivrMenu_8_day0: ['', ''],
      sipAdd_8_day0: ['', ''],

      PrimaryforwardType_9_day0: ['', ''],
      number_9_day0: ['', ''],
      ivrMenu_9_day0: ['', ''],
      sipAdd_9_day0: ['', ''],

      // Day2
      day_1: ['', ''],
      start_hrs_1: ['', ''],
      start_min_1: ['', ''],
      end_hrs_1: ['', ''],
      end_min_1: ['', ''],

      PrimaryforwardType_0_day1: ['', ''],
      number_0_day1: ['', ''],
      ivrMenu_0_day1: ['', ''],
      sipAdd_0_day1: ['', ''],

      PrimaryforwardType_1_day1: ['', ''],
      number_1_day1: ['', ''],
      ivrMenu_1_day1: ['', ''],
      sipAdd_1_day1: ['', ''],

      PrimaryforwardType_2_day1: ['', ''],
      number_2_day1: ['', ''],
      ivrMenu_2_day1: ['', ''],
      sipAdd_2_day1: ['', ''],

      PrimaryforwardType_3_day1: ['', ''],
      number_3_day1: ['', ''],
      ivrMenu_3_day1: ['', ''],
      sipAdd_3_day1: ['', ''],

      PrimaryforwardType_4_day1: ['', ''],
      number_4_day1: ['', ''],
      ivrMenu_4_day1: ['', ''],
      sipAdd_4_day1: ['', ''],

      PrimaryforwardType_5_day1: ['', ''],
      number_5_day1: ['', ''],
      ivrMenu_5_day1: ['', ''],
      sipAdd_5_day1: ['', ''],

      PrimaryforwardType_6_day1: ['', ''],
      number_6_day1: ['', ''],
      ivrMenu_6_day1: ['', ''],
      sipAdd_6_day1: ['', ''],

      PrimaryforwardType_7_day1: ['', ''],
      number_7_day1: ['', ''],
      ivrMenu_7_day1: ['', ''],
      sipAdd_7_day1: ['', ''],

      PrimaryforwardType_8_day1: ['', ''],
      number_8_day1: ['', ''],
      ivrMenu_8_day1: ['', ''],
      sipAdd_8_day1: ['', ''],

      PrimaryforwardType_9_day1: ['', ''],
      number_9_day1: ['', ''],
      ivrMenu_9_day1: ['', ''],
      sipAdd_9_day1: ['', ''],

      // Day3
      day_2: ['', ''],
      start_hrs_2: ['', ''],
      start_min_2: ['', ''],
      end_hrs_2: ['', ''],
      end_min_2: ['', ''],

      PrimaryforwardType_0_day2: ['', ''],
      number_0_day2: ['', ''],
      ivrMenu_0_day2: ['', ''],
      sipAdd_0_day2: ['', ''],

      PrimaryforwardType_1_day2: ['', ''],
      number_1_day2: ['', ''],
      ivrMenu_1_day2: ['', ''],
      sipAdd_1_day2: ['', ''],

      PrimaryforwardType_2_day2: ['', ''],
      number_2_day2: ['', ''],
      ivrMenu_2_day2: ['', ''],
      sipAdd_2_day2: ['', ''],

      PrimaryforwardType_3_day2: ['', ''],
      number_3_day2: ['', ''],
      ivrMenu_3_day2: ['', ''],
      sipAdd_3_day2: ['', ''],

      PrimaryforwardType_4_day2: ['', ''],
      number_4_day2: ['', ''],
      ivrMenu_4_day2: ['', ''],
      sipAdd_4_day2: ['', ''],

      PrimaryforwardType_5_day2: ['', ''],
      number_5_day2: ['', ''],
      ivrMenu_5_day2: ['', ''],
      sipAdd_5_day2: ['', ''],

      PrimaryforwardType_6_day2: ['', ''],
      number_6_day2: ['', ''],
      ivrMenu_6_day2: ['', ''],
      sipAdd_6_day2: ['', ''],

      PrimaryforwardType_7_day2: ['', ''],
      number_7_day2: ['', ''],
      ivrMenu_7_day2: ['', ''],
      sipAdd_7_day2: ['', ''],

      PrimaryforwardType_8_day2: ['', ''],
      number_8_day2: ['', ''],
      ivrMenu_8_day2: ['', ''],
      sipAdd_8_day2: ['', ''],

      PrimaryforwardType_9_day2: ['', ''],
      number_9_day2: ['', ''],
      ivrMenu_9_day2: ['', ''],
      sipAdd_9_day2: ['', ''],

      // Day4
      day_3: ['', ''],
      start_hrs_3: ['', ''],
      start_min_3: ['', ''],
      end_hrs_3: ['', ''],
      end_min_3: ['', ''],

      PrimaryforwardType_0_day3: ['', ''],
      number_0_day3: ['', ''],
      ivrMenu_0_day3: ['', ''],
      sipAdd_0_day3: ['', ''],

      PrimaryforwardType_1_day3: ['', ''],
      number_1_day3: ['', ''],
      ivrMenu_1_day3: ['', ''],
      sipAdd_1_day3: ['', ''],

      PrimaryforwardType_2_day3: ['', ''],
      number_2_day3: ['', ''],
      ivrMenu_2_day3: ['', ''],
      sipAdd_2_day3: ['', ''],

      PrimaryforwardType_3_day3: ['', ''],
      number_3_day3: ['', ''],
      ivrMenu_3_day3: ['', ''],
      sipAdd_3_day3: ['', ''],

      PrimaryforwardType_4_day3: ['', ''],
      number_4_day3: ['', ''],
      ivrMenu_4_day3: ['', ''],
      sipAdd_4_day3: ['', ''],

      PrimaryforwardType_5_day3: ['', ''],
      number_5_day3: ['', ''],
      ivrMenu_5_day3: ['', ''],
      sipAdd_5_day3: ['', ''],

      PrimaryforwardType_6_day3: ['', ''],
      number_6_day3: ['', ''],
      ivrMenu_6_day3: ['', ''],
      sipAdd_6_day3: ['', ''],

      PrimaryforwardType_7_day3: ['', ''],
      number_7_day3: ['', ''],
      ivrMenu_7_day3: ['', ''],
      sipAdd_7_day3: ['', ''],

      PrimaryforwardType_8_day3: ['', ''],
      number_8_day3: ['', ''],
      ivrMenu_8_day3: ['', ''],
      sipAdd_8_day3: ['', ''],

      PrimaryforwardType_9_day3: ['', ''],
      number_9_day3: ['', ''],
      ivrMenu_9_day3: ['', ''],
      sipAdd_9_day3: ['', ''],

      // Day5
      day_4: ['', ''],
      start_hrs_4: ['', ''],
      start_min_4: ['', ''],
      end_hrs_4: ['', ''],
      end_min_4: ['', ''],

      PrimaryforwardType_0_day4: ['', ''],
      number_0_day4: ['', ''],
      ivrMenu_0_day4: ['', ''],
      sipAdd_0_day4: ['', ''],

      PrimaryforwardType_1_day4: ['', ''],
      number_1_day4: ['', ''],
      ivrMenu_1_day4: ['', ''],
      sipAdd_1_day4: ['', ''],

      PrimaryforwardType_2_day4: ['', ''],
      number_2_day4: ['', ''],
      ivrMenu_2_day4: ['', ''],
      sipAdd_2_day4: ['', ''],

      PrimaryforwardType_3_day4: ['', ''],
      number_3_day4: ['', ''],
      ivrMenu_3_day4: ['', ''],
      sipAdd_3_day4: ['', ''],

      PrimaryforwardType_4_day4: ['', ''],
      number_4_day4: ['', ''],
      ivrMenu_4_day4: ['', ''],
      sipAdd_4_day4: ['', ''],

      PrimaryforwardType_5_day4: ['', ''],
      number_5_day4: ['', ''],
      ivrMenu_5_day4: ['', ''],
      sipAdd_5_day4: ['', ''],

      PrimaryforwardType_6_day4: ['', ''],
      number_6_day4: ['', ''],
      ivrMenu_6_day4: ['', ''],
      sipAdd_6_day4: ['', ''],

      PrimaryforwardType_7_day4: ['', ''],
      number_7_day4: ['', ''],
      ivrMenu_7_day4: ['', ''],
      sipAdd_7_day4: ['', ''],

      PrimaryforwardType_8_day4: ['', ''],
      number_8_day4: ['', ''],
      ivrMenu_8_day4: ['', ''],
      sipAdd_8_day4: ['', ''],

      PrimaryforwardType_9_day4: ['', ''],
      number_9_day4: ['', ''],
      ivrMenu_9_day4: ['', ''],
      sipAdd_9_day4: ['', ''],

      // Day6
      day_5: ['', ''],
      start_hrs_5: ['', ''],
      start_min_5: ['', ''],
      end_hrs_5: ['', ''],
      end_min_5: ['', ''],

      PrimaryforwardType_0_day5: ['', ''],
      number_0_day5: ['', ''],
      ivrMenu_0_day5: ['', ''],
      sipAdd_0_day5: ['', ''],

      PrimaryforwardType_1_day5: ['', ''],
      number_1_day5: ['', ''],
      ivrMenu_1_day5: ['', ''],
      sipAdd_1_day5: ['', ''],

      PrimaryforwardType_2_day5: ['', ''],
      number_2_day5: ['', ''],
      ivrMenu_2_day5: ['', ''],
      sipAdd_2_day5: ['', ''],

      PrimaryforwardType_3_day5: ['', ''],
      number_3_day5: ['', ''],
      ivrMenu_3_day5: ['', ''],
      sipAdd_3_day5: ['', ''],

      PrimaryforwardType_4_day5: ['', ''],
      number_4_day5: ['', ''],
      ivrMenu_4_day5: ['', ''],
      sipAdd_4_day5: ['', ''],

      PrimaryforwardType_5_day5: ['', ''],
      number_5_day5: ['', ''],
      ivrMenu_5_day5: ['', ''],
      sipAdd_5_day5: ['', ''],

      PrimaryforwardType_6_day5: ['', ''],
      number_6_day5: ['', ''],
      ivrMenu_6_day5: ['', ''],
      sipAdd_6_day5: ['', ''],

      PrimaryforwardType_7_day5: ['', ''],
      number_7_day5: ['', ''],
      ivrMenu_7_day5: ['', ''],
      sipAdd_7_day5: ['', ''],

      PrimaryforwardType_8_day5: ['', ''],
      number_8_day5: ['', ''],
      ivrMenu_8_day5: ['', ''],
      sipAdd_8_day5: ['', ''],

      PrimaryforwardType_9_day5: ['', ''],
      number_9_day5: ['', ''],
      ivrMenu_9_day5: ['', ''],
      sipAdd_9_day5: ['', ''],


      // Day7
      day_6: ['', ''],
      start_hrs_6: ['', ''],
      start_min_6: ['', ''],
      end_hrs_6: ['', ''],
      end_min_6: ['', ''],

      PrimaryforwardType_0_day6: ['', ''],
      number_0_day6: ['', ''],
      ivrMenu_0_day6: ['', ''],
      sipAdd_0_day6: ['', ''],

      PrimaryforwardType_1_day6: ['', ''],
      number_1_day6: ['', ''],
      ivrMenu_1_day6: ['', ''],
      sipAdd_1_day6: ['', ''],

      PrimaryforwardType_2_day6: ['', ''],
      number_2_day6: ['', ''],
      ivrMenu_2_day6: ['', ''],
      sipAdd_2_day6: ['', ''],

      PrimaryforwardType_3_day6: ['', ''],
      number_3_day6: ['', ''],
      ivrMenu_3_day6: ['', ''],
      sipAdd_3_day6: ['', ''],

      PrimaryforwardType_4_day6: ['', ''],
      number_4_day6: ['', ''],
      ivrMenu_4_day6: ['', ''],
      sipAdd_4_day6: ['', ''],

      PrimaryforwardType_5_day6: ['', ''],
      number_5_day6: ['', ''],
      ivrMenu_5_day6: ['', ''],
      sipAdd_5_day6: ['', ''],

      PrimaryforwardType_6_day6: ['', ''],
      number_6_day6: ['', ''],
      ivrMenu_6_day6: ['', ''],
      sipAdd_6_day6: ['', ''],

      PrimaryforwardType_7_day6: ['', ''],
      number_7_day6: ['', ''],
      ivrMenu_7_day6: ['', ''],
      sipAdd_7_day6: ['', ''],

      PrimaryforwardType_8_day6: ['', ''],
      number_8_day6: ['', ''],
      ivrMenu_8_day6: ['', ''],
      sipAdd_8_day6: ['', ''],

      PrimaryforwardType_9_day6: ['', ''],
      number_9_day6: ['', ''],
      ivrMenu_9_day6: ['', ''],
      sipAdd_9_day6: ['', ''],
    });

    this.editFSLRules = this.fb.group({
      rulename: ['', ''],
      numberoftimeframe: ['', Validators.required],
      noofforwardinglocation_1: ['', ''],
      noofforwardinglocation_2: ['', ''],
      noofforwardinglocation_3: ['', ''],
      noofforwardinglocation_4: ['', ''],
      noofforwardinglocation_5: ['', ''],
      noofforwardinglocation_6: ['', ''],
      noofforwardinglocation_7: ['', ''],

      // Day 1
      day_0: ['', ''],
      start_hrs_0: ['', ''],
      start_min_0: ['', ''],
      end_hrs_0: ['', ''],
      end_min_0: ['', ''],

      PrimaryforwardType_0_day0: ['', ''],
      number_0_day0: ['', ''],
      ivrMenu_0_day0: ['', ''],
      sipAdd_0_day0: ['', ''],

      PrimaryforwardType_1_day0: ['', ''],
      number_1_day0: ['', ''],
      ivrMenu_1_day0: ['', ''],
      sipAdd_1_day0: ['', ''],

      PrimaryforwardType_2_day0: ['', ''],
      number_2_day0: ['', ''],
      ivrMenu_2_day0: ['', ''],
      sipAdd_2_day0: ['', ''],

      PrimaryforwardType_3_day0: ['', ''],
      number_3_day0: ['', ''],
      ivrMenu_3_day0: ['', ''],
      sipAdd_3_day0: ['', ''],

      PrimaryforwardType_4_day0: ['', ''],
      number_4_day0: ['', ''],
      ivrMenu_4_day0: ['', ''],
      sipAdd_4_day0: ['', ''],

      PrimaryforwardType_5_day0: ['', ''],
      number_5_day0: ['', ''],
      ivrMenu_5_day0: ['', ''],
      sipAdd_5_day0: ['', ''],

      PrimaryforwardType_6_day0: ['', ''],
      number_6_day0: ['', ''],
      ivrMenu_6_day0: ['', ''],
      sipAdd_6_day0: ['', ''],

      PrimaryforwardType_7_day0: ['', ''],
      number_7_day0: ['', ''],
      ivrMenu_7_day0: ['', ''],
      sipAdd_7_day0: ['', ''],

      PrimaryforwardType_8_day0: ['', ''],
      number_8_day0: ['', ''],
      ivrMenu_8_day0: ['', ''],
      sipAdd_8_day0: ['', ''],

      PrimaryforwardType_9_day0: ['', ''],
      number_9_day0: ['', ''],
      ivrMenu_9_day0: ['', ''],
      sipAdd_9_day0: ['', ''],

      // Day2
      day_1: ['', ''],
      start_hrs_1: ['', ''],
      start_min_1: ['', ''],
      end_hrs_1: ['', ''],
      end_min_1: ['', ''],

      PrimaryforwardType_0_day1: ['', ''],
      number_0_day1: ['', ''],
      ivrMenu_0_day1: ['', ''],
      sipAdd_0_day1: ['', ''],

      PrimaryforwardType_1_day1: ['', ''],
      number_1_day1: ['', ''],
      ivrMenu_1_day1: ['', ''],
      sipAdd_1_day1: ['', ''],

      PrimaryforwardType_2_day1: ['', ''],
      number_2_day1: ['', ''],
      ivrMenu_2_day1: ['', ''],
      sipAdd_2_day1: ['', ''],

      PrimaryforwardType_3_day1: ['', ''],
      number_3_day1: ['', ''],
      ivrMenu_3_day1: ['', ''],
      sipAdd_3_day1: ['', ''],

      PrimaryforwardType_4_day1: ['', ''],
      number_4_day1: ['', ''],
      ivrMenu_4_day1: ['', ''],
      sipAdd_4_day1: ['', ''],

      PrimaryforwardType_5_day1: ['', ''],
      number_5_day1: ['', ''],
      ivrMenu_5_day1: ['', ''],
      sipAdd_5_day1: ['', ''],

      PrimaryforwardType_6_day1: ['', ''],
      number_6_day1: ['', ''],
      ivrMenu_6_day1: ['', ''],
      sipAdd_6_day1: ['', ''],

      PrimaryforwardType_7_day1: ['', ''],
      number_7_day1: ['', ''],
      ivrMenu_7_day1: ['', ''],
      sipAdd_7_day1: ['', ''],

      PrimaryforwardType_8_day1: ['', ''],
      number_8_day1: ['', ''],
      ivrMenu_8_day1: ['', ''],
      sipAdd_8_day1: ['', ''],

      PrimaryforwardType_9_day1: ['', ''],
      number_9_day1: ['', ''],
      ivrMenu_9_day1: ['', ''],
      sipAdd_9_day1: ['', ''],

      // Day3
      day_2: ['', ''],
      start_hrs_2: ['', ''],
      start_min_2: ['', ''],
      end_hrs_2: ['', ''],
      end_min_2: ['', ''],

      PrimaryforwardType_0_day2: ['', ''],
      number_0_day2: ['', ''],
      ivrMenu_0_day2: ['', ''],
      sipAdd_0_day2: ['', ''],

      PrimaryforwardType_1_day2: ['', ''],
      number_1_day2: ['', ''],
      ivrMenu_1_day2: ['', ''],
      sipAdd_1_day2: ['', ''],

      PrimaryforwardType_2_day2: ['', ''],
      number_2_day2: ['', ''],
      ivrMenu_2_day2: ['', ''],
      sipAdd_2_day2: ['', ''],

      PrimaryforwardType_3_day2: ['', ''],
      number_3_day2: ['', ''],
      ivrMenu_3_day2: ['', ''],
      sipAdd_3_day2: ['', ''],

      PrimaryforwardType_4_day2: ['', ''],
      number_4_day2: ['', ''],
      ivrMenu_4_day2: ['', ''],
      sipAdd_4_day2: ['', ''],

      PrimaryforwardType_5_day2: ['', ''],
      number_5_day2: ['', ''],
      ivrMenu_5_day2: ['', ''],
      sipAdd_5_day2: ['', ''],

      PrimaryforwardType_6_day2: ['', ''],
      number_6_day2: ['', ''],
      ivrMenu_6_day2: ['', ''],
      sipAdd_6_day2: ['', ''],

      PrimaryforwardType_7_day2: ['', ''],
      number_7_day2: ['', ''],
      ivrMenu_7_day2: ['', ''],
      sipAdd_7_day2: ['', ''],

      PrimaryforwardType_8_day2: ['', ''],
      number_8_day2: ['', ''],
      ivrMenu_8_day2: ['', ''],
      sipAdd_8_day2: ['', ''],

      PrimaryforwardType_9_day2: ['', ''],
      number_9_day2: ['', ''],
      ivrMenu_9_day2: ['', ''],
      sipAdd_9_day2: ['', ''],

      // Day4
      day_3: ['', ''],
      start_hrs_3: ['', ''],
      start_min_3: ['', ''],
      end_hrs_3: ['', ''],
      end_min_3: ['', ''],

      PrimaryforwardType_0_day3: ['', ''],
      number_0_day3: ['', ''],
      ivrMenu_0_day3: ['', ''],
      sipAdd_0_day3: ['', ''],

      PrimaryforwardType_1_day3: ['', ''],
      number_1_day3: ['', ''],
      ivrMenu_1_day3: ['', ''],
      sipAdd_1_day3: ['', ''],

      PrimaryforwardType_2_day3: ['', ''],
      number_2_day3: ['', ''],
      ivrMenu_2_day3: ['', ''],
      sipAdd_2_day3: ['', ''],

      PrimaryforwardType_3_day3: ['', ''],
      number_3_day3: ['', ''],
      ivrMenu_3_day3: ['', ''],
      sipAdd_3_day3: ['', ''],

      PrimaryforwardType_4_day3: ['', ''],
      number_4_day3: ['', ''],
      ivrMenu_4_day3: ['', ''],
      sipAdd_4_day3: ['', ''],

      PrimaryforwardType_5_day3: ['', ''],
      number_5_day3: ['', ''],
      ivrMenu_5_day3: ['', ''],
      sipAdd_5_day3: ['', ''],

      PrimaryforwardType_6_day3: ['', ''],
      number_6_day3: ['', ''],
      ivrMenu_6_day3: ['', ''],
      sipAdd_6_day3: ['', ''],

      PrimaryforwardType_7_day3: ['', ''],
      number_7_day3: ['', ''],
      ivrMenu_7_day3: ['', ''],
      sipAdd_7_day3: ['', ''],

      PrimaryforwardType_8_day3: ['', ''],
      number_8_day3: ['', ''],
      ivrMenu_8_day3: ['', ''],
      sipAdd_8_day3: ['', ''],

      PrimaryforwardType_9_day3: ['', ''],
      number_9_day3: ['', ''],
      ivrMenu_9_day3: ['', ''],
      sipAdd_9_day3: ['', ''],

      // Day5
      day_4: ['', ''],
      start_hrs_4: ['', ''],
      start_min_4: ['', ''],
      end_hrs_4: ['', ''],
      end_min_4: ['', ''],

      PrimaryforwardType_0_day4: ['', ''],
      number_0_day4: ['', ''],
      ivrMenu_0_day4: ['', ''],
      sipAdd_0_day4: ['', ''],

      PrimaryforwardType_1_day4: ['', ''],
      number_1_day4: ['', ''],
      ivrMenu_1_day4: ['', ''],
      sipAdd_1_day4: ['', ''],

      PrimaryforwardType_2_day4: ['', ''],
      number_2_day4: ['', ''],
      ivrMenu_2_day4: ['', ''],
      sipAdd_2_day4: ['', ''],

      PrimaryforwardType_3_day4: ['', ''],
      number_3_day4: ['', ''],
      ivrMenu_3_day4: ['', ''],
      sipAdd_3_day4: ['', ''],

      PrimaryforwardType_4_day4: ['', ''],
      number_4_day4: ['', ''],
      ivrMenu_4_day4: ['', ''],
      sipAdd_4_day4: ['', ''],

      PrimaryforwardType_5_day4: ['', ''],
      number_5_day4: ['', ''],
      ivrMenu_5_day4: ['', ''],
      sipAdd_5_day4: ['', ''],

      PrimaryforwardType_6_day4: ['', ''],
      number_6_day4: ['', ''],
      ivrMenu_6_day4: ['', ''],
      sipAdd_6_day4: ['', ''],

      PrimaryforwardType_7_day4: ['', ''],
      number_7_day4: ['', ''],
      ivrMenu_7_day4: ['', ''],
      sipAdd_7_day4: ['', ''],

      PrimaryforwardType_8_day4: ['', ''],
      number_8_day4: ['', ''],
      ivrMenu_8_day4: ['', ''],
      sipAdd_8_day4: ['', ''],

      PrimaryforwardType_9_day4: ['', ''],
      number_9_day4: ['', ''],
      ivrMenu_9_day4: ['', ''],
      sipAdd_9_day4: ['', ''],

      // Day6
      day_5: ['', ''],
      start_hrs_5: ['', ''],
      start_min_5: ['', ''],
      end_hrs_5: ['', ''],
      end_min_5: ['', ''],

      PrimaryforwardType_0_day5: ['', ''],
      number_0_day5: ['', ''],
      ivrMenu_0_day5: ['', ''],
      sipAdd_0_day5: ['', ''],

      PrimaryforwardType_1_day5: ['', ''],
      number_1_day5: ['', ''],
      ivrMenu_1_day5: ['', ''],
      sipAdd_1_day5: ['', ''],

      PrimaryforwardType_2_day5: ['', ''],
      number_2_day5: ['', ''],
      ivrMenu_2_day5: ['', ''],
      sipAdd_2_day5: ['', ''],

      PrimaryforwardType_3_day5: ['', ''],
      number_3_day5: ['', ''],
      ivrMenu_3_day5: ['', ''],
      sipAdd_3_day5: ['', ''],

      PrimaryforwardType_4_day5: ['', ''],
      number_4_day5: ['', ''],
      ivrMenu_4_day5: ['', ''],
      sipAdd_4_day5: ['', ''],

      PrimaryforwardType_5_day5: ['', ''],
      number_5_day5: ['', ''],
      ivrMenu_5_day5: ['', ''],
      sipAdd_5_day5: ['', ''],

      PrimaryforwardType_6_day5: ['', ''],
      number_6_day5: ['', ''],
      ivrMenu_6_day5: ['', ''],
      sipAdd_6_day5: ['', ''],

      PrimaryforwardType_7_day5: ['', ''],
      number_7_day5: ['', ''],
      ivrMenu_7_day5: ['', ''],
      sipAdd_7_day5: ['', ''],

      PrimaryforwardType_8_day5: ['', ''],
      number_8_day5: ['', ''],
      ivrMenu_8_day5: ['', ''],
      sipAdd_8_day5: ['', ''],

      PrimaryforwardType_9_day5: ['', ''],
      number_9_day5: ['', ''],
      ivrMenu_9_day5: ['', ''],
      sipAdd_9_day5: ['', ''],


      // Day7
      day_6: ['', ''],
      start_hrs_6: ['', ''],
      start_min_6: ['', ''],
      end_hrs_6: ['', ''],
      end_min_6: ['', ''],

      PrimaryforwardType_0_day6: ['', ''],
      number_0_day6: ['', ''],
      ivrMenu_0_day6: ['', ''],
      sipAdd_0_day6: ['', ''],

      PrimaryforwardType_1_day6: ['', ''],
      number_1_day6: ['', ''],
      ivrMenu_1_day6: ['', ''],
      sipAdd_1_day6: ['', ''],

      PrimaryforwardType_2_day6: ['', ''],
      number_2_day6: ['', ''],
      ivrMenu_2_day6: ['', ''],
      sipAdd_2_day6: ['', ''],

      PrimaryforwardType_3_day6: ['', ''],
      number_3_day6: ['', ''],
      ivrMenu_3_day6: ['', ''],
      sipAdd_3_day6: ['', ''],

      PrimaryforwardType_4_day6: ['', ''],
      number_4_day6: ['', ''],
      ivrMenu_4_day6: ['', ''],
      sipAdd_4_day6: ['', ''],

      PrimaryforwardType_5_day6: ['', ''],
      number_5_day6: ['', ''],
      ivrMenu_5_day6: ['', ''],
      sipAdd_5_day6: ['', ''],

      PrimaryforwardType_6_day6: ['', ''],
      number_6_day6: ['', ''],
      ivrMenu_6_day6: ['', ''],
      sipAdd_6_day6: ['', ''],

      PrimaryforwardType_7_day6: ['', ''],
      number_7_day6: ['', ''],
      ivrMenu_7_day6: ['', ''],
      sipAdd_7_day6: ['', ''],

      PrimaryforwardType_8_day6: ['', ''],
      number_8_day6: ['', ''],
      ivrMenu_8_day6: ['', ''],
      sipAdd_8_day6: ['', ''],

      PrimaryforwardType_9_day6: ['', ''],
      number_9_day6: ['', ''],
      ivrMenu_9_day6: ['', ''],
      sipAdd_9_day6: ['', ''],
    });
    this.showLoadingIndicator= false;
    this.dropdownSettings = {
      singleSelection: false,
      text:"Select Associated Label",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      classes:"myclass custom-class"
    };


    this.ivrdropdownSettings = {
      singleSelection: true,
      text:"Select IVR Menu",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      classes:"myclass custom-class"
    };
    // this.getAllLabels();
    this.pageTitle = 'Edit Target';
  }




currtargetname:any=[];
currtargetnumber:any=[];
timezoneval:any=[];
isfilter:any =0;
isfilterselect(){
  this.isfilter=1;
  this.editForm.get('isfilter').setValue(this.isfilter);
}
isfilterclear(){
  this.isfilter=0;
  this.editForm.get('citystatetype').setValue(1);
  this.editForm.get('citystate').setValue('');
  this.editForm.get('cityval').setValue('');
}

ispubfilter:any =0;
ispubfilterselect(){
  this.ispubfilter=1;
  this.editForm.get('ispubfilter').setValue(this.ispubfilter);
}

ispubfilterclear(){
  this.filterselected=[];
  this.ispubfilter=0;
  this.editForm.get('ispubfilter').setValue(0);
  this.editForm.get('pubiscontains').setValue(0);
  this.editForm.get('pubvalue').setValue('');
}

filterselected:any = [];
addorrule(){
  let typeval = this.editForm.get('pubiscontains').value;
  let typetext;
  if(typeval==0){
    typetext = "Equals Single Value";
  }else if(typeval==1){
    typetext = "Does Not Equal Single Value";
  }else if(typeval==2){
    typetext = "Contains";
  }else if(typeval==3){
    typetext = "Does Not contain";
  }else if(typeval==4){
    typetext = "Begins With";
  }else{
    typetext = "Does Not Begin With";
  }
  let customObj = new filterarr();
  customObj.filteradvanced =typeval;
  customObj.filterlabel = typetext;
  if(this.editForm.get('pubfiltertype').value==1){
    customObj.filtertype = 1;
    customObj.containfiltertext = 'Publisher';
  }
  if(this.editForm.get('pubfiltertype').value==2){
    customObj.filtertype = 2;
    customObj.containfiltertext = 'Zipcode';
  }
  customObj.containfiltertype = this.editForm.get('pubfiltertype').value;

  let itemname;
  let itemuuid;
  if((typeval==0 || typeval==1)){
    if(this.editForm.get('pubvalue').value!=undefined && this.editForm.get('pubvalue').value!=''){
      itemuuid = this.editForm.get('pubvalue').value[0]['id'];
      itemname = this.editForm.get('pubvalue').value[0]['itemName']
    }else{
      itemname = "";
      itemuuid = "";
    }
  }else{
    itemname = this.editForm.get('pubvalue').value;
    itemuuid = "";
  }
  // console.log(this.editForm.get('pubvalue').value);
  // console.log(itemname); return
  if(itemname=="") return

  customObj.uuid = itemuuid;
  customObj.name = itemname;
  customObj.isorand = 0;
  if(itemname!=undefined){
    this.filterselected.push(customObj);
  }
  // console.log(this.filterselected);
}

addandrule(){
  let typeval = this.editForm.get('pubiscontains').value;
  let typetext;
  if(typeval==0){
    typetext = "Equals Single Value";
  }else if(typeval==1){
    typetext = "Does Not Equal Single Value";
  }else if(typeval==2){
    typetext = "Contains";
  }else if(typeval==3){
    typetext = "Does Not contain";
  }else if(typeval==4){
    typetext = "Begins With";
  }else{
    typetext = "Does Not Begin With";
  }
  let customObj = new filterarr();
  customObj.filteradvanced =typeval;
  customObj.filterlabel = typetext;
  if(this.editForm.get('pubfiltertype').value==1){
    customObj.filtertype = 1;
    customObj.containfiltertext = 'Publisher';
  }
  if(this.editForm.get('pubfiltertype').value==2){
    customObj.filtertype = 2;
    customObj.containfiltertext = 'Zipcode';
  }
  customObj.containfiltertype = this.editForm.get('pubfiltertype').value;

  let itemname;
  let itemuuid;
  if((typeval==0 || typeval==1)){
    if(this.editForm.get('pubvalue').value!=undefined && this.editForm.get('pubvalue').value!=''){
      itemuuid = this.editForm.get('pubvalue').value[0]['id'];
      itemname = this.editForm.get('pubvalue').value[0]['itemName']
    }else{
      itemname = "";
      itemuuid = "";
    }
  }else{
    itemname = this.editForm.get('pubvalue').value;
    itemuuid = "";
  }
  if(itemname=="") return
  customObj.uuid = itemuuid;
  customObj.name = itemname;
  customObj.isorand = 1;
  if(itemname!=undefined){
    this.filterselected.push(customObj);
  }
}

getallbuyers(){
  this.moduleService.getallbuyers(this.currentCompany.uuid).subscribe(
    result => {
      if(result.length>0){
      let text =  result.filter(x => x.itemName == this.company)[0];
      if(text!=undefined){
        this.selectedItemsBuyer1 = [{"id":text.id,"itemName":text.text}];
      }
      }
      this.allBuyers = result
    },
    error => this.error = error
  );
}

allzipcode:any=[];
selectedItemsZip = [];
  getallzipcode(uuid:any){
    this.moduleService.getallzipcode(this.currentCompany.uuid,uuid).subscribe(
      result => {
        if(result.length>0){
        this.allzipcode = result
        let text =  this.allzipcode.filter(x => x.name == this.filename)[0];
        console.log(text);

          if(text!=undefined){
          //   this.selectedItemsZip = [{"uuid":text.id,"ItemName":text.itemName}];
          this.selectedItemsZip = [{"id":text.id,"itemName":text.itemName}];
          }
        }
        // console.log(this.selectedItemsZip);

      },
      error => this.error = error
    );
  }

changetype(event){
  // console.log(event.target.value);
  this.editForm.get('citystatetype').setValue(event.target.value);
}
company:any=""
ivrmenus : any;
getalldata(){
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  const p1 = new Promise((res,rej)=>{
    this.moduleService.getIVRMenus(this.currentCompany.uuid).subscribe(
      result => {
        res(result)
      },
      error => this.error = error
    );
  })

  const p2 = new Promise((res,rej)=>{
    this.moduleService.getallbuyers(this.currentCompany.uuid).subscribe(
      result => {
        res(result)
      },
      error => this.error = error
    );
  })

  const p3 = new Promise((res,rej)=>{
    this.moduleService.gettimezone().subscribe(
      result => {
        res(result)
      },
      error => this.error = error
    );
  })

  const p4 = new Promise((res,rej)=>{
    const uuid = this.route.snapshot.paramMap.get('id');
    this.moduleService.getallzipcode(this.currentCompany.uuid, uuid  ).subscribe(
      result => {
        res(result)
      },
      error => this.error = error
    );
  })

  
  const prm = [p1, p2, p3, p4];
  Promise.all(prm).
      then((results) =>{
        this.allBuyers = results[1];
        this.ivrmenus = results[0];
        this.alltimezone = results[2];
        this.allzipcode = results[3];
        
        // console.log(this.ivrmenus);return
        const uuid = this.route.snapshot.paramMap.get('id');
        this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
        if (uuid) {
          this.moduleService.getTargetdata(uuid,this.currentCompany.uuid).subscribe(
            res => {
              // console.log(res[0]?.name);
              this.currtargetname = res[0]?.name;
              this.currtargetnumber = res[0]?.number;
              if(this.currentCompany.uuid != res[0].company_uuid){
                Swal.fire({
                  title:'Error!',
                  html:'This target is assigned to another company.',
                  icon:'warning',
                  timer:2000
                }
                )
                this.router.navigate(['/target']);
              }
              this.setTitle(res[0].name+' | Target')

              if(res[0].buyer_id!="" && res[0].buyer_id!=undefined && res[0].buyer_id.length>0){
                let text =  this.allBuyers.filter(x => x.id == res[0].buyer_id)[0];
                if(text!=undefined){
                  this.selectedItemsBuyer1 = [{"id":res[0].buyer_id,"itemName":text.text}];
                }
              }
              this.selectedItems = res[0].assosciated_labels;
              if(res[0].max_concurrency==1){
                this.maxconcurrency = true;
              }else{
                this.maxconcurrency = false;
              }
              if(res[0].global_revenuecap==1){
                this.global_revenuecap = true;
              }else{
                this.global_revenuecap = false;
              }
              if(res[0].is_autodtmf==1){
                this.dtmf_auto = true;
              }else{
                this.dtmf_auto = false;
              }
              if(res[0].dtmf_zipcode_enable==1){
                this.dtmf_zipcode_enable = true;
              }else{
                this.dtmf_zipcode_enable = false;
              }
              if(res[0].monthly_revenuecap==1){
                this.monthly_revenuecap = true;
              }else{
                this.monthly_revenuecap = false;
              }
              if(res[0].daily_revenuecap==1){
                this.daily_revenuecap = true;
              }else{
                this.daily_revenuecap = false;
              }
              if(res[0].hourly_revenuecap==1){
                this.hourly_revenuecap = true;
              }else{
                this.hourly_revenuecap = false;
              }

              if(res[0].disableRecordings==1){
                this.disableRecordings = true;
              }else{
                this.disableRecordings = false;
              }

              this.advanced=res[0].isadded == 1 ?'Advanced':"Basic";
              this.is12or24 = res[0].is12or24;

              if(res[0].is_ringtree==1){
                this.ringtreetext = "Ringtree";
                this.ringtree = true;
                if(res[0].keywordlength==1){
                  this.addkeyword = [0];
                }
                if(res[0].keywordlength==2){
                  this.addkeyword = [0,1];
                }
                if(res[0].keywordlength==3){
                  this.addkeyword = [0,1,2];
                }

              }else{
                this.ringtreetext = "Simple";
                this.ringtree = false;
              }

              var days = res[0].hoursofoperationdays.split('=');
              var days_1 = res[0].hoursofoperationdays_1.split('=');
              this.settingToggleTypeval = res[0].settingToggleType;
              this.editForm.get('settingToggleType').setValue(res[0].settingToggleType);

              let starttimearray;
              let endtimearray;
              let defaultsampm="0";
              let defaulteampm="1";
              if(res[0].is12or24==1){
                let time1 = this.convert12hrs(res[0].start_time).substring(0,2);
                let time2 = this.convert12hrs(res[0].start_time).substring(3,5);
                let sampmval = this.convert12hrs(res[0].start_time).substring(6,8);
                if(sampmval=='AM'){defaultsampm="0"} else {defaultsampm="1"}
                starttimearray = [time1,time2]

                let etime1 = this.convert12hrs(res[0].end_time).substring(0,2);
                let etime2 = this.convert12hrs(res[0].end_time).substring(3,5);
                let eampmval = this.convert12hrs(res[0].end_time).substring(6,8);
                if(eampmval=='AM'){defaulteampm="0"} else {defaulteampm="1"}
                endtimearray = [etime1,etime2]
              }else{
                starttimearray = res[0].start_time.split(':');
                endtimearray = res[0].end_time.split(':');
              }

              var starttimearray_1 = res[0].start_time_1.split(':');
              var endtimearray_1 = res[0].end_time_1.split(':');

              this.isadded = res[0].isadded;
              // console.log(res[0].ivrmenu_uuid);
              // console.log('ok');
              if(res[0].ivrmenu_arr!=""){
                  let text =  this.ivrmenus.filter(x => x.uuid == JSON.parse(res[0].ivrmenu_arr)[0]['uuid'])[0];
                  if(text!=undefined){
                    this.selected_ivr = [{"uuid":text.uuid,"id":text.id,"itemName":text.name}];
                  }
                  // console.log(JSON.parse(res[0].ivrmenu_arr));

                // this.editForm.patchValue({
                //   ivrMenu: JSON.parse(res[0].ivrmenu_arr)[0]['uuid'],
                // });
              }

            let http_headers_name;
            let http_headers_value;
            if(res[0].http_headers){
                let header = JSON.parse(res[0].http_headers);
                if(header){
                  for (var key in header) {
                    http_headers_name = key;
                    http_headers_value = header[key];
                  }
                }
              }
              this.methodtypeval = res[0].http_method;

              this.timeonehrs = starttimearray[0];
              this.timeonemin = starttimearray[1];
              this.timetwohrs = endtimearray[0];
              this.timetwomin = endtimearray[1];

              this.timeonehrs1 = starttimearray_1[0];
              this.timeonemin1 = starttimearray_1[1];
              this.timetwohrs1 = endtimearray_1[0];
              this.timetwomin1 = endtimearray_1[1];

                // console.log(res[0]);
                
              this.globalcap = res[0].global_cap=="1"?true:false;
              this.monthlycap = res[0].monthly_cap=="1"?true:false;
              this.dailycap = res[0].daily_cap=="1"?true:false;
              this.hourlycap = res[0].hourly_cap=="1"?true:false;

              if(res[0].isadded==1){
                // this.hof_alwaysopen = true;
                this.isadvanced = 1;
              }
              if(res[0].isadded==0){
                // this.hof_alwaysopen = true;
                this.isadvanced = 0;
              }

              if(res[0].hof_alwaysopen==1){
                this.hof_alwaysopen = true;
              }
              if(res[0].hof_alwaysopen==2){
                this.hof_alwaysopen = true;
              }

              if(res[0].jsondata && JSON.parse(res[0].jsondata).length>0){
                this.ispubfilter = 1;
                this.filterselected = JSON.parse(res[0].jsondata);
              }

              if((res[0].hrsofopedata).length>0){
                res[0].hrsofopedata.forEach((element,i) => {
                    let stime;
                    let etime;
                    let sampm="0";
                    let eampm="1";
                    if(res[0].is12or24==1){
                      let time1 = this.convert12hrs(element.open_time).substring(0,2);
                      let time2 = this.convert12hrs(element.open_time).substring(3,5);
                      let sampmval = this.convert12hrs(element.open_time).substring(6,8);
                      if(sampmval=='AM'){sampm="0"} else {sampm="1"}
                      stime = [time1,time2]

                      let etime1 = this.convert12hrs(element.close_time).substring(0,2);
                      let etime2 = this.convert12hrs(element.close_time).substring(3,5);
                      let eampmval = this.convert12hrs(element.close_time).substring(6,8);
                      if(eampmval=='AM'){eampm="0"} else {eampm="1"}
                      etime = [etime1,etime2]
                    }else{
                      stime = element.open_time.split(":");
                      etime = element.close_time.split(":");
                    }

                    if(element.day=="Sunday"){
                      this.sundayopen = Number(element.is_open);
                      this.editForm.patchValue({
                        start_hrs_sunday: stime[0],
                        start_min_sunday: stime[1],
                        end_hrs_sunday: etime[0],
                        end_min_sunday: etime[1],
                        sunday_ampm: sampm,
                        sunday_ampm_close: eampm,
                      });

                    }
                    if(element.day=="Monday"){
                      this.mondayopen = Number(element.is_open);
                      // const stime = element.open_time.split(":");
                      // const etime = element.close_time.split(":");
                      this.editForm.patchValue({
                        start_hrs_monday: stime[0],
                        start_min_monday: stime[1],
                        end_hrs_monday: etime[0],
                        end_min_monday: etime[1],
                        monday_ampm: sampm,
                        monday_ampm_close: eampm,
                      });
                    }
                    if(element.day=="Tuesday"){
                      this.tuesdayopen = Number(element.is_open);
                      // const stime = element.open_time.split(":");
                      // const etime = element.close_time.split(":");
                      this.editForm.patchValue({
                        start_hrs_tuesday: stime[0],
                        start_min_tuesday: stime[1],
                        end_hrs_tuesday: etime[0],
                        end_min_tuesday: etime[1],
                        tuesday_ampm: sampm,
                        tuesday_ampm_close: eampm,
                      });
                    }
                    if(element.day=="Wednesday"){
                      this.wednesdayopen = Number(element.is_open);
                      // const stime = element.open_time.split(":");
                      // const etime = element.close_time.split(":");
                      this.editForm.patchValue({
                        start_hrs_wednesday: stime[0],
                        start_min_wednesday: stime[1],
                        end_hrs_wednesday: etime[0],
                        end_min_wednesday: etime[1],
                        wednesday_ampm: sampm,
                        wednesday_ampm_close: eampm,
                      });
                    }
                    if(element.day=="Thursday"){
                      this.thursdayopen = Number(element.is_open);
                      // const stime = element.open_time.split(":");
                      // const etime = element.close_time.split(":");
                      this.editForm.patchValue({
                        start_hrs_thursday: stime[0],
                        start_min_thursday: stime[1],
                        end_hrs_thursday: etime[0],
                        end_min_thursday: etime[1],
                        thursday_ampm: sampm,
                        thursday_ampm_close: eampm,
                      });
                    }
                    if(element.day=="Friday"){
                      this.fridayopen = Number(element.is_open);
                      // const stime = element.open_time.split(":");
                      // const etime = element.close_time.split(":");
                      this.editForm.patchValue({
                        start_hrs_friday: stime[0],
                        start_min_friday: stime[1],
                        end_hrs_friday: etime[0],
                        end_min_friday: etime[1],
                        friday_ampm: sampm,
                        friday_ampm_close: eampm,
                      });
                    }
                    if(element.day=="Saturday"){
                      this.saturdayopen = Number(element.is_open);
                      // const stime = element.open_time.split(":");
                      // const etime = element.close_time.split(":");
                      this.editForm.patchValue({
                        start_hrs_saturday: stime[0],
                        start_min_saturday: stime[1],
                        end_hrs_saturday: etime[0],
                        end_min_saturday: etime[1],
                        saturday_ampm: sampm,
                        saturday_ampm_close: eampm,
                      });
                    }
                });
              }
              // console.log(res[0]);


              let text =  this.alltimezone.filter(x => x.id == res[0].timezone)[0];
              if(text!=undefined){
                this.timezoneval = [{"id":text.id,"itemName":text.text}];
              }

              this.editForm.patchValue({
                name: res[0].name,
                subid: res[0].subid,
                // buyer: res[0].buyer_id,
                number: res[0].number,
                // ivrMenu: JSON.parse(res[0].ivrmenu_arr),
                sip_address: res[0].sip_address,
                voice_mail: res[0].voice_mail,
                hoursofoperation_start: days[0],
                hoursofoperation_end: days[1],
                start_hrs: starttimearray[0],
                start_min: starttimearray[1],
                end_hrs: endtimearray[0],
                end_min: endtimearray[1],
                default_ampm:defaultsampm,
                default_ampm_close:defaulteampm,

                url: res[0].url,
                http_method: res[0].http_method,
                content_type: res[0].content_type,
                body: res[0].body,
                http_headers_name: http_headers_name,
                http_headers_value: http_headers_value,
                keyword0: res[0].keyword1,
                keyword1: res[0].keyword2,
                keyword2: res[0].keyword3,


                hoursofoperation_start_1: days_1[0],
                hoursofoperation_end_1: days_1[1],
                start_hrs_1: starttimearray_1[0],
                start_min_1: starttimearray_1[1],
                end_hrs_1: endtimearray_1[0],
                end_min_1: endtimearray_1[1],


                dtmf_value: res[0].dtmf_value,
                dtmf_interval: res[0].dtmf_interval,
                dtmf_percent: res[0].dtmf_percent,

                capon: res[0].capon,
                global_capvalue: res[0].global_capvalue,
                global_revenuecapvalue: res[0].global_revenuecapvalue,
                monthly_revenuecapvalue: res[0].monthly_revenuecapvalue,
                monthly_capvalue: res[0].monthly_capvalue,
                daily_revenuecap: res[0].daily_revenuecap,
                daily_revenuecapvalue: res[0].daily_revenuecapvalue,
                daily_capvalue: res[0].daily_capvalue,
                hourly_revenuecapvalue: res[0].hourly_revenuecapvalue,
                hourly_capvalue: res[0].hourly_capvalue,
                maxconvalue: res[0].max_concurrencyval,
                isfilter: res[0].isfilter,
                citystatetype: res[0].citystatetype,
                iscontains: res[0].iscontains,
                restrictdup: res[0].restrictdup,
                restrictafter: res[0].restrictafter,
                days: res[0].days,
                settingToggleDurationval: res[0].settingToggleDurationval,
                settingToggleDuration: res[0].settingToggleDuration,
              });
              this.settingToggleDurationvalue = res[0].settingToggleDurationval;
              if(res[0].isfilter==1){
              this.isfilter=1;
              if(res[0].citystatetype==0){
                var stateval = res[0].citystate.replace(/['"]+/g, '')
                this.editForm.patchValue({
                  citystate:stateval,
                  cityval: JSON.parse(res[0].cityval),
                });
                this.getdata();
              }
              if(res[0].citystatetype==1){
                this.editForm.patchValue({
                  citystate:JSON.parse(res[0].citystate),
                });
                this.getdata();
              }
            }
            this.ngxLoader.stopLoader('customLoaderId');
            }
          );

        }
  });

}

// getIVRMenu(){
//   this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
//   return new Promise((res,rej)=>{
//     this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
//     this.moduleService.getIVRMenus(this.currentCompany.uuid).subscribe(
//       result => {
//         res(result)
//       },
//       error => this.error = error
//     );
//   })
// }

// getAllRules(){
//   return new Promise((res,rej)=>{
//     this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
//     this.moduleService.getallbuyers(this.currentCompany.uuid).subscribe(
//       result => {
//         res(result)
//       },
//       error => this.error = error
//     );
//   })
// }

  get rules() { return this.addFSLRules.controls; }
  onFSLSubmit () {
    this.FSLRulesSubmitted = true;
    // stop here if form is invalid
    if (this.addFSLRules.invalid) {
        return;
    }
    // console.log(this.addFSLRules.get('start_time_0').value._d);
    //  return false;
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    // console.log(this.addFSLRules.value); return false;
    this.moduleService.addFSLRules(this.addFSLRules.value,this.currentCompany.uuid).subscribe(
      res => {
        // console.log(res);
        // return false;
        // localStorage.setItem('FSLRulesInsertID', JSON.stringify(res.insertid));
        this.addFSL();
        if (res.status !== 'error') {
          this.toastrService.success('FSL Rules Added Successfully');
        }else{
          this.toastrService.error('Failed To Add');
        }
      },
      error => this.error = error,
    );

  }

  BuyerSubmitted = false;
//Add Buyer
get buyercontrol() { return this.addBuyerForm.controls; }
onBuyerSubmit () {
    const formData = new FormData();
    this.BuyerSubmitted = true;
    if (this.addBuyerForm.invalid) {
        return;
    }

    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    formData.append('company_uuid', this.currentCompany.uuid);
    formData.append('company', this.addBuyerForm.get('company').value);
    formData.append('email', this.addBuyerForm.get('email').value);
    formData.append('password', this.addBuyerForm.get('password').value);
    formData.append('sub_id', this.addBuyerForm.get('sub_id').value);
    formData.append('allow_buyer_to_pause_target', this.addBuyerForm.get('allow_buyer_to_pause_target').value);
    formData.append('allow_buyer_to_set_target_concurrency', this.addBuyerForm.get('allow_buyer_to_set_target_concurrency').value);
    formData.append('allow_buyer_to_dispute_call_conversions', this.addBuyerForm.get('allow_buyer_to_dispute_call_conversions').value);
    formData.append('limit_revenue', this.addBuyerForm.get('limit_revenue').value);

    // console.log(formData); return false;

    this.moduleService.addBuyer(formData).subscribe(
      res => {
        // console.log(res); return false;
        if (res.status !== 'error') {
          this.toastrService.success('Buyer Added Successfully');
          // this.getAllRules();
          this.getallbuyers();

          this.BuyerSubmitted = false;

        }else{
          this.toastrService.error('Failed To Add Label');
        }
      },
      error => this.error = error,
    );
   }

   closeBuyerModal() {
    if(this.addBuyerForm.get('company').value)
    {
      $("#addBuyer").modal("hide");
      $(".custom-switch-input").prop('checked',false);
      this.BuyerSubmitted = false;
      setTimeout(() => { this.addBuyerForm.reset();}, 2000);
    }
  }



  filename:any = ""; //for headings
  filetype:any = ""; //for headings
  lines:any = []; //for headings
  linesR:any = []; // for rows
  selectFile(event) {
    // console.log(event.target.files);
    // this.selectedfile =  event.target.files.item(0);
    let file : File = event.target.files.item(0);
    this.filetype = file.type;
    // console.log(file.type); return


    if(file.type!='text/plain'){
      Swal.fire(
        'Error!',
        'Please select only TXT File.',
        'error',
      );
      return;
    }

    if(event) {
        // console.log(file.name);
        this.filename = file.name;
       //  console.log(file.size);
       //  console.log(file.type);
        //File reader method
        let reader: FileReader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {
         let csv: any = reader.result;
         let allTextLines = [];
         allTextLines = csv.split(/[\r\n]+/);
        //  console.log(allTextLines);


        //Table Headings
         let headers = allTextLines[0].split(',');
         let data = headers;
         let tarr = [];
         for (let j = 0; j < headers.length; j++) {
           tarr.push(data[j]);
         }
         //Pusd headings to array variable
         this.lines.push(tarr);


         // Table Rows
         let tarrR = [];

         let arrl = allTextLines.length;
         let rows = [];
         for(let i = 0; i < arrl; i++){
         rows.push(allTextLines[i].split(','));

         }

         for (let j = 0; j < arrl; j++) {

             tarrR.push(rows[j]);

         }
        //Push rows to array variable
         this.linesR.push(tarrR);
     }
   }
  //  console.log(this.linesR);

   this.selectedfiledata = this.linesR;
  }
  selectedfiledata:any = [];
  selectedfile: File | null = null;

  onZipSubmit () {
    if(this.filetype!='text/plain'){
      Swal.fire(
        'Error!',
        'Please select TXT File.',
        'error',
      );
      return;
    }
    this.closeZipModal();
    const formData = new FormData();
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    const uuid = this.route.snapshot.paramMap.get('id');
    formData.append('target_uuid', uuid);
    formData.append('company_uuid', this.currentCompany.uuid);
    formData.append('filename', this.filename);
    formData.append('file', JSON.stringify(this.selectedfiledata));

    this.moduleService.addZipcode(formData, this.currentCompany.uuid).subscribe(
      res => {
        // console.log(res); return false;
        if (res.status !== 'error') {
          this.toastrService.success('Zipcode Added Successfully');
          this.getallzipcode(uuid);
          this.BuyerSubmitted = false;

        }else{
          this.toastrService.error('Failed To Add Label');
        }
      },
      error => this.error = error,
    );
  }

  closeZipModal() {
      $("#addZipcode").modal("hide");
  }


  get editrules() { return this.editFSLRules.controls; }
  onFSLEditSubmit () {
    this.FSLRulesEditSubmitted = true;
    // stop here if form is invalid
    if (this.editFSLRules.invalid) {
        return;
    }
    this.editFSLRules.get('rulename').setValue(this.forwarding)
    // console.log(this.editFSLRules.value);
    // return false;
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    // console.log(this.currentCompany.uuid); return false;
    this.moduleService.editFSLRules(this.editFSLRules.value,this.currentCompany.uuid).subscribe(
      res => {
        // console.log(res);
        // return false;
        // localStorage.setItem('FSLRulesInsertID', JSON.stringify(res.insertid));
        if (res.status !== 'error') {
          this.toastrService.success('FSL Rules Updated Successfully');
        }else{
          this.toastrService.error('Failed To Add');
        }
      },
      error => this.error = error,
    );

  }

  settingToggleTypeval:number = 0;
  settingToggleType(val:number) {
    this.settingToggleTypeval = val;
    this.editForm.get('settingToggleType').setValue(val);
  }

  settingToggleDurationvalue:any = 0;
  settingToggleDuration(val:number) {
    this.settingToggleDurationvalue = val;
    this.editForm.get('settingToggleDurationval').setValue(val);
  }

  convert24hrs(time:any){
    return moment(time, 'h:mm A').format('HH:mm')
  }

  convert12hrs(time:any){
    return moment(time, 'h:mm A').format('hh:mm A')
  }

  get f() { return this.editForm.controls; }
  currentUser:any;
  onSubmit () {
    // console.log(this.ringtree); return false;
    const formData = new FormData();

    this.submitted = true;
    // stop here if form is invalid
    if (this.editForm.invalid) {
        return;
    }
    // if(this.timetwohrs<this.timeonehrs){
    //   this.getTimeerror()
    //   return;
    // }
    // if(this.timetwohrs==this.timeonehrs && this.timetwomin<=this.timeonemin){
    //   this.getTimeerror()
    //   return;
    // }
    // if(this.timetwohrs1<this.timeonehrs1){
    //   this.getTimeerror()
    //   return;
    // }
    // if(this.timetwohrs1==this.timeonehrs1 && this.timetwomin1<=this.timeonemin1){
    //   this.getTimeerror()
    //   return;
    // }

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    formData.append('user_uuid', this.currentUser.uuid);
    formData.append('company_uuid', this.currentCompany.uuid);
    formData.append('name', this.editForm.get('name').value);
    formData.append('subid', this.editForm.get('subid').value);
    formData.append('buyer', JSON.stringify(this.editForm.get('buyer').value));
    // formData.append('settingToggleBlockCallsIfCapped', this.editForm.get('settingToggleBlockCallsIfCapped').value);
    formData.append('settingToggleType', this.editForm.get('settingToggleType').value);
    if(this.settingToggleTypeval==0){
      formData.append('number', this.editForm.get('number').value);
    }
    if(this.settingToggleTypeval==1){
      formData.append('ivrMenu', JSON.stringify(this.editForm.get('ivrMenu').value));
    }
    if(this.settingToggleTypeval==2){
      formData.append('sip_address', this.editForm.get('sip_address').value);
    }
    if(this.settingToggleTypeval==3){
      formData.append('voice_mail', this.editForm.get('voice_mail').value);
    }
    formData.append('ringtree', this.ringtree);
    if(this.ringtree==1){
    formData.append('url', this.editForm.get('url').value);
    formData.append('http_method', this.editForm.get('http_method').value);
    formData.append('content_type', this.editForm.get('content_type').value);
    formData.append('body', this.editForm.get('body').value);
    formData.append('http_headers_name', this.editForm.get('http_headers_name').value);
    formData.append('http_headers_value', this.editForm.get('http_headers_value').value);
    // formData.append('keyword', this.editForm.get('keyword').value);
    formData.append('keywordlength', this.addkeyword.length);
    formData.append('keyword0', this.editForm.get('keyword0').value);
    formData.append('keyword1', this.editForm.get('keyword1').value);
    formData.append('keyword2', this.editForm.get('keyword2').value);
    }
    // formData.append('connection_timeout', this.editForm.get('connection_timeout').value);
    // formData.append('disableRecordings', this.disableRecordings);
    // formData.append('dialivroption', this.editForm.get('dialivroption').value);
    // formData.append('timezone', this.editForm.get('timezone').value);
    formData.append('start_day', this.editForm.get('hoursofoperation_start').value);
    formData.append('end_day', this.editForm.get('hoursofoperation_end').value);
    let tid="";
    // console.log(this.timezoneval); return

    if(this.timezoneval.length>0){
      tid = this.timezoneval[0].id;
    }
    formData.append('timezone', tid);
    // var starttime =  this.editForm.get('start_hrs').value+':'+this.editForm.get('start_min').value;
    // var endtime =  this.editForm.get('end_hrs').value+':'+this.editForm.get('end_min').value;

    var starttime;
    var endtime
    let default_ampm = this.editForm.get('default_ampm').value==0 ? "AM":"PM"
    let default_ampm_close = this.editForm.get('default_ampm_close').value==0 ? "AM":"PM"

    if(this.is12or24==1){
      starttime =  this.convert24hrs(this.editForm.get('start_hrs').value+':'+this.editForm.get('start_min').value +' '+default_ampm);
      endtime =  this.convert24hrs(this.editForm.get('end_hrs').value+':'+this.editForm.get('end_min').value +' '+default_ampm_close);
    }else{
      starttime =  this.convert24hrs(this.editForm.get('start_hrs').value+':'+this.editForm.get('start_min').value);
      endtime =  this.convert24hrs(this.editForm.get('end_hrs').value+':'+this.editForm.get('end_min').value);
    }
    // return
    formData.append('isadded', this.isadvanced);
    formData.append('hof_alwaysopen', this.hof_alwaysopen);
    // console.log(this.hof_alwaysopen);return

    if(this.isadvanced==1){
      formData.append('day_0', 'Sunday');
      formData.append('day_1', 'Monday');
      formData.append('day_2', 'Tuesday');
      formData.append('day_3', 'Wednesday');
      formData.append('day_4', 'Thursday');
      formData.append('day_5', 'Friday');
      formData.append('day_6', 'Saturday');

      formData.append('open_0', this.sundayopen);
      formData.append('open_1', this.mondayopen);
      formData.append('open_2', this.tuesdayopen);
      formData.append('open_3', this.wednesdayopen);
      formData.append('open_4', this.thursdayopen);
      formData.append('open_5', this.fridayopen);
      formData.append('open_6', this.saturdayopen);

      let sunday_ampm = this.editForm.get('sunday_ampm').value==0 ? "AM":"PM"
      let sunday_ampm_close = this.editForm.get('sunday_ampm_close').value==0 ? "AM":"PM"

      let monday_ampm = this.editForm.get('monday_ampm').value==0 ? "AM":"PM"
      let monday_ampm_close = this.editForm.get('monday_ampm_close').value==0 ? "AM":"PM"

      let tuesday_ampm = this.editForm.get('tuesday_ampm').value==0 ? "AM":"PM"
      let tuesday_ampm_close = this.editForm.get('tuesday_ampm_close').value==0 ? "AM":"PM"

      let wednesday_ampm = this.editForm.get('wednesday_ampm').value==0 ? "AM":"PM"
      let wednesday_ampm_close = this.editForm.get('wednesday_ampm_close').value==0 ? "AM":"PM"

      let thursday_ampm = this.editForm.get('thursday_ampm').value==0 ? "AM":"PM"
      let thursday_ampm_close = this.editForm.get('thursday_ampm_close').value==0 ? "AM":"PM"

      let friday_ampm = this.editForm.get('friday_ampm').value==0 ? "AM":"PM"
      let friday_ampm_close = this.editForm.get('friday_ampm_close').value==0 ? "AM":"PM"

      let saturday_ampm = this.editForm.get('saturday_ampm').value==0 ? "AM":"PM"
      let saturday_ampm_close = this.editForm.get('saturday_ampm_close').value==0 ? "AM":"PM"

      if(this.is12or24==1){
        formData.append('0_open_time',  this.convert24hrs(this.editForm.get('start_hrs_sunday').value+':'+this.editForm.get('start_min_sunday').value +' '+sunday_ampm));
        formData.append('0_close_time',  this.convert24hrs(this.editForm.get('end_hrs_sunday').value+':'+this.editForm.get('end_min_sunday').value +' '+sunday_ampm_close));

        formData.append('1_open_time',  this.convert24hrs(this.editForm.get('start_hrs_monday').value+':'+this.editForm.get('start_min_monday').value +' '+monday_ampm));
        formData.append('1_close_time',  this.convert24hrs(this.editForm.get('end_hrs_monday').value+':'+this.editForm.get('end_min_monday').value +' '+monday_ampm_close));

        formData.append('2_open_time',  this.convert24hrs(this.editForm.get('start_hrs_tuesday').value+':'+this.editForm.get('start_min_tuesday').value +' '+tuesday_ampm));
        formData.append('2_close_time',  this.convert24hrs(this.editForm.get('end_hrs_tuesday').value+':'+this.editForm.get('end_min_tuesday').value +' '+tuesday_ampm_close));

        formData.append('3_open_time',  this.convert24hrs(this.editForm.get('start_hrs_wednesday').value+':'+this.editForm.get('start_min_wednesday').value +' '+wednesday_ampm));
        formData.append('3_close_time',  this.convert24hrs(this.editForm.get('end_hrs_wednesday').value+':'+this.editForm.get('end_min_wednesday').value +' '+wednesday_ampm_close));

        formData.append('4_open_time',  this.convert24hrs(this.editForm.get('start_hrs_thursday').value+':'+this.editForm.get('start_min_thursday').value +' '+thursday_ampm));
        formData.append('4_close_time',  this.convert24hrs(this.editForm.get('end_hrs_thursday').value+':'+this.editForm.get('end_min_thursday').value +' '+thursday_ampm_close));

        formData.append('5_open_time',  this.convert24hrs(this.editForm.get('start_hrs_friday').value+':'+this.editForm.get('start_min_friday').value +' '+friday_ampm));
        formData.append('5_close_time',  this.convert24hrs(this.editForm.get('end_hrs_friday').value+':'+this.editForm.get('end_min_friday').value +' '+friday_ampm_close));

        formData.append('6_open_time',  this.convert24hrs(this.editForm.get('start_hrs_saturday').value+':'+this.editForm.get('start_min_saturday').value +' '+saturday_ampm));
        formData.append('6_close_time',  this.convert24hrs(this.editForm.get('end_hrs_saturday').value+':'+this.editForm.get('end_min_saturday').value +' '+saturday_ampm_close));
      }else{
        formData.append('0_open_time',  this.editForm.get('start_hrs_sunday').value+':'+this.editForm.get('start_min_sunday').value);
        formData.append('0_close_time',  this.editForm.get('end_hrs_sunday').value+':'+this.editForm.get('end_min_sunday').value);

        formData.append('1_open_time',  this.editForm.get('start_hrs_monday').value+':'+this.editForm.get('start_min_monday').value);
        formData.append('1_close_time',  this.editForm.get('end_hrs_monday').value+':'+this.editForm.get('end_min_monday').value);

        formData.append('2_open_time',  this.editForm.get('start_hrs_tuesday').value+':'+this.editForm.get('start_min_tuesday').value);
        formData.append('2_close_time',  this.editForm.get('end_hrs_tuesday').value+':'+this.editForm.get('end_min_tuesday').value);

        formData.append('3_open_time',  this.editForm.get('start_hrs_wednesday').value+':'+this.editForm.get('start_min_wednesday').value);
        formData.append('3_close_time',  this.editForm.get('end_hrs_wednesday').value+':'+this.editForm.get('end_min_wednesday').value);

        formData.append('4_open_time',  this.editForm.get('start_hrs_thursday').value+':'+this.editForm.get('start_min_thursday').value);
        formData.append('4_close_time',  this.editForm.get('end_hrs_thursday').value+':'+this.editForm.get('end_min_thursday').value);

        formData.append('5_open_time',  this.editForm.get('start_hrs_friday').value+':'+this.editForm.get('start_min_friday').value);
        formData.append('5_close_time',  this.editForm.get('end_hrs_friday').value+':'+this.editForm.get('end_min_friday').value);

        formData.append('6_open_time',  this.editForm.get('start_hrs_saturday').value+':'+this.editForm.get('start_min_saturday').value);
        formData.append('6_close_time',  this.editForm.get('end_hrs_saturday').value+':'+this.editForm.get('end_min_saturday').value);
      }
    }
    // if(this.isadded==1){
    //   formData.append('start_day_1', this.editForm.get('hoursofoperation_start_1').value);
    //   formData.append('end_day_1', this.editForm.get('hoursofoperation_end_1').value);

    //   var starttime_1 =  this.editForm.get('start_hrs_1').value+':'+this.editForm.get('start_min_1').value;
    //   var endtime_1 =  this.editForm.get('end_hrs_1').value+':'+this.editForm.get('end_min_1').value;

    //   formData.append('start_time_1',starttime_1);
    //   formData.append('end_time_1',endtime_1);
    //   formData.append('timezone1', this.editForm.get('timezone1').value);
    // }


    formData.append('start_time',starttime);
    formData.append('end_time',endtime);
    formData.append('capon',  this.editForm.get('capon').value);

    formData.append('is_autodtmf', this.dtmf_auto);
    formData.append('dtmf_zipcode_enable', this.dtmf_zipcode_enable);
    formData.append('dtmf_value',  this.editForm.get('dtmf_value').value);
    formData.append('dtmf_interval',  this.editForm.get('dtmf_interval').value);
    formData.append('dtmf_percent',  this.editForm.get('dtmf_percent').value);

    formData.append('global_revenuecaptoggle', this.global_revenuecap);
    formData.append('global_revenuecapvalue',  this.editForm.get('global_revenuecapvalue').value);
    formData.append('monthly_revenuecaptoggle', this.monthly_revenuecap);
    formData.append('monthly_revenuecapvalue',  this.editForm.get('monthly_revenuecapvalue').value);
    formData.append('daily_revenuecaptoggle', this.daily_revenuecap);
    formData.append('daily_revenuecapvalue',  this.editForm.get('daily_revenuecapvalue').value);
    formData.append('hourly_revenuecaptoggle', this.hourly_revenuecap);
    formData.append('hourly_revenuecapvalue',  this.editForm.get('hourly_revenuecapvalue').value);


    formData.append('global_cap', this.globalcap);
    formData.append('monthly_cap', this.monthlycap);
    formData.append('daily_cap', this.dailycap);
    formData.append('hourly_cap', this.hourlycap);

    formData.append('global_capvalue',  this.editForm.get('global_capvalue').value);
    formData.append('monthly_capvalue',  this.editForm.get('monthly_capvalue').value);
    formData.append('daily_capvalue',  this.editForm.get('daily_capvalue').value);
    formData.append('hourly_capvalue',  this.editForm.get('hourly_capvalue').value);

    formData.append('addcontoggle', this.maxconcurrency);
    formData.append('maxconvalue', this.editForm.get('maxconvalue').value);

    formData.append('restrictdup', this.editForm.get('restrictdup').value);
    formData.append('restrictafter', this.editForm.get('restrictafter').value);
    formData.append('settingToggleDurationval', this.settingToggleDurationvalue);
    formData.append('days', this.editForm.get('days').value);

    if(this.isfilter==1){
    formData.append('isfilter', this.editForm.get('isfilter').value);
    formData.append('citystatetype', this.editForm.get('citystatetype').value);
    formData.append('iscontains', this.editForm.get('iscontains').value);
    formData.append('citystate', JSON.stringify(this.editForm.get('citystate').value));
    formData.append('cityval', JSON.stringify(this.editForm.get('cityval').value));
    }

    formData.append('filterselected', JSON.stringify(this.filterselected));
    formData.append('is12or24', this.is12or24);

    const uuid = this.route.snapshot.paramMap.get('id');
    // alert(uuid);return false;
    if (uuid) {
      this.moduleService.updateTarget(formData, uuid).subscribe(
        res => {
          // console.log(res); return false;
          if (res.status !== 'error') {
            this.toastrService.success('Target Updated Successfully');
            this.isTargetName = false;
            // this.router.navigate(['/numbers']);
            this.router.navigate(['/target']);

          }else{
            this.toastrService.success('Failed To Update! Please Try Again Later..');
          }
        },
        error => this.error = error
      );
    }
  }

  isTargetName : any;
  validateTargetname() {
  if(this.editForm.get('name').value!='' && (this.currtargetname!=this.editForm.get('name').value)){
    this.moduleService.validateTargetName(this.editForm.get('name').value,this.currentCompany.uuid).subscribe(
      res =>{
          // console.log(res); return false;
        this.isTargetName = res;
        if (this.isTargetName.status == 'success') {
          this.isTargetName = "success";
        }
        else{
          this.isTargetName = "fail";
        }
      }
    );
  }else{
    this.isTargetName="";
  }
}

isNumberValid : any;
notvalidmsg : any= "";
validatenumber() {
  if(this.editForm.get('number').value!='' && (this.currtargetnumber!=this.editForm.get('number').value)){
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleService.validateTargetNumber(this.editForm.get('number').value,this.currentCompany.uuid).subscribe(
      res =>{
        this.isNumberValid = res.status;         
        this.notvalidmsg = res.message;      
      }
    );
  }else{
    this.isNumberValid="";
  }
}

// getRules(){
//     // alert('ok');
//     this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
//     this.FSLRulesInsertID = localStorage.getItem('FSLRulesInsertID');
//     // console.log(this.FSLRulesInsertID);return false;
//     this.moduleService.getRecentRules(this.FSLRulesInsertID,this.currentCompany.uuid).subscribe(
//       res => {
//         // console.log(res.noofforwardinglocation);
//         this.rulesData = res;
//       },
//       error => this.error = error,
//     );
// }

jklrules:string;
get_jklrules(event:any){
  this.jklrules = event.target.checked;
}

clearfilter(index:any){
  this.filterselected.splice(index,1);
}
ringtree:any = false;
ringtreetext = "Simple";
ringtree_toggle(event:any){
  this.ringtree = event.target.checked;
  console.log(event.target.checked);
  if(event.target.checked == true){
    this.ringtreetext = "Ring Tree";
  }else{
    this.ringtreetext = "Simple";
  }
}

global_revenuecap:any;
global_revenue_cap(event:any){
  this.global_revenuecap = event.target.checked;
}

globalcap:any;
global_cap(event:any){
  this.globalcap = event.target.checked;
}

dtmf_auto:any;
dtmf_auto_toggle(event:any){
  this.dtmf_auto = event.target.checked;
}

methodtypeval:any = 0;
methodtype(event:any){
  this.methodtypeval = event.target.value;
}

hof_alwaysopen:any=false;
  hof_alwaysopen_toggle(event:any){
    this.hof_alwaysopen = event.target.checked;
  }

  isadvanced:any=0;
  advanced:any='Advanced';
  isadvanced_toggle(event:any){
    if(event==1){
      this.advanced='Advanced';
      this.isadvanced = 1;
    }
    if(event==0){
      this.advanced='Basic';
      this.isadvanced = 0;
    }
  }

  sundayopen:any=1;
  sunday_open(event:any){
    if(event.target.checked==true){
    this.sundayopen = 1;
    }else{
      this.sundayopen = 0;
    }
  }
  mondayopen:any=1;
  monday_open(event:any){
    if(event.target.checked==true){
      this.mondayopen = 1;
      }else{
        this.mondayopen = 0;
      }
  }
  tuesdayopen:any=1;
  tuesday_open(event:any){
    if(event.target.checked==true){
      this.tuesdayopen = 1;
      }else{
        this.tuesdayopen = 0;
      }
  }
  wednesdayopen:any=1;
  wednesday_open(event:any){
    if(event.target.checked==true){
      this.wednesdayopen = 1;
      }else{
        this.wednesdayopen = 0;
      }
  }
  thursdayopen:any=1;
  thursday_open(event:any){
    if(event.target.checked==true){
    this.thursdayopen = 1;
    }else{
      this.thursdayopen = 0;
    }
  }
  fridayopen:any=1;
  friday_open(event:any){
    if(event.target.checked==true){
    this.fridayopen = 1;
    }else{
      this.fridayopen = 0;
    }
  }
  saturdayopen:any=1;
  saturday_open(event:any){
    if(event.target.checked==true){
    this.saturdayopen = 1;
    }else{
      this.saturdayopen = 0;
    }
  }

dtmf_zipcode_enable:any;
dtmf_zipcode_enable_toggle(event:any){
  this.dtmf_zipcode_enable = event.target.checked;
}

monthly_revenuecap:any;
monthly_revenue_cap(event:any){
  this.monthly_revenuecap = event.target.checked;
}

monthlycap:any;
monthly_cap(event:any){
  this.monthlycap = event.target.checked;
}

daily_revenuecap:any;
daily_revenue_cap(event:any){
  this.daily_revenuecap = event.target.checked;
}

dailycap:any;
daily_cap(event:any){
  this.dailycap = event.target.checked;
}

hourly_revenuecap:any;
hourly_revenue_cap(event:any){
  this.hourly_revenuecap = event.target.checked;
}

hourlycap:any;
hourly_cap(event:any){
  this.hourlycap = event.target.checked;
}

maxconcurrency:any;
get_maxcon(event:any){
  this.maxconcurrency = event.target.checked;
}

disableRecordings:any;
get_disableRecordings(event:any){
  this.disableRecordings = event.target.checked;
}

hourlyconcurrency:any;
get_hourlyconcurrency(event:any){
  this.hourlyconcurrency = event.target.checked;
}

timeonehrs:any;
  getTimeonehrs(event:any){
    this.timeonehrs = event.target.value;
    if(this.timetwohrs<this.timeonehrs){
      this.getTimeerror()
    }
  }
  timeonemin:any;
  getTimeonemin(event:any){
    this.timeonemin = event.target.value;
    if(this.timetwohrs==this.timeonehrs && this.timetwomin<=this.timeonemin){
      this.getTimeerror()
    }
  }
  timetwohrs:any;
  getTimetwohrs(event:any){
    this.timetwohrs = event.target.value;
    if(this.timetwohrs<this.timeonehrs){
      this.getTimeerror()
    }

  }
  timetwomin:any;
  getTimetwomin(event:any){
    this.timetwomin = event.target.value;
    if(this.timetwohrs==this.timeonehrs && this.timetwomin<=this.timeonemin){
      this.getTimeerror()
    }
  }


  timeonehrs1:any;
  getTimetwohrs1(event:any){
    this.timeonehrs1 = event.target.value;
    if(this.timetwohrs1<this.timeonehrs1){
      this.getTimeerror()
    }
  }
  timeonemin1:any;
  getTimetwomin1(event:any){
    this.timeonemin1 = event.target.value;
    if(this.timetwohrs1==this.timeonehrs1 && this.timetwomin1<=this.timeonemin1){
      this.getTimeerror()
    }
  }
  timetwohrs1:any;
  getTimetwohrs2(event:any){
    this.timetwohrs1 = event.target.value;
    if(this.timetwohrs1<this.timeonehrs1){
      this.getTimeerror()
    }

  }
  timetwomin1:any;
  getTimetwomin2(event:any){
    this.timetwomin1 = event.target.value;
    if(this.timetwohrs1==this.timeonehrs1 && this.timetwomin1<=this.timeonemin1){
      this.getTimeerror()
    }
  }

  getTimeerror(){
    Swal.fire(
      'Error!',
      'Closing Time must be greater than Opening Time.',
      'error',
    );
  }


getFSLbyuuid(uuid:string){
  // alert(uuid);
  // console.log(uuid);
  if(uuid!=''){
  this.moduleService.getFSLbyuuid(uuid).subscribe(
    res => {

      this.forwarding = res.forwarding[0].name;
      this.forwardingtimeframe = res.forwarding[0].nooftimeframe;
      this.noofforwarding = res.forwarding[0].nooftimeframe;
      for (let index = 0; index < res.forwarding.length; index++) {
        var start = res.forwarding[index].start_time.split(':');
        var end = res.forwarding[index].end_time.split(':');
        var starthrs ='start_hrs_'+index;
        var startmin ='start_min_'+index;
        var endhrs ='end_hrs_'+index;
        var endmin ='end_min_'+index;
        // var endkey ='end_time_'+index;
        var daykey ='day_'+index;
        // console.log(start[0]);
        this.editFSLRules.patchValue({
          [daykey]: res.forwarding[index].timeofday,
          // [startkey]: new Date(new Date().setHours(start[0], start[1])),
          [starthrs]: start[0],
          [startmin]: start[1],
          [endhrs]: end[0],
          [endmin]: end[1],

          // [endkey]: new Date(new Date().setHours(end[0], end[1])),
        });

        for (let j = 0; j < res.forwarding[index].locations.length; j++) {
          var inc = j + 1;
          // var forlocationkey ='noofforwardinglocation_'+inc;
          var PrimaryforwardType ='PrimaryforwardType_'+j+'_day'+index;
          var number_value ='number_'+j+'_day'+index;
          var ivr_value ='ivrMenu_'+j+'_day'+index;
          var sip_value ='sipAdd_'+j+'_day'+index;
          // this.flag =res.forwarding[index].locations[j].primary_forwarding_type;
          // console.log(res.forwarding[index].locations[j].primary_forwarding_type);
          if(res.forwarding[index].locations[j].primary_forwarding_type ==0){
            this.setPrimaryforward(0,j,index);
            this.flag = 0;
          }
          if(res.forwarding[index].locations[j].primary_forwarding_type ==1){
            this.setPrimaryforward(1,j,index);
            this.flag = 1;
          }
          if(res.forwarding[index].locations[j].primary_forwarding_type ==2){
            this.flag = 2;
            this.setPrimaryforward(2,j,index);
          }

          if(res.forwarding[index].locations[j].primary_forwarding_number!=''){
            var number = res.forwarding[index].locations[j].primary_forwarding_number
            var formatted = '('+ number.substr(0, 3) + ') ' + number.substr(3, 3) + '-' + number.substr(6,4)
          }else{
            var formatted="";
          }



           if(index == 0){
            this.noofforwardinglocation_1 = res.forwarding[index].locations[j].noofforwardinglocation;
            // if(this.noofforwardinglocation_1!=1)  {
            // this.noofforwardinglocation_1++;
            // }
           }
          //  console.log(res.forwarding[index].locations[j].noofforwardinglocation);
           if(index == 1){
            this.noofforwardinglocation_2 = res.forwarding[index].locations[j].noofforwardinglocation;
            // if(res.forwarding[index].locations[j].noofforwardinglocation!=1)  {
            //   this.noofforwardinglocation_2++;
            //   }
           }
           if(index == 2){
            this.noofforwardinglocation_3 = res.forwarding[index].locations[j].noofforwardinglocation;
            // if(res.forwarding[index].locations[j].noofforwardinglocation!=1)  {
            //   this.noofforwardinglocation_3++;
            // }
           }
           if(index == 3){
            this.noofforwardinglocation_4 = res.forwarding[index].locations[j].noofforwardinglocation;
            // if(res.forwarding[index].locations[j].noofforwardinglocation!=1)  {
            //   this.noofforwardinglocation_4++;
            // }
           }
           if(index == 4){
            this.noofforwardinglocation_5 = res.forwarding[index].locations[j].noofforwardinglocation;
            // if(res.forwarding[index].locations[j].noofforwardinglocation!=1)  {
            //   this.noofforwardinglocation_5++;
            // }
           }
           if(index == 5){
            this.noofforwardinglocation_6 = res.forwarding[index].locations[j].noofforwardinglocation;
           }
           if(index == 6){
            this.noofforwardinglocation_7 = res.forwarding[index].locations[j].noofforwardinglocation;
           }
          // console.log(index);
          this.editFSLRules.patchValue({
            // [forlocationkey]: res.forwarding[index].locations[j].noofforwardinglocation,
            [PrimaryforwardType]: this.flag,
            [number_value]: formatted,
            [ivr_value]: res.forwarding[index].locations[j].primary_forwarding_ivr,
            [sip_value]: res.forwarding[index].locations[j].primary_forwarding_sip,
          });
        }
      }

    },
    error => this.error = error,
  );
  }else{
    this.allRulesbyname = [];
  }
}

addFSL(){
    this.FSLRulesSubmitted = false;
    this.addFSLRules.reset();
}
getWeekbyuuid(uuid:any,start_day:any,end_day:any){
    this.editWeekDaysForm.patchValue({
      'uuid':uuid,
      'start_day':start_day,
      'end_day':end_day,
    });
}

allweekdays:any;
loadallweekdays(){
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  this.moduleService.loadallweekdays(this.currentCompany.uuid).subscribe(
    res => {
      //  console.log(res); return false;
      this.allweekdays = res.data;
    },
    error => this.error = error,
  );
}


  // Day0
  private selectedPrimaryForaward_0_day0: number=0;
  private selectedPrimaryCount_0_day0: number=0;
  private selected_0_day_0: number=0;

  private selectedPrimaryForaward_1_day0: number=0;
  private selectedPrimaryCount_1_day0: number=0;
  private selected_1_day_0: number=0;

  private selectedPrimaryForaward_2_day0: number=0;
  private selectedPrimaryCount_2_day0: number=0;
  private selected_2_day_0: number=0;

  private selectedPrimaryForaward_3_day0: number=0;
  private selectedPrimaryCount_3_day0: number=0;
  private selected_3_day_0: number=0;

  private selectedPrimaryForaward_4_day0: number=0;
  private selectedPrimaryCount_4_day0: number=0;
  private selected_4_day_0: number=0;

  private selectedPrimaryForaward_5_day0: number=0;
  private selectedPrimaryCount_5_day0: number=0;
  private selected_5_day_0: number=0;

  private selectedPrimaryForaward_6_day0: number=0;
  private selectedPrimaryCount_6_day0: number=0;
  private selected_6_day_0: number=0;

  private selectedPrimaryForaward_7_day0: number=0;
  private selectedPrimaryCount_7_day0: number=0;
  private selected_7_day_0: number=0;

  private selectedPrimaryForaward_8_day0: number=0;
  private selectedPrimaryCount_8_day0: number=0;
  private selected_8_day_0: number=0;

  private selectedPrimaryForaward_9_day0: number=0;
  private selectedPrimaryCount_9_day0: number=0;
  private selected_9_day_0: number=0;

 // Day1
 private selectedPrimaryForaward_0_day1: number=0;
 private selectedPrimaryCount_0_day1: number=0;
 private selected_0_day_1: number=0;

 private selectedPrimaryForaward_1_day1: number=0;
 private selectedPrimaryCount_1_day1: number=0;
 private selected_1_day_1: number=0;

 private selectedPrimaryForaward_2_day1: number=0;
 private selectedPrimaryCount_2_day1: number=0;
 private selected_2_day_1: number=0;

 private selectedPrimaryForaward_3_day1: number=0;
 private selectedPrimaryCount_3_day1: number=0;
 private selected_3_day_1: number=0;

 private selectedPrimaryForaward_4_day1: number=0;
 private selectedPrimaryCount_4_day1: number=0;
 private selected_4_day_1: number=0;

 private selectedPrimaryForaward_5_day1: number=0;
 private selectedPrimaryCount_5_day1: number=0;
 private selected_5_day_1: number=0;

 private selectedPrimaryForaward_6_day1: number=0;
 private selectedPrimaryCount_6_day1: number=0;
 private selected_6_day_1: number=0;

 private selectedPrimaryForaward_7_day1: number=0;
 private selectedPrimaryCount_7_day1: number=0;
 private selected_7_day_1: number=0;

 private selectedPrimaryForaward_8_day1: number=0;
 private selectedPrimaryCount_8_day1: number=0;
 private selected_8_day_1: number=0;

 private selectedPrimaryForaward_9_day1: number=0;
 private selectedPrimaryCount_9_day1: number=0;
 private selected_9_day_1: number=0;

// Day2
private selectedPrimaryForaward_0_day2: number=0;
private selectedPrimaryCount_0_day2: number=0;
private selected_0_day_2: number=0;

private selectedPrimaryForaward_1_day2: number=0;
private selectedPrimaryCount_1_day2: number=0;
private selected_1_day_2: number=0;

private selectedPrimaryForaward_2_day2: number=0;
private selectedPrimaryCount_2_day2: number=0;
private selected_2_day_2: number=0;

private selectedPrimaryForaward_3_day2: number=0;
private selectedPrimaryCount_3_day2: number=0;
private selected_3_day_2: number=0;

private selectedPrimaryForaward_4_day2: number=0;
private selectedPrimaryCount_4_day2: number=0;
private selected_4_day_2: number=0;

private selectedPrimaryForaward_5_day2: number=0;
private selectedPrimaryCount_5_day2: number=0;
private selected_5_day_2: number=0;

private selectedPrimaryForaward_6_day2: number=0;
private selectedPrimaryCount_6_day2: number=0;
private selected_6_day_2: number=0;

private selectedPrimaryForaward_7_day2: number=0;
private selectedPrimaryCount_7_day2: number=0;
private selected_7_day_2: number=0;

private selectedPrimaryForaward_8_day2: number=0;
private selectedPrimaryCount_8_day2: number=0;
private selected_8_day_2: number=0;

private selectedPrimaryForaward_9_day2: number=0;
private selectedPrimaryCount_9_day2: number=0;
private selected_9_day_2: number=0;

// Day3
private selectedPrimaryForaward_0_day3: number=0;
private selectedPrimaryCount_0_day3: number=0;
private selected_0_day_3: number=0;

private selectedPrimaryForaward_1_day3: number=0;
private selectedPrimaryCount_1_day3: number=0;
private selected_1_day_3: number=0;

private selectedPrimaryForaward_2_day3: number=0;
private selectedPrimaryCount_2_day3: number=0;
private selected_2_day_3: number=0;

private selectedPrimaryForaward_3_day3: number=0;
private selectedPrimaryCount_3_day3: number=0;
private selected_3_day_3: number=0;

private selectedPrimaryForaward_4_day3: number=0;
private selectedPrimaryCount_4_day3: number=0;
private selected_4_day_3: number=0;

private selectedPrimaryForaward_5_day3: number=0;
private selectedPrimaryCount_5_day3: number=0;
private selected_5_day_3: number=0;

private selectedPrimaryForaward_6_day3: number=0;
private selectedPrimaryCount_6_day3: number=0;
private selected_6_day_3: number=0;

private selectedPrimaryForaward_7_day3: number=0;
private selectedPrimaryCount_7_day3: number=0;
private selected_7_day_3: number=0;

private selectedPrimaryForaward_8_day3: number=0;
private selectedPrimaryCount_8_day3: number=0;
private selected_8_day_3: number=0;

private selectedPrimaryForaward_9_day3: number=0;
private selectedPrimaryCount_9_day3: number=0;
private selected_9_day_3: number=0;

// Day4
private selectedPrimaryForaward_0_day4: number=0;
private selectedPrimaryCount_0_day4: number=0;
private selected_0_day_4: number=0;

private selectedPrimaryForaward_1_day4: number=0;
private selectedPrimaryCount_1_day4: number=0;
private selected_1_day_4: number=0;

private selectedPrimaryForaward_2_day4: number=0;
private selectedPrimaryCount_2_day4: number=0;
private selected_2_day_4: number=0;

private selectedPrimaryForaward_3_day4: number=0;
private selectedPrimaryCount_3_day4: number=0;
private selected_3_day_4: number=0;

private selectedPrimaryForaward_4_day4: number=0;
private selectedPrimaryCount_4_day4: number=0;
private selected_4_day_4: number=0;

private selectedPrimaryForaward_5_day4: number=0;
private selectedPrimaryCount_5_day4: number=0;
private selected_5_day_4: number=0;

private selectedPrimaryForaward_6_day4: number=0;
private selectedPrimaryCount_6_day4: number=0;
private selected_6_day_4: number=0;

private selectedPrimaryForaward_7_day4: number=0;
private selectedPrimaryCount_7_day4: number=0;
private selected_7_day_4: number=0;

private selectedPrimaryForaward_8_day4: number=0;
private selectedPrimaryCount_8_day4: number=0;
private selected_8_day_4: number=0;

private selectedPrimaryForaward_9_day4: number=0;
private selectedPrimaryCount_9_day4: number=0;
private selected_9_day_4: number=0;

  setPrimaryforward(id:number,count:number,day:number){
    // Day0
    if(count==0 && day==0){
      this.selectedPrimaryForaward_0_day0 = id;
      this.selectedPrimaryCount_0_day0 = count;
      this.selected_0_day_0 = day;
    }
    if(count==1 && day==0){
      this.selectedPrimaryForaward_1_day0 = id;
      this.selectedPrimaryCount_1_day0 = count;
      this.selected_1_day_0 = day;
    }
    if(count==2 && day==0){
      this.selectedPrimaryForaward_2_day0 = id;
      this.selectedPrimaryCount_2_day0 = count;
      this.selected_2_day_0 = day;
    }
    if(count==3 && day==0){
      this.selectedPrimaryForaward_3_day0 = id;
      this.selectedPrimaryCount_3_day0 = count;
      this.selected_3_day_0 = day;
    }
    if(count==4 && day==0){
      this.selectedPrimaryForaward_4_day0 = id;
      this.selectedPrimaryCount_4_day0 = count;
      this.selected_4_day_0 = day;
    }
    if(count==5 && day==0){
      this.selectedPrimaryForaward_5_day0 = id;
      this.selectedPrimaryCount_5_day0 = count;
      this.selected_5_day_0 = day;
    }
    if(count==6 && day==0){
      this.selectedPrimaryForaward_6_day0 = id;
      this.selectedPrimaryCount_6_day0 = count;
      this.selected_6_day_0 = day;
    }
    if(count==7 && day==0){
      this.selectedPrimaryForaward_7_day0 = id;
      this.selectedPrimaryCount_7_day0 = count;
      this.selected_7_day_0 = day;
    }
    if(count==8 && day==0){
      this.selectedPrimaryForaward_8_day0 = id;
      this.selectedPrimaryCount_8_day0 = count;
      this.selected_8_day_0 = day;
    }
    if(count==9 && day==0){
      this.selectedPrimaryForaward_9_day0 = id;
      this.selectedPrimaryCount_9_day0 = count;
      this.selected_9_day_0 = day;
    }


   // Day1
   if(count==0 && day==1){
    this.selectedPrimaryForaward_0_day1 = id;
    this.selectedPrimaryCount_0_day1 = count;
    this.selected_0_day_1 = day;
  }
  if(count==1 && day==1){
    this.selectedPrimaryForaward_1_day1 = id;
    this.selectedPrimaryCount_1_day1 = count;
    this.selected_1_day_1 = day;
  }
  if(count==2 && day==1){
    this.selectedPrimaryForaward_2_day1 = id;
    this.selectedPrimaryCount_2_day1 = count;
    this.selected_2_day_1 = day;
  }
  if(count==3 && day==1){
    this.selectedPrimaryForaward_3_day1 = id;
    this.selectedPrimaryCount_3_day1 = count;
    this.selected_3_day_1 = day;
  }
  if(count==4 && day==1){
    this.selectedPrimaryForaward_4_day1 = id;
    this.selectedPrimaryCount_4_day1 = count;
    this.selected_4_day_1 = day;
  }
  if(count==5 && day==1){
    this.selectedPrimaryForaward_5_day1 = id;
    this.selectedPrimaryCount_5_day1 = count;
    this.selected_5_day_1 = day;
  }
  if(count==6 && day==1){
    this.selectedPrimaryForaward_6_day1 = id;
    this.selectedPrimaryCount_6_day1 = count;
    this.selected_6_day_1 = day;
  }
  if(count==7 && day==1){
    this.selectedPrimaryForaward_7_day1 = id;
    this.selectedPrimaryCount_7_day1 = count;
    this.selected_7_day_1 = day;
  }
  if(count==8 && day==1){
    this.selectedPrimaryForaward_8_day1 = id;
    this.selectedPrimaryCount_8_day1 = count;
    this.selected_8_day_1 = day;
  }
  if(count==9 && day==1){
    this.selectedPrimaryForaward_9_day1 = id;
    this.selectedPrimaryCount_9_day1 = count;
    this.selected_9_day_1 = day;
  }

  // Day2
  if(count==0 && day==2){
    this.selectedPrimaryForaward_0_day2 = id;
    this.selectedPrimaryCount_0_day2 = count;
    this.selected_0_day_2 = day;
  }
  if(count==1 && day==2){
    this.selectedPrimaryForaward_1_day2 = id;
    this.selectedPrimaryCount_1_day2 = count;
    this.selected_1_day_2 = day;
  }
  if(count==2 && day==2){
    this.selectedPrimaryForaward_2_day2 = id;
    this.selectedPrimaryCount_2_day2 = count;
    this.selected_2_day_2 = day;
  }
  if(count==3 && day==2){
    this.selectedPrimaryForaward_3_day2 = id;
    this.selectedPrimaryCount_3_day2 = count;
    this.selected_3_day_2 = day;
  }
  if(count==4 && day==2){
    this.selectedPrimaryForaward_4_day2 = id;
    this.selectedPrimaryCount_4_day2 = count;
    this.selected_4_day_2 = day;
  }
  if(count==5 && day==2){
    this.selectedPrimaryForaward_5_day2 = id;
    this.selectedPrimaryCount_5_day2 = count;
    this.selected_5_day_2 = day;
  }
  if(count==6 && day==2){
    this.selectedPrimaryForaward_6_day2 = id;
    this.selectedPrimaryCount_6_day2 = count;
    this.selected_6_day_2 = day;
  }
  if(count==7 && day==2){
    this.selectedPrimaryForaward_7_day2 = id;
    this.selectedPrimaryCount_7_day2 = count;
    this.selected_7_day_2 = day;
  }
  if(count==8 && day==2){
    this.selectedPrimaryForaward_8_day2 = id;
    this.selectedPrimaryCount_8_day2 = count;
    this.selected_8_day_2 = day;
  }
  if(count==9 && day==2){
    this.selectedPrimaryForaward_9_day2 = id;
    this.selectedPrimaryCount_9_day2 = count;
    this.selected_9_day_2 = day;
  }

   // Day3
   if(count==0 && day==3){
    this.selectedPrimaryForaward_0_day3 = id;
    this.selectedPrimaryCount_0_day3 = count;
    this.selected_0_day_3 = day;
  }
  if(count==1 && day==3){
    this.selectedPrimaryForaward_1_day3 = id;
    this.selectedPrimaryCount_1_day3 = count;
    this.selected_1_day_3 = day;
  }
  if(count==2 && day==3){
    this.selectedPrimaryForaward_2_day3 = id;
    this.selectedPrimaryCount_2_day3 = count;
    this.selected_2_day_3 = day;
  }
  if(count==3 && day==3){
    this.selectedPrimaryForaward_3_day3 = id;
    this.selectedPrimaryCount_3_day3 = count;
    this.selected_3_day_3 = day;
  }
  if(count==4 && day==3){
    this.selectedPrimaryForaward_4_day3 = id;
    this.selectedPrimaryCount_4_day3 = count;
    this.selected_4_day_3 = day;
  }
  if(count==5 && day==3){
    this.selectedPrimaryForaward_5_day3 = id;
    this.selectedPrimaryCount_5_day3 = count;
    this.selected_5_day_3 = day;
  }
  if(count==6 && day==3){
    this.selectedPrimaryForaward_6_day3 = id;
    this.selectedPrimaryCount_6_day3 = count;
    this.selected_6_day_3 = day;
  }
  if(count==7 && day==3){
    this.selectedPrimaryForaward_7_day3 = id;
    this.selectedPrimaryCount_7_day3 = count;
    this.selected_7_day_3 = day;
  }
  if(count==8 && day==3){
    this.selectedPrimaryForaward_8_day3 = id;
    this.selectedPrimaryCount_8_day3 = count;
    this.selected_8_day_3 = day;
  }
  if(count==9 && day==3){
    this.selectedPrimaryForaward_9_day3 = id;
    this.selectedPrimaryCount_9_day3 = count;
    this.selected_9_day_3 = day;
  }

  // Day4
  if(count==0 && day==4){
    this.selectedPrimaryForaward_0_day4 = id;
    this.selectedPrimaryCount_0_day4 = count;
    this.selected_0_day_4 = day;
  }
  if(count==1 && day==4){
    this.selectedPrimaryForaward_1_day4 = id;
    this.selectedPrimaryCount_1_day4 = count;
    this.selected_1_day_4 = day;
  }
  if(count==2 && day==4){
    this.selectedPrimaryForaward_2_day4 = id;
    this.selectedPrimaryCount_2_day4 = count;
    this.selected_2_day_4 = day;
  }
  if(count==3 && day==4){
    this.selectedPrimaryForaward_3_day4 = id;
    this.selectedPrimaryCount_3_day4 = count;
    this.selected_3_day_4 = day;
  }
  if(count==4 && day==4){
    this.selectedPrimaryForaward_4_day4 = id;
    this.selectedPrimaryCount_4_day4 = count;
    this.selected_4_day_4 = day;
  }
  if(count==5 && day==4){
    this.selectedPrimaryForaward_5_day4 = id;
    this.selectedPrimaryCount_5_day4 = count;
    this.selected_5_day_4 = day;
  }
  if(count==6 && day==4){
    this.selectedPrimaryForaward_6_day4 = id;
    this.selectedPrimaryCount_6_day4 = count;
    this.selected_6_day_4 = day;
  }
  if(count==7 && day==4){
    this.selectedPrimaryForaward_7_day4 = id;
    this.selectedPrimaryCount_7_day4 = count;
    this.selected_7_day_4 = day;
  }
  if(count==8 && day==4){
    this.selectedPrimaryForaward_8_day4 = id;
    this.selectedPrimaryCount_8_day4 = count;
    this.selected_8_day_4 = day;
  }
  if(count==9 && day==4){
    this.selectedPrimaryForaward_9_day4 = id;
    this.selectedPrimaryCount_9_day4 = count;
    this.selected_9_day_4 = day;
  }

  }

  isPrimarySelected(id:number,count:number,day:number): boolean
  {
    // Day0
    if(count==0 && day==0){
      if(this.selectedPrimaryForaward_0_day0 == id && this.selectedPrimaryCount_0_day0 === count && this.selected_0_day_0== day)
      return true;
      else
      return false;
    }
    if(count==1 && day==0){
      if(this.selectedPrimaryForaward_1_day0 == id && this.selectedPrimaryCount_1_day0 === count && this.selected_1_day_0== day)
      return true;
      else
      return false;
    }
    if(count==2 && day==0){
      if(this.selectedPrimaryForaward_2_day0 == id && this.selectedPrimaryCount_2_day0 === count && this.selected_2_day_0== day)
      return true;
      else
      return false;
    }
    if(count==3 && day==0){
      if(this.selectedPrimaryForaward_3_day0 == id && this.selectedPrimaryCount_3_day0 === count && this.selected_3_day_0== day)
      return true;
      else
      return false;
    }
    if(count==4 && day==0){
      if(this.selectedPrimaryForaward_4_day0 == id && this.selectedPrimaryCount_4_day0 === count && this.selected_4_day_0== day)
      return true;
      else
      return false;
    }
    if(count==5 && day==0){
      if(this.selectedPrimaryForaward_5_day0 == id && this.selectedPrimaryCount_5_day0 === count && this.selected_5_day_0== day)
      return true;
      else
      return false;
    }
    if(count==6 && day==0){
      if(this.selectedPrimaryForaward_6_day0 == id && this.selectedPrimaryCount_6_day0 === count && this.selected_6_day_0== day)
      return true;
      else
      return false;
    }
    if(count==7 && day==0){
      if(this.selectedPrimaryForaward_7_day0 == id && this.selectedPrimaryCount_7_day0 === count && this.selected_7_day_0== day)
      return true;
      else
      return false;
    }
    if(count==8 && day==0){
      if(this.selectedPrimaryForaward_8_day0 == id && this.selectedPrimaryCount_8_day0 === count && this.selected_8_day_0== day)
      return true;
      else
      return false;
    }

    if(count==9 && day==0){
      if(this.selectedPrimaryForaward_9_day0 == id && this.selectedPrimaryCount_9_day0 === count && this.selected_9_day_0== day)
      return true;
      else
      return false;
    }

    // Day1
    if(count==0 && day==1){
      if(this.selectedPrimaryForaward_0_day1 == id && this.selectedPrimaryCount_0_day1 === count && this.selected_0_day_1== day)
      return true;
      else
      return false;
    }
    if(count==1 && day==1){
      if(this.selectedPrimaryForaward_1_day1 == id && this.selectedPrimaryCount_1_day1 === count && this.selected_1_day_1== day)
      return true;
      else
      return false;
    }
    if(count==2 && day==1){
      if(this.selectedPrimaryForaward_2_day1 == id && this.selectedPrimaryCount_2_day1 === count && this.selected_2_day_1== day)
      return true;
      else
      return false;
    }
    if(count==3 && day==1){
      if(this.selectedPrimaryForaward_3_day1 == id && this.selectedPrimaryCount_3_day1 === count && this.selected_3_day_1== day)
      return true;
      else
      return false;
    }
    if(count==4 && day==1){
      if(this.selectedPrimaryForaward_4_day1 == id && this.selectedPrimaryCount_4_day1 === count && this.selected_4_day_1== day)
      return true;
      else
      return false;
    }
    if(count==5 && day==1){
      if(this.selectedPrimaryForaward_5_day1 == id && this.selectedPrimaryCount_5_day1 === count && this.selected_5_day_1== day)
      return true;
      else
      return false;
    }
    if(count==6 && day==1){
      if(this.selectedPrimaryForaward_6_day1 == id && this.selectedPrimaryCount_6_day1 === count && this.selected_6_day_1== day)
      return true;
      else
      return false;
    }
    if(count==7 && day==1){
      if(this.selectedPrimaryForaward_7_day1 == id && this.selectedPrimaryCount_7_day1 === count && this.selected_7_day_1== day)
      return true;
      else
      return false;
    }
    if(count==8 && day==1){
      if(this.selectedPrimaryForaward_8_day1 == id && this.selectedPrimaryCount_8_day1 === count && this.selected_8_day_1== day)
      return true;
      else
      return false;
    }

    if(count==9 && day==1){
      if(this.selectedPrimaryForaward_9_day1 == id && this.selectedPrimaryCount_9_day1 === count && this.selected_9_day_1== day)
      return true;
      else
      return false;
    }

    // Day2
    if(count==0 && day==2){
      if(this.selectedPrimaryForaward_0_day2 == id && this.selectedPrimaryCount_0_day2 === count && this.selected_0_day_2== day)
      return true;
      else
      return false;
    }
    if(count==1 && day==2){
      if(this.selectedPrimaryForaward_1_day2 == id && this.selectedPrimaryCount_1_day2 === count && this.selected_1_day_2== day)
      return true;
      else
      return false;
    }
    if(count==2 && day==2){
      if(this.selectedPrimaryForaward_2_day2 == id && this.selectedPrimaryCount_2_day2 === count && this.selected_2_day_2== day)
      return true;
      else
      return false;
    }
    if(count==3 && day==2){
      if(this.selectedPrimaryForaward_3_day2 == id && this.selectedPrimaryCount_3_day2 === count && this.selected_3_day_2== day)
      return true;
      else
      return false;
    }
    if(count==4 && day==2){
      if(this.selectedPrimaryForaward_4_day2 == id && this.selectedPrimaryCount_4_day2 === count && this.selected_4_day_2== day)
      return true;
      else
      return false;
    }
    if(count==5 && day==2){
      if(this.selectedPrimaryForaward_5_day2 == id && this.selectedPrimaryCount_5_day2 === count && this.selected_5_day_2== day)
      return true;
      else
      return false;
    }
    if(count==6 && day==2){
      if(this.selectedPrimaryForaward_6_day2 == id && this.selectedPrimaryCount_6_day2 === count && this.selected_6_day_2== day)
      return true;
      else
      return false;
    }
    if(count==7 && day==2){
      if(this.selectedPrimaryForaward_7_day2 == id && this.selectedPrimaryCount_7_day2 === count && this.selected_7_day_2== day)
      return true;
      else
      return false;
    }
    if(count==8 && day==2){
      if(this.selectedPrimaryForaward_8_day2 == id && this.selectedPrimaryCount_8_day2 === count && this.selected_8_day_2== day)
      return true;
      else
      return false;
    }

    if(count==9 && day==2){
      if(this.selectedPrimaryForaward_9_day2 == id && this.selectedPrimaryCount_9_day2 === count && this.selected_9_day_2== day)
      return true;
      else
      return false;
    }

    // Day3
    if(count==0 && day==3){
      if(this.selectedPrimaryForaward_0_day3 == id && this.selectedPrimaryCount_0_day3 === count && this.selected_0_day_3== day)
      return true;
      else
      return false;
    }
    if(count==1 && day==3){
      if(this.selectedPrimaryForaward_1_day3 == id && this.selectedPrimaryCount_1_day3 === count && this.selected_1_day_3== day)
      return true;
      else
      return false;
    }
    if(count==2 && day==3){
      if(this.selectedPrimaryForaward_2_day3 == id && this.selectedPrimaryCount_2_day3 === count && this.selected_2_day_3== day)
      return true;
      else
      return false;
    }
    if(count==3 && day==3){
      if(this.selectedPrimaryForaward_3_day3 == id && this.selectedPrimaryCount_3_day3 === count && this.selected_3_day_3== day)
      return true;
      else
      return false;
    }
    if(count==4 && day==3){
      if(this.selectedPrimaryForaward_4_day3 == id && this.selectedPrimaryCount_4_day3 === count && this.selected_4_day_3== day)
      return true;
      else
      return false;
    }
    if(count==5 && day==3){
      if(this.selectedPrimaryForaward_5_day3 == id && this.selectedPrimaryCount_5_day3 === count && this.selected_5_day_3== day)
      return true;
      else
      return false;
    }
    if(count==6 && day==3){
      if(this.selectedPrimaryForaward_6_day3 == id && this.selectedPrimaryCount_6_day3 === count && this.selected_6_day_3== day)
      return true;
      else
      return false;
    }
    if(count==7 && day==3){
      if(this.selectedPrimaryForaward_7_day3 == id && this.selectedPrimaryCount_7_day3 === count && this.selected_7_day_3== day)
      return true;
      else
      return false;
    }
    if(count==8 && day==3){
      if(this.selectedPrimaryForaward_8_day3 == id && this.selectedPrimaryCount_8_day3 === count && this.selected_8_day_3== day)
      return true;
      else
      return false;
    }

    if(count==9 && day==3){
      if(this.selectedPrimaryForaward_9_day3 == id && this.selectedPrimaryCount_9_day3 === count && this.selected_9_day_3== day)
      return true;
      else
      return false;
    }

    // Day4
    if(count==0 && day==4){
      if(this.selectedPrimaryForaward_0_day4 == id && this.selectedPrimaryCount_0_day4 === count && this.selected_0_day_4== day)
      return true;
      else
      return false;
    }
    if(count==1 && day==4){
      if(this.selectedPrimaryForaward_1_day4 == id && this.selectedPrimaryCount_1_day4 === count && this.selected_1_day_4== day)
      return true;
      else
      return false;
    }
    if(count==2 && day==4){
      if(this.selectedPrimaryForaward_2_day4 == id && this.selectedPrimaryCount_2_day4 === count && this.selected_2_day_4== day)
      return true;
      else
      return false;
    }
    if(count==3 && day==4){
      if(this.selectedPrimaryForaward_3_day4 == id && this.selectedPrimaryCount_3_day4 === count && this.selected_3_day_4== day)
      return true;
      else
      return false;
    }
    if(count==4 && day==4){
      if(this.selectedPrimaryForaward_4_day4 == id && this.selectedPrimaryCount_4_day4 === count && this.selected_4_day_4== day)
      return true;
      else
      return false;
    }
    if(count==5 && day==4){
      if(this.selectedPrimaryForaward_5_day4 == id && this.selectedPrimaryCount_5_day4 === count && this.selected_5_day_4== day)
      return true;
      else
      return false;
    }
    if(count==6 && day==4){
      if(this.selectedPrimaryForaward_6_day4 == id && this.selectedPrimaryCount_6_day4 === count && this.selected_6_day_4== day)
      return true;
      else
      return false;
    }
    if(count==7 && day==4){
      if(this.selectedPrimaryForaward_7_day4 == id && this.selectedPrimaryCount_7_day4 === count && this.selected_7_day_4== day)
      return true;
      else
      return false;
    }
    if(count==8 && day==4){
      if(this.selectedPrimaryForaward_8_day4 == id && this.selectedPrimaryCount_8_day4 === count && this.selected_8_day_4== day)
      return true;
      else
      return false;
    }

    if(count==9 && day==4){
      if(this.selectedPrimaryForaward_9_day4 == id && this.selectedPrimaryCount_9_day4 === count && this.selected_9_day_4== day)
      return true;
      else
      return false;
    }

  }

  onReset() {
    this.submitted = false;
    this.editForm.reset();
  }

  ite:any;
  selectednoofforwardinglocation(){
    let array = [];
    if(this.noofforwardinglocation_1>=10){
      this.ite = 10;
    }else{
      this.ite = this.noofforwardinglocation_1;
    }
    for (let i = 0; i < this.ite; i++) {
      array.push(i);
    }

    return array;
  }
  ite2:any;
  selectednoofforwardinglocation_2(){
    let array = [];
    if(this.noofforwardinglocation_2>=10){
      this.ite2 = 10;
    }else{
      this.ite2 = this.noofforwardinglocation_2;
    }
    for (let i = 0; i < this.ite2; i++) {
      array.push(i);
    }
    return array;
  }
  ite3:any;
  selectednoofforwardinglocation_3(){
    let array = [];
    if(this.noofforwardinglocation_3>=10){
      this.ite3 = 10;
    }else{
      this.ite3 = this.noofforwardinglocation_3;
    }
    for (let i = 0; i < this.ite3; i++) {
      array.push(i);
    }
    return array;
  }
  ite4:any;
  selectednoofforwardinglocation_4(){
    let array = [];
    if(this.noofforwardinglocation_4>=10){
      this.ite4 = 10;
    }else{
      this.ite4 = this.noofforwardinglocation_4;
    }
    for (let i = 0; i < this.ite4; i++) {
      array.push(i);
    }
    return array;
  }
  ite5:any;
  selectednoofforwardinglocation_5(){
    let array = [];
    if(this.noofforwardinglocation_5>=10){
      this.ite5 = 10;
    }else{
      this.ite5 = this.noofforwardinglocation_5;
    }
    for (let i = 0; i < this.ite5; i++) {
      array.push(i);
    }
    return array;
  }
  ite6:any;
  selectednoofforwardinglocation_6(){
    let array = [];
    if(this.noofforwardinglocation_6>=10){
      this.ite6 = 10;
    }else{
      this.ite6 = this.noofforwardinglocation_6;
    }
    for (let i = 0; i < this.ite6; i++) {
      array.push(i);
    }
    return array;
  }
  ite7:any;
  selectednoofforwardinglocation_7(){
    let array = [];
    if(this.noofforwardinglocation_7>=10){
      this.ite7 = 10;
    }else{
      this.ite7 = this.noofforwardinglocation_7;
    }
    for (let i = 0; i < this.ite7; i++) {
      array.push(i);
    }
    return array;
  }

// Day5
private selectedPrimaryForaward_0_day5: number=0;
private selectedPrimaryCount_0_day5: number=0;
private selected_0_day_5: number=0;

private selectedPrimaryForaward_1_day5: number=0;
private selectedPrimaryCount_1_day5: number=0;
private selected_1_day_5: number=0;

private selectedPrimaryForaward_2_day5: number=0;
private selectedPrimaryCount_2_day5: number=0;
private selected_2_day_5: number=0;

private selectedPrimaryForaward_3_day5: number=0;
private selectedPrimaryCount_3_day5: number=0;
private selected_3_day_5: number=0;

private selectedPrimaryForaward_4_day5: number=0;
private selectedPrimaryCount_4_day5: number=0;
private selected_4_day_5: number=0;

private selectedPrimaryForaward_5_day5: number=0;
private selectedPrimaryCount_5_day5: number=0;
private selected_5_day_5: number=0;

private selectedPrimaryForaward_6_day5: number=0;
private selectedPrimaryCount_6_day5: number=0;
private selected_6_day_5: number=0;

private selectedPrimaryForaward_7_day5: number=0;
private selectedPrimaryCount_7_day5: number=0;
private selected_7_day_5: number=0;

private selectedPrimaryForaward_8_day5: number=0;
private selectedPrimaryCount_8_day5: number=0;
private selected_8_day_5: number=0;

private selectedPrimaryForaward_9_day5: number=0;
private selectedPrimaryCount_9_day5: number=0;
private selected_9_day_5: number=0;

// Day6
private selectedPrimaryForaward_0_day6: number=0;
private selectedPrimaryCount_0_day6: number=0;
private selected_0_day_6: number=0;

private selectedPrimaryForaward_1_day6: number=0;
private selectedPrimaryCount_1_day6: number=0;
private selected_1_day_6: number=0;

private selectedPrimaryForaward_2_day6: number=0;
private selectedPrimaryCount_2_day6: number=0;
private selected_2_day_6: number=0;

private selectedPrimaryForaward_3_day6: number=0;
private selectedPrimaryCount_3_day6: number=0;
private selected_3_day_6: number=0;

private selectedPrimaryForaward_4_day6: number=0;
private selectedPrimaryCount_4_day6: number=0;
private selected_4_day_6: number=0;

private selectedPrimaryForaward_5_day6: number=0;
private selectedPrimaryCount_5_day6: number=0;
private selected_5_day_6: number=0;

private selectedPrimaryForaward_6_day6: number=0;
private selectedPrimaryCount_6_day6: number=0;
private selected_6_day_6: number=0;

private selectedPrimaryForaward_7_day6: number=0;
private selectedPrimaryCount_7_day6: number=0;
private selected_7_day_6: number=0;

private selectedPrimaryForaward_8_day6: number=0;
private selectedPrimaryCount_8_day6: number=0;
private selected_8_day_6: number=0;

private selectedPrimaryForaward_9_day6: number=0;
private selectedPrimaryCount_9_day6: number=0;
private selected_9_day_6: number=0;

  onFSLReset() {
    this.FSLRulesSubmitted = false;
    this.addFSLRules.reset();
  }


  selectedLevel :number =0;
  selected(){
    let array = [];
    for (let i = 0; i < this.noofforwarding; i++) {
      array.push(i);
    }
    return array;
  }

  Delete(id: string) {
    // alert(id)
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Delete Record!',

      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.moduleService.deleteFSLRulesbyname(id).subscribe(
            res => {
              // this.getAllRules();
              this.getalldata();
              Swal.fire(
                'Deleted!',
                'FSL Deleted Successfully.',
              )
              setTimeout(() => {
                Swal.close()
              }, 2000);
            },
            error => this.error = error
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your Record is safe :)',
          'error'
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
      }
    });
  }

  isadded:any;
  addremoverow(type){
    if(type==0){
      this.isadded = 1;
    }
    if(type==1){
      this.isadded = 0;
    }
  }

}

