import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpClientModule} from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { GlobalVariable } from '.././global';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // serverUrl = 'http://192.168.1.50/angapi/index.php/';
  // serverUrl = 'http://104.131.72.90/index.php/';
  private serverUrl = GlobalVariable.BASE_API_URL;
  errorData: {};
  constructor(private http: HttpClient) { }
  redirectUrl: string;
  private jsonObj: any = {};

  login(username: string, password: string) {
    // console.log(password);return false;
    return this.http.post<any>(`${this.serverUrl}login`, {username: username, password: password})
    .pipe(map(user => {
      // console.log(user);return
      
        if (user && user.token) {
          // console.log(companny); return;
          localStorage.setItem('currentUser', JSON.stringify(user));
          if(user.role==2){
            var companny = user.companydetails;
            for (let index = 0; index < companny.length; index++) {
              const element = companny[index];
              if(element.active==1) {
                this.jsonObj = {
                  "uuid": element.uuid,               
                  "name": element.name,               
                }            
              }
            }
          }else{
            // console.log(user.role); return;
            this.jsonObj = {
              "uuid": user.company_uuid,
              "name": user.first_name,                              
            }    
          }
          // console.log(this.jsonObj);return false;
          if(user.role==3){
            this.redirectUrl ='/campaigns';
          }
          if(user.role==4){
            this.redirectUrl ='/target';
          }
          // console.log(this.jsonObj);return false;
          localStorage.setItem('currentCompany', JSON.stringify(this.jsonObj));
          // console.log(compaany_arr);
          // return false;
         
        }
      }),
      catchError(this.handleError)
    );
  }
  
  get_permission(url: any,user_id:number) {
    return this.http.post<any>(this.serverUrl + 'get_permission/'+user_id, url).pipe(
      catchError(this.handleError)
    );
  }  
  
  isLoggedIn() {
    // console.log(localStorage.getItem('currentUser'));return false;
    if (localStorage.getItem('currentUser')) {      
      return true;
    }else{
    return false;
    }
  }

  getAuthorizationToken() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // console.log(currentUser);return false;
    return currentUser.token;
  }

  logout() {
    // localStorage.removeItem('currentUser');
    localStorage.clear();
    
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message
    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }
  
}
