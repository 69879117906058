import { Component, OnInit, Renderer2,ElementRef,Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

declare var $;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private renderer: Renderer2,private elementRef: ElementRef,@Inject(DOCUMENT) private doc ) 
 {}
 
  getyear:any;
  ngOnInit() {

    // let link1: HTMLLinkElement = this.doc.createElement('link');
    //  link1.setAttribute('rel', 'amphtml');
    //  link1.setAttribute('href', '../assets/css/app.min.css');
    //  this.doc.head.appendChild(link1);
    
    //  let link2: HTMLLinkElement = this.doc.createElement('link');
    //  link2.setAttribute('rel', 'amphtml');
    //  link2.setAttribute('href', '../assets/css/components.css');
    //  this.doc.head.appendChild(link2);
    
    // $('.settingPanelToggle').click(function(){
    //   this.count = 1;
    //   console.log(this.count);
    //   // $('.settingPanelToggle').toggleClass('showSettingPanel');
    //   // $('.settingSidebar').toggleClass('showSettingPanel');      
    // });
    // $('.settingPanelToggle').removeClass('showSettingPanel');
    // $('.settingSidebar').removeClass('showSettingPanel');
   
    // this.addJsToElement("../assets/js/app.min.js").onload ;
    this.addJsToElement("../assets/js/scripts.js").onload ;
    this.addJsToElement("../assets/js/custom.js").onload ;  
    
    this.getyear = (new Date()).getFullYear();

  }

  count:number = 0;
  show(){
    this.count =this.count  + 1;
    if(this.count % 2 == 0){
      this.count = 0;
    }
  }

  addJsToElement(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    this.renderer.appendChild(document.body, script);
    return script;
  }

}
