import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModuleService } from '../../module.service';
import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { ActivatedRoute, Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from './../../../global';
declare var $;
const router = Router;

@Component({
  selector: 'app-publisher-terms',
  templateUrl: './publisher-terms.component.html',
  styleUrls: ['./publisher-terms.component.scss']
})
export class PublisherTermsComponent implements OnInit {
  private ServerUrl = GlobalVariable.BASE_API_URL;
  private BaseUrl = GlobalVariable.BASE_URL;
  error: {};
  showLoadingIndicator: any;
  numbers: any;
  private http: HttpClient;
  public settings: any;
  addForm: FormGroup;
  currentCompany: any;
  Companyuuid: any;
  publicregistration:boolean;
  
  constructor(
    private moduleservice: ModuleService,
    private toastrService:ToastrService,
    handler: HttpBackend,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute : ActivatedRoute
  ) {
    // this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    // this.Companyuuid = this.currentCompany.uuid;
    this.http = new HttpClient(handler);
    this.publicregistration =true;
    this.activatedRoute.params.subscribe( (data) => {
      this.checkcompanyavail(data.id)                     
    })

   }
   routeurl:any;
   isavail:any = 1;
   company:any = 1;
  ngOnInit(): void {
    
    
    this.routeurl = window.location.href.replace(this.BaseUrl+'publisher-registration/', ""); 
    this.checkcompanyavail(this.routeurl)
    // console.log(this.routeurl);
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000);
    // this.getAllpublisherssetting();
    this.addForm = this.fb.group({
      // , Validators.required
      businame : ['',Validators.required],
      first_name : ['',Validators.required],
      last_name: ['',Validators.required],      
      email: ['',Validators.required],      
      phone_number: ['',Validators.required],      
     });
  }

  checkcompanyavail(company_uuid:any){
    this.moduleservice.checkcompanyavail(company_uuid).subscribe(
      res => {
          this.isavail = res.isavail;               
          this.company = res.company;               
      },      
    );  
  }  

  email:any;
  getAllpublisherssetting(){
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleservice.getAllpublisherssetting(this.currentCompany.uuid).subscribe(
      res => {
        //  console.log(res); return false;  
       this.email = res['email'];     
      },
      error => this.error = error,
    );  
  }  

  tcenable:any=false;
  tc_enable(event:any){
    if(event.target.checked==true){
      this.tcenable = true; 
    }else{
      this.tcenable = false; 
    }
  }  

  Submitted = false;
//Add Publisher
get addformcontrol() { return this.addForm.controls; }  
onSubmit () {
    const formData = new FormData();      
    this.Submitted = true;
    if (this.addForm.invalid) {
        return;
    }
    
    // this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    formData.append('company_uuid', this.routeurl);
    formData.append('businame',this.addForm.get('businame').value);
    formData.append('first_name', this.addForm.get('first_name').value);
    formData.append('last_name', this.addForm.get('last_name').value);    
    formData.append('email', this.addForm.get('email').value);    
    formData.append('phone_number', this.addForm.get('phone_number').value);    
    // console.log(formData); return false;

    this.moduleservice.addPublisherRegistration(formData).subscribe(
      res => {
        // console.log(res); return false;
        if (res.status !== 'error') {
          this.toastrService.success('Publisher Registration Successfully Done.');      
          localStorage.setItem('company',this.routeurl);      
          this.router.navigate(['/thank-you']);
        }else{
          this.toastrService.error('Failed To Add Label');
        }
      },
      error => this.error = error,
    );     
   }

  

}
