import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login-componunt',
  templateUrl: './login-componunt.component.html',
  styleUrls: ['./login-componunt.component.scss']
})
export class LoginComponuntComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  error: {};
  loginError: string;
  islogin:any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService
    ) {
      // if (authService.isLoggedIn()) {
      //     router.navigate(['/']);
      // }
      
     }

  ngOnInit() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    if (localStorage.getItem('currentUser')) {
      this.router.navigate(['/']);
      return false;
    }

    this.islogin = this.authService.isLoggedIn();
    
    // if(this.islogin='true'){
    //   // console.log(this.islogin);  
    //   this.router.navigate(['']);
    // }
    this.authService.logout();
  }

  get username() { return this.loginForm.get('username'); }
  get password() { return this.loginForm.get('password'); }

  onSubmit() {
    this.submitted = true;
    this.authService.login(this.username.value, this.password.value).subscribe((data) => {
       if (localStorage.getItem('currentUser')) {
        //  console.log(this.authService.isLoggedIn);return false;
        // return false;
          const redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/';
          this.router.navigate([redirect]);
        } else {
          this.loginError = 'Username or password is incorrect.';
        }
      },
      error => this.error = error
    );
  }

    // logout() {
    //   this.authService.logout();
    //   this.router.navigate(['/login']);
    // }
  }
